import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Switch } from "antd";
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import SidebarNav from "../sidebar";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import {APIURL} from '../../../matcher';
class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      category:[],
      brands:[],
      subCate:[],
      brand_id:0,
      category_id:0,
      sub_category_id:0,
      add_product:false,
      edit_product:false,
      check_product:false,
    };
  }

  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('add_product'))
    {
      this.props.history.push('/admin/dashboard')
    }

    if(permission.includes('add_product'))
    {
      this.setState(
      {
        add_product: true
      });
    }

    if(permission.includes('edit_product'))
    {
      this.setState(
      {
        edit_product: true
      });
    }

    if(permission.includes('check_product'))
    {
      this.setState(
      {
        check_product: true
      });
    }

    axios.get(APIURL+'brand-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            brands: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            brands: [],
            loading: false,
          });
        }
    })

    axios.get(APIURL+'category-list')
        .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            category: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            category: [],
            loading: false,
          });
        }
    })

    axios.get(APIURL+'product-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            data: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            data: '',
            loading: false,
          });
        }
    })
    
  }

  onChangeHandle = (event,index) =>
  {
    const status = event;
    const is_active = '';
    const product_id = index.target.id;
    if(status == true)
    {
      const is_active = 1;
      axios({
          method: 'post',
          url: APIURL+'update-product-status/'+product_id,
          data: qs.stringify({
            is_active: is_active,
            admin_id: localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    }
    else if(status == false)
    {
      const is_active = 0;
      axios({
          method: 'post',
          url: APIURL+'update-product-status/'+product_id,
          data: qs.stringify({
            is_active: is_active,
            admin_id: localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    } 
  }

  handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

  handleCategory = (event) =>  
  {
    const category_id = event.target.value;
    if(!category_id) 
    {
      this.setState({ category_id });
    } 
    else 
    {
      this.setState({ category_id });
    }

    axios.get(APIURL+'get-subcategory-list/'+category_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            subCate: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            subCate: [],
            loading: false,
          });
        }
    })
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    
      axios.get(APIURL+'search-product?brand_id='+this.state.brand_id+'&category_id='+this.state.category_id+'&sub_category_id='+this.state.sub_category_id)
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            data: res.data.list,
            loading: false,
            showResults: true
          });
        }
        else
        {
          alert('No Record Found for selected Parameters')
          this.setState({
            data: '',
            'message':'No Record Found for selected Parameters',
            loading: false,
          });
        }
      })
  }

  render() {
    const { brands,category,data,subCate }  = this.state;

    const columns = [
      {
        title: "Brand Name",
        dataIndex: "brand_name",
        sorter: (a, b) => a.brand_name.length - b.brand_name.length,
      },
      {
        title: "Category",
        dataIndex: "category_name",
        sorter: (a, b) => a.category_name.length - b.category_name.length,
      },
      {
        title: "Sub Category",
        dataIndex: "sub_category",
        sorter: (a, b) => a.sub_category.length - b.sub_category.length,
      },
      {
        title: "Product Name",
        dataIndex: "product_name",
        sorter: (a, b) => a.product_name.length - b.product_name.length,
      },
      {
        title: "Color Code",
        dataIndex: "color_code",
        sorter: (a, b) => a.color_code.length - b.color_code.length,
      },
      {
        title: "SKU",
        dataIndex: "product_sku",
        sorter: (a, b) => a.product_sku.length - b.product_sku.length,
      },
      {
        title: "Product Description",
        dataIndex: "description",
        sorter: (a, b) => a.description.length - b.description.length,
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: (a, b) => a.size.length - b.size.length,
      },
      {
        title: "Unit",
        dataIndex: "unit",
        sorter: (a, b) => a.unit.length - b.unit.length,
      },
      {
        title: "Roll Size",
        dataIndex: "roll_size",
        sorter: (a, b) => a.roll_size.length - b.roll_size.length,
      },
      {
        title: "Enabled/Disabled",
        dataIndex: "status",
        key: "status",
        render: (e,data) => (
          this.state.check_product ?
          <Switch
          id={data.id}
          onChange={this.onChangeHandle}
          defaultChecked={e}        
          />
          : null
          /*<Switch onChange={this.onChangeHandle} defaultChecked={e} />*/
        ),
      },
      {
        title: 'Actions',
        dataIndex: "id",
        render: (dataIndex) => (
            this.state.edit_product ? 
              <div className="actions">
                <Link to={'/admin/edit-product/'+dataIndex} className="btn btn-sm bg-success-light"><i className="fe fe-pencil"></i> Edit</Link>
              </div>
            : null
            ),
      },
    ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Product Master</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0)">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Product Master</li>
                  </ul>
                </div>
                { this.state.add_product ?
                <div className="col-sm-5 col">
                  <a className="btn btn-primary float-right mt-2" href="/admin/add-product"><i className="fas fa-plus mr-1" /> Add New Product </a>
                </div>
                : null }
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
              <form onSubmit={this.onSubmit} method="get" encType="multipart/form-data" autoComplete="off"> 
                  <div className="row">
                    <div className="col-md-3">
                      <label>Select Brand</label>
                      <select className="form-control" placeholder="Brand Name" name="brand_id" id="brand_id" value={this.state.brand_id}  onChange={this.handleInputChange} required>
                        <option value="0">ALL</option>
                          {
                            brands.map((list) =>
                            {
                              return(
                                <option value={list.id}>{list.brand_name}</option>
                              )
                            })
                          }
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>Select Category</label>
                      <select className="form-control" placeholder="Brand Name" name="category_id" id="category_id" value={this.state.category_id}  onChange={this.handleCategory} required>
                      <option value="0">ALL</option>
                        {
                          category.map((lis) =>
                          {
                            return(
                              <option value={lis.id}>{lis.category_name}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>Select SubCategory</label>
                      <select className="form-control" placeholder="Brand Name" name="sub_category_id" id="sub_category_id" value={this.state.sub_category_id}  onChange={this.handleInputChange} required>
                      <option value="0">ALL</option>
                        {
                          subCate.map((sub) =>
                          {
                            return(
                              <option value={sub.id}>{sub.sub_category}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-md-2">
                      <button className="btn btn-primary" type="submit" style={{marginTop: 15 + '%'}}><i className="fa fa-search" />&nbsp;Search</button>
                    </div>
                  </div>
                </form>
                <br/>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        scroll={{
                          x: 2000,
                          }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductList;
