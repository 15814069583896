
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class EditState extends Component {

constructor(props) 
{
  super(props);
  this.state = {
    data: '',
    region_id : '',
    state_name: '',
    state_code: '',
    description:'',
    lists:''
  };
}

componentDidMount() 
{  
  let permission = localStorage.getItem('permissions');
  if(!permission.includes('edit_state'))
  {
    this.props.history.push('/admin/dashboard')
  }

    axios.get(APIURL+'get-region-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            lists: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            lists: '',
            loading: false,
          });
        }
    })

    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    axios.get(APIURL+'edit-state/'+segment)
    .then((res) => 
    {
        if(res.data.statusCode == 200)
        {
          this.setState({
            data: res.data.details,
            region_id: res.data.details[0].region_id,
            state_name: res.data.details[0].state_name,
            state_code: res.data.details[0].state_code,
            description: res.data.details[0].description,
            loading: false,
          });
        }
        else
        {
          this.setState({
            data: '',
            loading: false,
          });
        }
    })
}

handleRegion = (event) =>  
{
  const states = event.target.value;
  if(!states) 
  {
    this.setState({ states });
  } 
  else 
  {
    this.setState({ states });
  }
}

handleRegionId = (event) =>  
{
  const region_id = event.target.value;
  if(!region_id) 
  {
    this.setState({ region_id });
  } 
  else 
  {
    this.setState({ region_id });
  }
}

handleStateName = (event) =>  
{
  const state_name = event.target.value;
  if(!state_name) 
  {
    this.setState({ state_name });
  } 
  else 
  {
    this.setState({ state_name });
  }
}

handleStateCode = (event) =>  
{
  const state_code = event.target.value;
  if(!state_code) 
  {
    this.setState({ state_code });
  } 
  else 
  {
    this.setState({ state_code });
  }
}

handleDesc = (event) =>  
{
  const description = event.target.value;
  if(!description) 
  {
    this.setState({ description });
  } 
  else 
  {
    this.setState({ description });
  }
}

update = (event) => 
{
  const url = this.props.location.pathname;
  const segment = url.substring(url.lastIndexOf('/') + 1);
  event.preventDefault();
  this.btn.setAttribute("disabled", "disabled");
  axios({
      method: 'post',
      url: APIURL+'check-edit-state-name/'+segment,
      data: qs.stringify({
        state_name: this.state.state_name
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }).then((res) => 
    {
      if(res.data.total[0].total > 0)
      {
        alert('State Name Should be Unique.');
        this.btn.removeAttribute("disabled");
      }
      else if(res.data.total[0].total == 0)
      {
        axios({
          method: 'post',
          url: APIURL+'check-edit-state-code/'+segment,
          data: qs.stringify({
            state_code: this.state.state_code
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
          if(res.data.total[0].total > 0)
          {
            alert('State Code Should be Unique');
            this.btn.removeAttribute("disabled");
          }
          else if(res.data.total[0].total == 0)
          {
            axios({
                method: 'post',
                url: APIURL+'update-state/'+segment,
                data: qs.stringify({
                  region_id: this.state.region_id,
                  state_name: this.state.state_name,
                  state_code: this.state.state_code,
                  description: this.state.description,
                  admin_id: localStorage.getItem('id')
                }),
                headers: {
                  'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            })
            .then((res) => 
            {
                if(res.data.statusCode == 200)
                {
                  alert(res.data.message)
                  this.props.history.push('/admin/state-list')
                }
                else if(res.data.statusCode == 500)
                {
                  this.setState({
                      errorMessage: res.data.message
                  });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error) || error) {
                    this.setState({
                        loading: false,
                        message: 'Failed to fetch results.Please check network',
                    });
                }
            });
          }
        })       
      }
    })  
}

render() 
{
  const { lists,data }  = this.state;
  if(lists.length == 0)
  {
    console.log(lists)  
    return('')
  }
  else
  {
      return (        
          <>
            <SidebarNav />
            <div className="main-wrapper">
            {/* Page Wrapper */}
              <div className="page-wrapper">
                <div className="content container-fluid">
                  {/* Page Header */}
                  <div className="page-header">
                    <div className="row">
                      <div className="col-sm-5">
                        <h3 className="page-title">Edit State</h3>
                        <ul className="breadcrumb">
                          <li className="breadcrumb-item"><a href="">State Master</a></li>
                          <li className="breadcrumb-item active">Edit State</li>
                        </ul>
                      </div>
                      <div className="col-sm-5">
                        <Link to="/admin/state-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
                      </div>
                    </div>
                  </div>
                  {/* /Page Header */}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body custom-edit-service">
                          
                          {/* Add User */}
                          <form method="post" onSubmit={this.update} encType="multipart/form-data" autoComplete="off">
                            <div className="service-fields mb-2">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>Select Region<b>*</b></label>
                                    <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleRegionId} required>
                                    <option value=""></option>
                                    {
                                      lists.map((list) =>
                                      {
                                        return(
                                            <option value={list.id}>{list.region}</option>
                                          )
                                      })
                                    }
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>State Name<b>*</b></label>
                                    <input className="form-control" placeholder="State Name" name="state_name" id="state_name" value={this.state.state_name}  onChange={this.handleStateName} required />
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="form-group">
                                    <label>State Code<b>*</b></label>
                                    <input className="form-control" placeholder="State Code" name="state_code" id="state_code" value={this.state.state_code}  onChange={this.handleStateCode} required />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="service-fields mb-2">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <label>Description <b>*</b></label>
                                    <textarea className="form-control" value={this.state.description}  onChange={this.handleDesc} rows="5" name="description" required></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="submit-section">
                              <button ref={btn => { this.btn = btn; }} className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Update</button>
                            </div>
                          </form>
                          {/* /Add User */}
                        </div>
                      </div>
                    </div>      
                  </div>
                </div>      
              </div>
              {/* /Page Wrapper */}
            </div>
          </>
      );
    }
  }
}

export default EditState;

