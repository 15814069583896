import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import Select, { components } from "react-select";
//import { components } from "react-select";
import { DatePicker } from "antd";
import moment from 'moment';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';

const MultiValueRemove = (props) => {
  if (props.data.isFixed) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
class EditUser extends Component {

  constructor(props) 
  {
    super(props)
    this.state = {
      member_type_detail : '',
      full_name: '',
      email: '',
      mobile: '',
      password: '',
      username: '',
      email: '',
      region_id: '',
      state_id: '',
      district_id: '',
      optionSelected:'',
      employee_code:'',
      address:'',
      date_of_birth:'',
      lists:[],
      states:[],
      districts:[],
      input: {},
      errors: {},
      data:'',
      colourOptions : [],
      stateOptions:[],
      wait:false,
      submit:true,
      selectRef : null
    };
  }
  
  componentDidMount() 
  {  

    let permission = localStorage.getItem('permissions');
    if(!permission.includes('edit_user'))
    {
      this.props.history.push('/admin/login')
    }

    axios.get(APIURL+'get-region-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            lists: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            lists: '',
            loading: false,
          });
        }
    })

    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    axios.get(APIURL+'edit-user/'+segment)
    .then((res) => 
    {
      if(res.data.statusCode == 200)
      {
        this.setState({
          data:         res.data.details[0],
          region_id:    res.data.details[0].region_id,
          full_name:    res.data.details[0].full_name,
          mobile:       res.data.details[0].mobile,
          username:     res.data.details[0].username,
          email:          res.data.details[0].email,
          optionSelected: res.data.details[0].district,
          state_id: res.data.details[0].state,
          member_type_detail: res.data.details[0].member_type_detail,
          employee_code: res.data.details[0].employee_code,
          address: res.data.details[0].address,
          date_of_birth: res.data.details[0].date_of_birth,
          // state_name: res.data.details[0].state_name,
          loading: false,
        });
        axios.get(APIURL+'get-state-list/'+this.state.region_id)
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                stateOptions: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                stateOptions: [],
                loading: false,
              });
            }
        })

        axios.get(APIURL+'getDistrictList/'+this.state.state_id)
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                colourOptions: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                colourOptions: [],
                loading: false,
              });
            }
        })
      }
      else
      {
        this.setState({
          data: '',
          loading: false,
        });
      }
    })

    axios.get(APIURL+'get-last-code')
    .then((res) => 
    {
      this.setState({ 
        employee_code: res.data.result[0].employee_code
      });
    })
  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleRegionId = (event) =>  
  {
    const region_id = event.target.value;
    if(!region_id) 
    {
      this.setState({ region_id });
    } 
    else 
    {
      this.setState({ region_id });
    }

    axios.get(APIURL+'get-state-list/'+region_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            stateOptions: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            stateOptions: [],
            loading: false,
          });
        }
    })
  }

  handleChange = (selected) => {
    this.setState({
      optionSelected: selected
    });
  };

  handleChangee = (event) =>
  {
      this.setState({
        date_of_birth: event.target.value
      });
      const date18YrsAgo = new Date();
      date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
      const birth = new Date(event.target.value);
      if(birth.getFullYear() > date18YrsAgo.getFullYear())
      {
        alert("Invalid Date of Birth")
        this.setState({
          date_of_birth:''
        });
      }
  }

  // handleStateId = (event) =>  
  // {
  //   const state_id = event.target.value;
  //   if(!state_id) 
  //   {
  //     this.setState({ state_id });
  //   } 
  //   else 
  //   {
  //     this.setState({ state_id });
  //   }

  //   axios.get(APIURL+'getDistrictList/'+state_id)
  //   .then((res) => 
  //   {
  //       if(res.data.list.length > 0)
  //       {
  //         this.setState({
  //           colourOptions: res.data.list,
  //           optionSelected:null,
  //           loading: false,
  //         });
  //         this.selectRef.select.clearValue();
  //       }
  //       else
  //       {
  //         this.setState({
  //           colourOptions: [],
  //           loading: false,
  //         });
  //       }
  //   })
  // }
  
  onSubmit = (event) => 
  {
    event.preventDefault();
    if(this.validate())
    {
      const url = this.props.location.pathname;
      const segment = url.substring(url.lastIndexOf('/') + 1);
      event.preventDefault();
      this.setState({
          submit: false,
          wait: true,
      });
      axios({
        method: 'post',
        url: APIURL+'update-user/'+segment,
        data: qs.stringify({
          member_type_detail: this.state.member_type_detail,
          region_id: this.state.region_id,
          state_id: this.state.state_id,
          district_id: this.state.optionSelected,
          full_name: this.state.full_name,
          mobile: this.state.mobile,
          username: this.state.username,
          address: this.state.address,
          date_of_birth: this.state.date_of_birth,
          password: this.state.password,
          email: this.state.email,
          admin_id: localStorage.getItem('id')
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
      .then((res) => 
      {
        if(res.data.statusCode == 200)
        {
          alert(res.data.message)
          this.props.history.push('/admin/users-list')
        }
        else if(res.data.statusCode == 500)
        {
          this.setState({
              errorMessage: res.data.message,
              wait:false,
              submit:true
          });
        }
      })
      .catch((error) => {
          if (axios.isCancel(error) || error) {
              this.setState({
                  loading: false,
                  message: 'Failed to fetch results.Please check network',
              });
          }
      });
    }  
  }

  validate()
  {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
   // console.log(this.state.username)
    if (!this.state.full_name) 
    {
      isValid = false;
      errors["full_name"] = "Please enter your Full Name.";
    }


    if (!this.state.email) 
    {
      isValid = false;
      errors["email"] = "Please enter your Email Address.";
    }

    if(this.state.username.trim().length == 0)
    {
      isValid = false;
      errors["username"] = "Please enter your username.";
    }
    else if (!this.state.username) 
    {
      isValid = false;
      errors["username"] = "Please enter your username.";
    }
    
    if(typeof this.state.mobile !== "undefined") 
    {
      var pattern = new RegExp(/^[0-9\b]+$/);
      var pat = new RegExp(/^0/);
      if (!pattern.test(this.state.mobile)) 
      {
        isValid = false;
        errors["mobile"] = "Please enter only number.";
      }
      else if(pat.test(this.state.mobile)) 
      {
        isValid = false;
        errors["mobile"] = "Invalid Mobile Number";
      }
      else if(this.state.mobile.length == '00000000000')
      {
        isValid = false;
        errors["mobile"] = "Please Enter valid phone number.";
      }
      else if(this.state.mobile.length != 10)
      {
        isValid = false;
        errors["mobile"] = "Please enter valid phone number.";
      }
    }

    if (typeof this.state.email !== "undefined") 
    {    
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.state.email)) 
      {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    if(this.state.address.trim().length == 0)
    {
      isValid = false;
      errors["address"] = "Please enter your address.";
    }
    
    // if(this.state.password.trim().length == 0)
    // {
    //   isValid = false;
    //   errors["password"] = "Please enter your password.";
    // }
    // else if (!this.state.password) 
    // {
    //   isValid = false;
    //   errors["password"] = "Please enter your password.";
    // }

    // if(this.state.confirm_password.trim().length == 0)
    // {
    //   isValid = false;
    //   errors["confirm_password"] = "Please enter your confirm password.";
    // }
    // else if (!this.state.confirm_password) 
    // {
    //   isValid = false;
    //   errors["confirm_password"] = "Please enter your confirm password.";
    // }

    if (typeof this.state.password !== "undefined" && typeof this.state.confirm_password !== "undefined") 
    {  
      if (this.state.password != this.state.confirm_password) 
      {
        isValid = false;
        errors["password"] = "Passwords don't match.";
      }
    } 
    this.setState({
      errors: errors
    });
    return isValid;
  }

  handleStateId = (event) =>  
  {
    const state_id = event;
    console.log(state_id)
      
    if(!state_id) 
    {
      this.setState({
        state_id: state_id
      });
      //this.setState({optionSelected:[]});
    } 
    else 
    {
      this.setState({
        state_id: state_id
      });
      //this.setState({optionSelected:[]});
    }

    for (var i = 0, l = state_id.length; i < l; i++) 
    {
      axios.get(APIURL+'getDistrictList/'+state_id[i].id)
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            colourOptions: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            colourOptions: [],
            loading: false,
          });
        }
      })
    } 
  }

   render() 
   {
    const { lists,data, districts,optionSelected,state_id }  = this.state;
    //console.log(this.state.stateOptions)
    if(this.state.stateOptions.length > 0)
    {
      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
        {/* Page Wrapper */}
        <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7">
                <h3 className="page-title">User Enrolment</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Users</a></li>
                  <li className="breadcrumb-item active">Edit User</li>
                </ul>
              </div>
              <div className="col-sm-5">
                <a href="/admin/users-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></a>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body custom-edit-service">
                  
                  {/* Add User */}
                  <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Member Type <b>*</b></label>
                            <select className="form-control" name="member_type_detail" value={this.state.member_type_detail}  onChange={this.handleInputChange} required> 
                              <option value="">Select Member Type</option>
                              <option value="admin">Admin</option>
                              {/*<option value="backend">Backend</option>*/}
                              <option value="rm">RM</option>
                              <option value="kre">KRE</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Region <b>*</b></label>
                            <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleRegionId} required>
                              <option value="">Select Region</option>
                              {
                                lists.map((list) =>
                                {
                                  return(
                                      <option value={list.id}>{list.region}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>State <b>*</b></label>
                            <Select isMulti ref={ref => {
                              this.selectRef = ref;
                            }} isSearchable defaultValue={state_id} isClearable={false} options={this.state.stateOptions}  components={{ MultiValueRemove }} onChange={this.handleStateId}/>
                            {/* <select className="form-control" placeholder="State Name" name="state_id" id="state_id" value={this.state.state_id}  onChange={this.handleStateId} required>
                              
                              {
                                states.map((state) =>
                                {
                                  return(
                                      <option value={state.id}>{state.state_name}</option>
                                    )
                                })
                              }
                            </select> */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>District <b>*</b></label>
                            <Select isMulti ref={ref => {
                              this.selectRef = ref;
                            }} isSearchable defaultValue={optionSelected} isClearable={false} options={this.state.colourOptions}  components={{ MultiValueRemove }} onChange={this.handleChange}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Full Name <b>*</b></label>
                            <input className="form-control" pattern="^[a-zA-Z][\sa-zA-Z]*" type="text" name="full_name" id="full_name" value={this.state.full_name}  onChange={this.handleInputChange} placeholder="Full Name" required />
                            <div className="text-danger">{this.state.errors.full_name}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Phone Number <b>*</b></label>
                            <input className="form-control" type="text" name="mobile" id="mobile" minlength="10" maxlength="10" value={this.state.mobile}  onChange={this.handleInputChange} placeholder="Phone Number" required />
                            <div className="text-danger">{this.state.errors.mobile}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>UserName <b>*</b></label>
                            <input className="form-control"  type="text" name="username" id="username" placeholder="UserName" value={this.state.username}  onChange={this.handleInputChange} required />
                            <div className="text-danger">{this.state.errors.username}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Email <b>*</b></label>
                            <input className="form-control" type="email" value={this.state.email}  onChange={this.handleInputChange} name="email" id="email"  placeholder="Email" required />
                            <div className="text-danger">{this.state.errors.email}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Address <b>*</b></label>
                            <input className="form-control"  type="text" name="address" id="address" placeholder="Address" value={this.state.address}  onChange={this.handleInputChange} required />
                            <div className="text-danger">{this.state.errors.address}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Date Of Birth <b>*</b></label>
                            <input type="date" name="date_of_birth" value={this.state.date_of_birth} className="form-control"  onChange={this.handleChangee} min="1971-01-01"  required/>
                            {/*<DatePicker className="form-control"    onChange={this.handleChangee}  format="YYYY-MM-DD" required/>*/}
                            <div className="text-danger">{this.state.errors.date}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Password </label>
                            <input className="form-control"  type="password" name="password" id="password" maxlength="10" placeholder="Password" value={this.state.password}  onChange={this.handleInputChange}/>
                            <div className="text-danger">{this.state.errors.password}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Confirm Password </label>
                            <input className="form-control"  type="password" name="confirm_password" id="confirm_password" maxlength="10" value={this.state.confirm_password}  onChange={this.handleInputChange} placeholder="Confirm Password"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Update</button> : null}
                      {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                    </div>
                  </form>
                  {/* /Add User */}
                </div>
              </div>
            </div>      
          </div>
        </div>      
      </div>
      {/* /Page Wrapper */}
        </div>
        </>
      );
    }
    else
    {
      return('')
    }
   }
}

export default EditUser;
