import React, { Component } from 'react';
import { CSVLink } from 'react-csv'
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import SidebarNav from './sidebar/index';
import moment from 'moment';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';

class VisitReport extends Component{
  constructor(props) 
  {
    super(props)
    this.state = {
      startDate: '',
      endDate: '',
      lists:[],
      states:[],
      districts:[],
      name:'',
      mobile:'',
      region_id:'',
      state_id:'',
      district_id:'',
      player:[],
      showResults:false
    };
  }


  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('visit_report'))
    {
      this.props.history.push('/admin/report/my-profile')
    }
    axios.get(APIURL+'get-region-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            lists: res.data.list,
            loading: false,
            showResults: false
          });
        }
        else
        {
          this.setState({
            lists: '',
            loading: false,
          });
        }
    })
  }

  handleRegionId = (event) =>  
  {
    const region_id = event.target.value;
    if(!region_id) 
    {
      this.setState({ region_id });
    } 
    else 
    {
      this.setState({ region_id });
    }

    this.setState({
      showResults: false
    });

    axios.get(APIURL+'get-state-list/'+region_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            states: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            states: [],
            loading: false,
          });
        }
    })
  }

  handleStateId = (event) =>  
  {
    const state_id = event.target.value;
    if(!state_id) 
    {
      this.setState({ state_id });
    } 
    else 
    {
      this.setState({ state_id });
    }

    this.setState({
      showResults: false
    });

    axios.get(APIURL+'get-district-list/'+state_id)
    .then((res) => 
    {
      if(res.data.list.length > 0)
      {
        this.setState({
          districts: res.data.list,
          loading: false,
        });
      }
      else
      {
        this.setState({
          districts: [],
          loading: false,
        });
      }
    })
  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
      showResults: false
    });
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    if(this.state.startDate == '')
    {
      var  startDate = ''
    }
    else
    {
      var  startDate = moment(this.state.startDate).format('YYYY-MM-DD')
    }
    
    if(this.state.endDate == '')
    {
      var  endDate = ''
    }
    else
    {
      var  endDate = moment(this.state.endDate).format('YYYY-MM-DD')
    }

    if(this.state.region_id == '' && this.state.state_id == '' && this.state.district_id == '' && this.state.mobile == '' && this.state.name == '' && startDate == '' && endDate == '')
    {
      alert('Please Select the Parameters')
    }
    else
    {
      axios.get(APIURL+'visit-report?region='+this.state.region_id+'&state='+this.state.state_id+'&district='+this.state.district_id+'&mobile='+this.state.mobile+'&name='+this.state.name+'&startDate='+startDate+'&endDate='+endDate)
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            player: res.data.list,
            showResults: true,
          });
        }
        else
        {
          alert('No Record Found for selected Parameters')
          this.setState({
            showResults: false,
          });
        }
      })
    }
    
  }

  handleChange = (event) => 
  {
    this.setState({
      showResults: false,
    });
  }

    render()
    {
      const { lists,states,districts,player,startDate}  = this.state;
      const players = player
      const headers = [
        {
          label:"Region",key:"region"
        },
        {
          label:"State",key:"state_name"
        },
        {
          label:"District",key:"district_name"
        },
        {
          label:"Retailer Customer Code",key:"retailer_customer_code"
        },
        {
          label:"Retailership Name",key:"retailership_name"
        },
        {
          label:"Retailer Mobile No",key:"retailer_mobile"
        },
        {
          label:"Pin Code",key:"pincode"
        },
        {
          label:"Tagged KRE Code",key:"kre_code"
        },
        {
          label:"Tagged KRE Name",key:"tagged_kre"
        },
        {
          label:"RMEmployeeCode",key:"rsm_code"
        },
        {
          label:"RM NAME",key:"tagged_rsm"
        },
        {
          label:"Visit Date",key:"visit_date"
        },
        {
          label:"Check-in Time",key:"check_in_time"
        },
        {
          label:"Check-in Latitude",key:"latitude"
        },
        {
          label:"Check-in Longitude",key:"longitude"
        },
        {
          label:"Check-Out Time",key:"check_out_time"
        },
        {
          label:"Check-Out Latitude",key:"checkout_lat"
        },
        {
          label:"Check-Out Longitude",key:"checkout_long"
        },
        {
          label:"Remarks",key:"kre_remarks"
        }
      ]
       
      const csvLink = {
        headers: headers,
        data: players,
        filename:"visit-report.csv"
      }
        return(
          <div>
            <SidebarNav />
            <div className="page-wrapper">
              <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col">
                            <h3 className="page-title">Visit Report</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Report</a></li>
                                <li className="breadcrumb-item active">Visit Report</li>
                            </ul>
                        </div>
                    </div>
                </div>
                 {/* page header */}
              	<div className="row">
                  <div className="col-lg-12">
                      <div className="card">
                          <div className="card-body">
                            <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                                <div className="service-fields mb-2">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <div className="form-group">
                                        <label>From Date </label>
                                        <input type="date" className="form-control" onChange={e => this.setState({ startDate: e.target.value })}/>
                                      </div>
                                    </div>
                                    {startDate != '' && (
                                      <React.Fragment>
                                        <div className="col-lg-3">
                                          <div className="form-group">
                                            <label>To Date </label>
                                            <input type="date" className="form-control" min={startDate} onChange={e => this.setState({ endDate: e.target.value })}/>
                                          </div>
                                        </div>
                                     </React.Fragment>
                                    )}
                                    <div className="col-lg-3">
                                      <div className="form-group">
                                        <label>Region </label>
                                        <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleRegionId}>
                                          <option value="">Select Region</option>
                                          {
                                            lists.map((list) =>
                                            {
                                              return(
                                                  <option value={list.id}>{list.region}</option>
                                                )
                                            })
                                          }
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="form-group">
                                        <label>State </label>
                                        <select className="form-control" placeholder="State Name" name="state_id" id="state_id" value={this.state.state_id}  onChange={this.handleStateId}>
                                          <option value="">Select State</option>
                                          {
                                            states.map((state) =>
                                            {
                                              return(
                                                  <option value={state.id}>{state.state_name}</option>
                                                )
                                            })
                                          }
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="service-fields mb-2">
                                    <div className="row">
                                        <div className="col-lg-3">
                                          <div className="form-group">
                                            <label>District </label>
                                            <select className="form-control" name="district_id" value={this.state.district_id}  onChange={this.handleInputChange}>   
                                              <option value="">Select District</option>
                                              {
                                                districts.map((lis) =>
                                                {
                                                  return(
                                                      <option value={lis.id}>{lis.district_name}</option>
                                                    )
                                                })
                                              }
                                            </select>
                                          </div>
                                        </div>
                                        <div className="col-lg-3">
                                          <div className="form-group">
                                            <label>KRE Mobile No. </label>
                                            <input type="text" minlength="10" maxlength="10" className="form-control" placeholder="KRE Mobile No." name="mobile" value={this.state.mobile} onChange={this.handleInputChange}/>
                                          </div>
                                        </div>
                                        <div className="col-lg-3">
                                          <div className="form-group">
                                            <label>KRE Name </label>
                                            <input type="text"  className="form-control" placeholder="KRE Name" name="name" value={this.state.name} onChange={this.handleInputChange}/>
                                          </div>
                                        </div>
                                        <div className="col-lg-3">
                                          <div className="form-group">
                                            { this.state.showResults ? null : <button className="btn btn-primary search-btn" type="submit"><i className="fa fa-search"></i></button> }
                                            { this.state.showResults ? <CSVLink {...csvLink} className="btn btn-primary search-btn" onClick={this.handleChange}><i className="fa fa-download"></i>&nbsp;Export</CSVLink> : null }
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default VisitReport;