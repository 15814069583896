import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import SidebarNav from '../sidebar';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';

class Dashboard extends Component{

	constructor(props){
        super(props);
        this.state = {
            data: '',
            total_rsm : 0,
            total_kre: 0,
            total_retailer: 0,
            view_user:false,
            view_retailer:false
        }; 
    }

	componentDidMount() 
    {  
    	let permission = localStorage.getItem('permissions');
	    if(permission.includes('view_user'))
	    {
	      this.setState(
	      {
	        view_user: true
	      });
	    }

	    if(permission.includes('view_retailer'))
	    {
	      this.setState(
	      {
	        view_retailer: true
	      });
	    }

        const user_id = localStorage.getItem('id');
        axios({
            method: 'get',
            url: APIURL+'get-dashboard-details',
            headers: {
              'x-access-token': localStorage.getItem('token'),  
              'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
          })
          .then((res) => 
          {
              if(res.data.statusCode == 200)
              {
                this.setState({
                    data: res.data.details,
                    total_rsm: res.data.details[0].total_rsm,
                    total_kre: res.data.details[0].total_kre,
                    loading: false,
                });
              }
              else if(res.data.statusCode == 500)
              {
                this.setState({
                    errorMessage: res.data.message
                });
              }
          })
          .catch((error) => 
          {
              if (axios.isCancel(error) || error) {
                  this.setState({
                      loading: false,
                      message: 'Failed to fetch results.Please check network',
                  });
              }
          });

             axios({
				method: 'get',
				url: APIURL+'get-total-retailer',
				headers: {
				  'x-access-token': localStorage.getItem('token'),  
				  'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
				}
			})
			.then((res) => 
			{
			  if(res.data.statusCode == 200)
			  {
			    this.setState({
			        data: res.data.details,
			        total_retailer: res.data.details[0].total_retailer,
			        loading: false,
			    });
			  }
			  else if(res.data.statusCode == 500)
			  {
			    this.setState({
			        errorMessage: res.data.message
			    });
			  }
			})
			.catch((error) => 
			{
			  if (axios.isCancel(error) || error) {
			      this.setState({
			          loading: false,
			          message: 'Failed to fetch results.Please check network',
			      });
			  }
			});  
    }
	
    render(){
        return(
            <>
             <SidebarNav />
            <div className="page-wrapper">
			    <div className="content container-fluid">
					<div className="page-header">
						<div className="row">
							<div className="col-sm-12">
								<h3 className="page-title">Welcome Admin!</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item active">Dashboard</li>
								</ul>
							</div>
						</div>
					</div>
                   {/* breadcrumb */}
                 	<div className="row">
						<div className="col-xl-4 col-sm-6 col-12">
							<a href="/admin/users-list?type=rm">
								<div className="card">
									<div className="card-body">
										<div className="dash-widget-header">
											<span className="dash-widget-icon text-primary border-primary">
												<i className="fe fe-users"></i>
											</span>
											<div className="dash-count">
												<h3>{this.state.total_rsm}</h3>
											</div>
										</div>
										<div className="dash-widget-info">
											<h6 className="text-muted">Total RM</h6>
											<div className="progress progress-sm">
												<div className="progress-bar bg-primary w-50"></div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-xl-4 col-sm-6 col-12">
							<a href="/admin/users-list?type=kre">
								<div className="card">
									<div className="card-body">
										<div className="dash-widget-header">
											<span className="dash-widget-icon text-success">
												<i className="fe fe-user-plus"></i>
											</span>
											<div className="dash-count">
												<h3>{this.state.total_kre}</h3>
											</div>
										</div>
										<div className="dash-widget-info">
											<h6 className="text-muted">Total KRE</h6>
											<div className="progress progress-sm">
												<div className="progress-bar bg-success w-50"></div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
						<div className="col-xl-4 col-sm-6 col-12">
							<a href="/admin/retailer-management">
								<div className="card">
									<div className="card-body">
										<div className="dash-widget-header">
											<span className="dash-widget-icon text-danger border-danger">
												<i className="fe fe-vector"></i>
											</span>
											<div className="dash-count">
												<h3>{this.state.total_retailer}</h3>
											</div>
										</div>
										<div className="dash-widget-info">
											
											<h6 className="text-muted">Total Retailer</h6>
											<div className="progress progress-sm">
												<div className="progress-bar bg-danger w-50"></div>
											</div>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
					{/*<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
									<TableAppointmentList />
							</div>
						</div>
					</div>*/}	
			     </div> 
             </div>
             </>         
        )
     }
  }
  
 export default Dashboard; 