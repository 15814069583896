import React, { Component } from 'react';
import SidebarNav from '../sidebar';
import IMG01 from '../../assets/images/avatar-01.jpg'
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class ChangePassword extends Component
{
    constructor(props)
    {
      super(props);
      this.state=
      {
        current_password:'',
        password:'',
        confirm_password:'',
        wait:false,
        submit:true
      }  
    }

    handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

    update = (event) => 
    {
      const user_id = localStorage.getItem('id');
      event.preventDefault();
      this.setState({
        submit: false,
        wait: true,
      });
      if(this.state.password == this.state.confirm_password)
      {
        axios({
            method: 'post',
            url: APIURL+'update-user-password/'+user_id,
            data: qs.stringify({
              current_password: this.state.current_password,
              password: this.state.password
            }),
            headers: 
            {
              'x-access-token': localStorage.getItem('token'),
              'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
          })
          .then((res) => 
          {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
              this.setState({
                current_password:'',
                password:'',
                confirm_password:'',
                submit: true,
                wait: false,
              });
            }
            else if(res.data.statusCode == 500)
            {
              alert(res.data.message)
              this.setState({
                submit: true,
                wait: false,
              });
            }
          })
          .catch((error) => 
          {
            if (axios.isCancel(error) || error) 
            {
              alert('Failed to fetch results.Please check network')
              this.setState({
                submit: true,
                wait: false,
              });
            }
          });
      }
      else if(this.state.password != this.state.confirm_password) 
      {
        alert('New Password & Confirm Password does not match');
        this.setState({
          submit: true,
          wait: false,
        });
      } 
    }

    render()
    {
      return(
        <div>
          <SidebarNav />
          <div className="page-wrapper">
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col">
                            <h3 className="page-title">Change Password</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/admin/dashboard">Dashboard</a></li>
                                <li className="breadcrumb-item active">Change Password</li>
                            </ul>
                        </div>
                    </div>
                </div>
               {/* page header */}
              	<div className="row">
  								<div className="col-lg-12">
  									<div className="card">
  										<div className="card-body">
                          <form onSubmit={this.update} method="post" encType="multipart/form-data" autoComplete="off">
                              <div className="service-fields mb-2">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Current Password <b>*</b></label>
                                      <input className="form-control" type="password" name="current_password" onChange={this.handleInputChange} value={this.state.current_password} placeholder="Current Password" required />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>New Password <b>*</b></label>
                                      <input className="form-control" type="password" name="password" onChange={this.handleInputChange} value={this.state.password} placeholder="New Password" required />
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="form-group">
                                      <label>Confirm Password <b>*</b></label>
                                      <input className="form-control" type="password" name="confirm_password" onChange={this.handleInputChange} value={this.state.confirm_password} placeholder="Confirm Password" required />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="submit-section">
                                {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Update</button> : null}
                                {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                              </div>
                          </form>
  										</div>
  									</div>
  								</div>
	              </div>
              </div>
          </div>
        </div>   
      );
    }
}

export default ChangePassword;