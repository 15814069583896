import React, { Component } from 'react';
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
//export const 
class AssignRetailer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
      states:[],
      districts:[],
      input: {},
      errors: {},
      region_id: '',
      state_id: '',
      district_id: '',
      rsm_list:[],
      rsm_id:'',
      kre_id:'',
      retailer:[],
      lists:'',
      kre_list:[],
      colourOptions : []
    };

  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    axios({
                  method: 'post',
                  url: APIURL+'retailer-mapping',
                  data: qs.stringify({
                    rsm_id: this.state.rsm_id,
                    kre_id: this.state.kre_id,
                    retailer: this.state.optionSelected,
                    admin_id: localStorage.getItem('id')
                  }),
                  headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                  }
                })
                .then((res) => 
                {
                  if(res.data.statusCode == 200)
                  {
                    alert(res.data.message)
                    this.props.history.push('/admin/retailer-mapping')
                  }
                  else if(res.data.statusCode == 500)
                  {
                    this.setState({
                        errorMessage: res.data.message
                    });
                  }
                })
                .catch((error) => {
                    if (axios.isCancel(error) || error) {
                        this.setState({
                            loading: false,
                            message: 'Failed to fetch results.Please check network',
                        });
                    }
                });        
  }

  componentDidMount() 
  {  
    axios.get(APIURL+'get-region-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            lists: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            lists: '',
            loading: false,
          });
        }
    })
  }

  handleRegionId = (event) =>  
  {
    const region_id = event.target.value;
    if(!region_id) 
    {
      this.setState({ region_id });
    } 
    else 
    {
      this.setState({ region_id });
    }

    axios.get(APIURL+'get-state-list/'+region_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            states: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            states: [],
            loading: false,
          });
        }
    })
  }

  handleStateId = (event) =>  
  {
    const state_id = event.target.value;
    if(!state_id) 
    {
      this.setState({ state_id });
    } 
    else 
    {
      this.setState({ state_id });
    }

    axios.get(APIURL+'get-district-list/'+state_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            districts: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            districts: [],
            loading: false,
          });
        }
    })
  }

  handleDistrict = (event) =>  
  {
    const district_id = event.target.value;
    if(!district_id) 
    {
      this.setState({ district_id });
    } 
    else 
    {
      this.setState({ district_id });
    }

    axios.get(APIURL+'get-district-wise-rsm-list/'+district_id)
    .then((res) => 
    {
        if(res.data.details.length > 0)
        {
          this.setState({
            rsm_list: res.data.details,
            loading: false,
          });
        }
        else
        {
          this.setState({
            rsm_list: [],
            loading: false,
          });
        }
    })

    axios.get(APIURL+'get-district-wise-retailer/'+district_id)
    .then((res) => 
    {
      if(res.data.details.length > 0)
      {
        this.setState({
          colourOptions: res.data.details,
          loading: false,
        });
      }
      else
      {
        this.setState({
          colourOptions: [],
          loading: false,
        });
      }
    })
  }

  handleRSM = (event) =>  
  {
    const rsm_id = event.target.value;
    if(!rsm_id) 
    {
      this.setState({ rsm_id });
    } 
    else 
    {
      this.setState({ rsm_id });
    }

    axios.get(APIURL+'get-tagged-rsm-kre/'+rsm_id)
    .then((res) => 
    {
        if(res.data.details.length > 0)
        {
          this.setState({
            kre_list: res.data.details,
            loading: false,
          });
        }
        else
        {
          this.setState({
            kre_list: [],
            loading: false,
          });
        }
    })
  }

  handleChange = (selected) => {
    this.setState({
      optionSelected: selected
    });
  };
   render() 
   {
    const { lists,data,states, districts,rsm_list,kre_list }  = this.state;
    if(lists.length == 0)
    {
      return('')
    }
    else
    {
      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
        {/* Page Wrapper */}
        <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Assign Retailer</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Retailer Mapping</a></li>
                  <li className="breadcrumb-item active">Assign Retailer</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body custom-edit-service">
                  
                  {/* Add User */}
                  <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Region <b>*</b></label>
                            <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleRegionId} required>
                              <option value="">Select Region</option>
                              {
                                lists.map((list) =>
                                {
                                  return(
                                      <option value={list.id}>{list.region}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>State <b>*</b></label>
                            <select className="form-control" placeholder="State Name" name="state_id" id="state_id" value={this.state.state_id}  onChange={this.handleStateId} required>
                              <option value="">Select State</option>
                              {
                                states.map((state) =>
                                {
                                  return(
                                      <option value={state.id}>{state.state_name}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>District <b>*</b></label>
                            <select className="form-control" name="district_id" value={this.state.district_id}  onChange={this.handleDistrict} required>   
                              <option value="">Select District</option>
                              {
                                districts.map((lis) =>
                                {
                                  return(
                                      <option value={lis.id}>{lis.district_name}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Select RM<b>*</b></label>
                            <select className="form-control" name="rsm_id" value={this.state.rsm_id}  onChange={this.handleRSM} required>   
                              <option value="">Select RM</option>
                              {
                                rsm_list.map((lis) =>
                                {
                                  return(
                                      <option value={lis.id}>{lis.full_name}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Tagged KRE <b>*</b></label>
                            <select className="form-control" name="kre_id" value={this.state.kre_id}  onChange={this.handleInputChange} required>   
                              <option value="">Select KRE</option>
                              {
                                kre_list.map((lis) =>
                                {
                                  return(
                                      <option value={lis.id}>{lis.full_name}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Select Retailer <b>*</b></label>
                              <ReactSelect name="retailer[]" options={this.state.colourOptions} isMulti closeMenuOnSelect={false} hideSelectedOptions={false} components={{ Option }} onChange={this.handleChange} allowSelectAll={true} value={this.state.optionSelected}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Submit</button>
                    </div>
                  </form>
                  {/* /Add User */}
                </div>
              </div>
            </div>      
          </div>
        </div>      
      </div>
      {/* /Page Wrapper */}
        </div>
        </>
      );
    }
   }
}

export default AssignRetailer;
