import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { DatePicker } from "antd";
import moment from 'moment';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
class AddUser extends Component {
  constructor(props) 
  {
    super(props)
    this.state = {
      member_type_detail : '',
      full_name: '',
      email: '',
      mobile: '',
      password: '',
      username: '',
      email: '',
      region_id: '',
      state_id: '',
      district_id: [],
      employee_code:'',
      address:'',
      date_of_birth:'',
      lists:'',
      states:[],
      districts:[],
      input: {},
      errors: {},
      colourOptions : [],
      stateOptions : [],
      wait:false,
      submit:true
    };
  }

  componentDidMount() 
  {  

    let permission = localStorage.getItem('permissions');
    if(!permission.includes('add_user'))
    {
      this.props.history.push('/admin/dashboard')
    }
    this.setState({username:'',password:''});
    axios.get(APIURL+'get-region-list')
    .then((res) => 
    {
      if(res.data.list.length > 0)
      {
        this.setState({
          lists: res.data.list,
          loading: false,
        });
      }
      else
      {
        this.setState({
          lists: '',
          loading: false,
        });
      }
    })

    axios.get(APIURL+'get-last-code')
    .then((res) => 
    {
      this.setState({ 
        employee_code: res.data.result[0].employee_code
      });
    })
  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleChangee = (event) =>
  {

    this.setState({
      date_of_birth: event.target.value
    });
      // let birthDate = moment(event.target.value).format("DD-MM-YYYY");
      // console.log(birthDate)
      const date18YrsAgo = new Date();
      date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
      const birth = new Date(event.target.value);
      if(birth.getFullYear() > date18YrsAgo.getFullYear())
      {
        alert("Invalid Date of Birth")
        this.setState({
          date_of_birth:''
        });
      }
  }

  handleRegionId = (event) =>  
  {
    const region_id = event.target.value;
    if(!region_id) 
    {
      this.setState({ region_id });
    } 
    else 
    {
      this.setState({ region_id });
    }

    axios.get(APIURL+'get-state-list/'+region_id)
    .then((res) => 
    {
      if(res.data.list.length > 0)
      {
        this.setState({
          states: res.data.list,
          stateOptions: res.data.list,
          loading: false,
        });
      }
      else
      {
        this.setState({
          states: [],
          stateOptions: [],
          loading: false,
        });
      }
    })
  }

  disabledDate(current) 
  {
    return current && current > moment().endOf('day');
  }

  handleStateId = (event) =>  
  {
    const state_id = event;
    console.log(state_id)
      
    if(!state_id) 
    {
      this.setState({
        state_id: state_id
      });
      //this.setState({optionSelected:[]});
    } 
    else 
    {
      this.setState({
        state_id: state_id
      });
      //this.setState({optionSelected:[]});
    }

    for (var i = 0, l = state_id.length; i < l; i++) 
    {
      axios.get(APIURL+'getDistrictList/'+state_id[i].id)
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            colourOptions: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            colourOptions: [],
            loading: false,
          });
        }
      })
    }
  

    
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    if(this.validate())
    {
      this.setState({
        submit: false,
        wait: true,
      });
      axios({
        method: 'post',
        url: APIURL+'check-unique-email',
        data: qs.stringify({
          email: this.state.email
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      }).then((res) => 
      {
        if(res.data.total[0].total == 1)
        {
          alert('Email ID already registered.Please Enter unique Email ID.');
          this.setState({
            wait: false,
            submit: true,
          });
        }
        else if(res.data.total[0].total == 0)
        {
          axios({
            method: 'post',
            url: APIURL+'check-unique-username',
            data: qs.stringify({
              username: this.state.username
            }),
            headers: {
              'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
          }).then((res) => 
          {
            if(res.data.total[0].total == 1)
            {
              alert('UserName already registered.Please Enter unique UserName.');
              this.setState({
                wait: false,
                submit: true,
              });
            }
            else if(res.data.total[0].total == 0)
            {
               axios({
                  method: 'post',
                  url: APIURL+'check-unique-phone',
                  data: qs.stringify({
                    mobile: this.state.mobile
                  }),
                  headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                  }
                }).then((res) => 
                {
                  if(res.data.total[0].total == 1)
                  {
                    alert('Phone Number already registered.Please Enter unique Phone Number.');
                    this.setState({
                      wait: false,
                      submit: true,
                    });
                  }
                  else if(res.data.total[0].total == 0)
                  {
                    if(this.state.employee_code == null)
                    {
                      var employee_code = this.state.member_type_detail+'0001';
                      this.setState({ 
                        employee_code: employee_code.toUpperCase()
                      });
                    }
                    else
                    {
                      var employeecode = this.state.employee_code.split("00");
                      var code = parseInt(employeecode[1])+parseInt(1);
                      var employee_code = this.state.member_type_detail+'000'+code;
                      this.setState({ 
                        employee_code: employee_code.toUpperCase()
                      });
                    }
                    axios({
                      method: 'post',
                      url: APIURL+'add-user',
                      data: qs.stringify({
                        member_type_detail: this.state.member_type_detail,
                        employee_code: this.state.employee_code,
                        region_id: this.state.region_id,
                        state_id: this.state.state_id,
                        district_id: this.state.optionSelected,
                        full_name: this.state.full_name,
                        mobile: this.state.mobile,
                        added_by: '1',
                        added_user_role: 'admin',
                        username: this.state.username,
                        address: this.state.address,
                        date_of_birth: this.state.date_of_birth,
                        password: this.state.password,
                        email: this.state.email,
                        tagged_with:0,
                        is_active:1,
                        admin_id: localStorage.getItem('id')
                      }),
                      headers: {
                        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                      }
                    })
                    .then((res) => 
                    {
                      if(res.data.statusCode == 200)
                      {
                        alert(res.data.message)
                        this.props.history.push('/admin/users-list')
                      }
                      else if(res.data.statusCode == 500)
                      {
                        this.setState({
                            errorMessage: res.data.message
                        });
                      }
                    })
                    .catch((error) => 
                    {
                        if (axios.isCancel(error) || error) 
                        {
                            this.setState({
                                loading: false,
                                message: 'Failed to fetch results.Please check network',
                            });
                        }
                    });
                  }
                })
            }
          })
           
        }
      })
    }  
  }

  validate()
  {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
   // console.log(this.state.username)
    if (!this.state.full_name) 
    {
      isValid = false;
      errors["full_name"] = "Please enter your Full Name.";
    }


    if (!this.state.email) 
    {
      isValid = false;
      errors["email"] = "Please enter your Email Address.";
    }

    if(this.state.username.trim().length == 0)
    {
      isValid = false;
      errors["username"] = "Please enter your username.";
    }
    else if (!this.state.username) 
    {
      isValid = false;
      errors["username"] = "Please enter your username.";
    }
    
    if(typeof this.state.mobile !== "undefined") 
    {
      var pattern = new RegExp(/^[0-9\b]+$/);
      var pat = new RegExp(/^0/);
      if (!pattern.test(this.state.mobile)) 
      {
        isValid = false;
        errors["mobile"] = "Please enter only number.";
      }
      else if(pat.test(this.state.mobile)) 
      {
        isValid = false;
        errors["mobile"] = "Invalid Mobile Number";
      }
      else if(this.state.mobile.length == '00000000000')
      {
        isValid = false;
        errors["mobile"] = "Please Enter valid phone number.";
      }
      else if(this.state.mobile.length != 10)
      {
        isValid = false;
        errors["mobile"] = "Please enter valid phone number.";
      }
    }

    if (typeof this.state.email !== "undefined") 
    {    
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.state.email)) 
      {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    if(this.state.address.trim().length == 0)
    {
      isValid = false;
      errors["address"] = "Please enter your address.";
    }
    
    if(this.state.password.trim().length == 0)
    {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }
    else if (!this.state.password) 
    {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    if(this.state.confirm_password.trim().length == 0)
    {
      isValid = false;
      errors["confirm_password"] = "Please enter your confirm password.";
    }
    else if (!this.state.confirm_password) 
    {
      isValid = false;
      errors["confirm_password"] = "Please enter your confirm password.";
    }

    if (typeof this.state.password !== "undefined" && typeof this.state.confirm_password !== "undefined") 
    {  
      if (this.state.password != this.state.confirm_password) 
      {
        isValid = false;
        errors["password"] = "Passwords don't match.";
      }
    } 
    this.setState({
      errors: errors
    });
    return isValid;
  }

  handleChange = (selected) => {
    this.setState({
      optionSelected: selected
    });
  };

  // handleChangee = (date) =>
  // {
  //   this.setState({
  //       date_of_birth: moment(date).format("DD-MM-YYYY")
  //     });
  // }

   render() 
   {
    const { lists,data,states, districts }  = this.state;
    if(lists.length == 0)
    {
      return('')
    }
    else
    {
      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
        {/* Page Wrapper */}
        <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7">
                <h3 className="page-title">User Enrolment</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Users</a></li>
                  <li className="breadcrumb-item active">Add User</li>
                </ul>
              </div>
              <div className="col-sm-5">
                <a href="/admin/users-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></a>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body custom-edit-service">
                  
                  {/* Add User */}
                  <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Member Type <b>*</b></label>
                            <select className="form-control" name="member_type_detail" value={this.state.member_type_detail}  onChange={this.handleInputChange} required> 
                              <option value="">Select Member Type</option>
                              <option value="admin">Admin</option>
                              <option value="rm">RM</option>
                              <option value="kre">KRE</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Region <b>*</b></label>
                            <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleRegionId} required>
                              <option value="">Select Region</option>
                              {
                                lists.map((list) =>
                                {
                                  return(
                                      <option value={list.id}>{list.region}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>State <b>*</b></label>
                            <ReactSelect name="state_id[]" options={this.state.stateOptions} isMulti closeMenuOnSelect={false} hideSelectedOptions={false} components={{ Option }} onChange={this.handleStateId} allowSelectAll={true} value={this.state.state_id}/>
                            
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>District <b>*</b></label>
                            <ReactSelect name="district_id[]" options={this.state.colourOptions} isMulti closeMenuOnSelect={false} hideSelectedOptions={false} components={{ Option }} onChange={this.handleChange} allowSelectAll={true} value={this.state.optionSelected}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Full Name <b>*</b></label>
                            <input className="form-control" pattern="^[a-zA-Z][\sa-zA-Z]*" type="text" name="full_name" id="full_name" value={this.state.full_name}  onChange={this.handleInputChange} placeholder="Full Name" required />
                            <div className="text-danger">{this.state.errors.full_name}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Phone Number <b>*</b></label>
                            <input className="form-control" type="text" name="mobile" id="mobile" minlength="10" maxlength="10" value={this.state.mobile}  onChange={this.handleInputChange} placeholder="Phone Number" required />
                            <div className="text-danger">{this.state.errors.mobile}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>UserName <b>*</b></label>
                            <input className="form-control"  autoComplete="off" type="text" name="username" id="username" placeholder="UserName" value={this.state.username}  onChange={this.handleInputChange} required />
                            <div className="text-danger">{this.state.errors.username}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Email <b>*</b></label>
                            <input className="form-control"  type="email" value={this.state.email}  onChange={this.handleInputChange} name="email" id="email"  placeholder="Email" required />
                            <div className="text-danger">{this.state.errors.email}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Address <b>*</b></label>
                            <input className="form-control"  type="text" name="address" id="address" placeholder="Address" value={this.state.address}  onChange={this.handleInputChange} required />
                            <div className="text-danger">{this.state.errors.address}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Date Of Birth <b>*</b></label>
                            <input type="date" name="date_of_birth" value={this.state.date_of_birth} className="form-control"  onChange={this.handleChangee} min="1971-01-01"  required/>
                            <div className="text-danger">{this.state.errors.date}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Password <b>*</b></label>
                            <input className="form-control"  type="password" name="password" id="password" maxlength="10" placeholder="Password" value={this.state.password}  onChange={this.handleInputChange} required />
                            <div className="text-danger">{this.state.errors.password}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Confirm Password <b>*</b></label>
                            <input className="form-control"  type="password" name="confirm_password" id="confirm_password" maxlength="10" value={this.state.confirm_password}  onChange={this.handleInputChange} placeholder="Confirm Password" required />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Submit</button> : null}
                      {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                    </div>
                  </form>
                  {/* /Add User */}
                </div>
              </div>
            </div>			
          </div>
        </div>			
      </div>
      {/* /Page Wrapper */}
        </div>
        </>
      );
    }
   }
}

export default AddUser;
