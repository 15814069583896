import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class AddBrand extends Component {
  constructor(props) 
  {
    super(props)
    this.state = {
      brand_name : ''
    };
  }

  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('add_brand'))
    {
      this.props.history.push('/admin/dashboard')
    }
  }

    handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

    onSubmit = (event) => 
    {
      event.preventDefault();
      this.btn.setAttribute("disabled", "disabled");
      axios({
          method: 'post',
          url: APIURL+'check-brand-name',
          data: qs.stringify({
            brand_name: this.state.brand_name
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
          if(res.data.total[0].total > 0)
          {
            alert('Brand Name Should be Unique');
            this.btn.removeAttribute("disabled");
          }
          else if(res.data.total[0].total == 0)
          {
            axios({
              method: 'post',
              url: APIURL+'add-brand',
              data: qs.stringify({
                brand_name: this.state.brand_name,
                admin_id: localStorage.getItem('id')
              }),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
              }
            })
            .then((res) => 
            {
                if(res.data.statusCode == 200)
                {
                  alert(res.data.message)
                  this.props.history.push('/admin/brand-list')
                }
                else if(res.data.statusCode == 500)
                {
                  this.setState({
                      errorMessage: res.data.message
                  });
                }
            })
            .catch((error) => {
                if (axios.isCancel(error) || error) {
                    this.setState({
                        loading: false,
                        message: 'Failed to fetch results.Please check network',
                    });
                }
            });       
          }
        })  
    }

    
   render() {
      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
        {/* Page Wrapper */}
        <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7">
                <h3 className="page-title">Add Brand</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Brand Master</a></li>
                  <li className="breadcrumb-item active">Add Brand</li>
                </ul>
              </div>
              <div className="col-sm-5">
                <Link to="/admin/brand-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body custom-edit-service">
                  
                  {/* Add User */}
                  <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Brand Name<b>*</b></label>
                            <input className="form-control" placeholder="Brand Name" name="brand_name" id="brand_name" value={this.state.brand_name}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button ref={btn => { this.btn = btn; }} className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Submit</button>
                    </div>
                  </form>
                  {/* /Add User */}
                </div>
              </div>
            </div>			
          </div>
        </div>			
      </div>
      {/* /Page Wrapper */}
        </div>
        </>
      );
   }
}

export default AddBrand;
