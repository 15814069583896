import React, { Component } from "react";
import { Table, Switch, DatePicker } from "antd";
import moment from 'moment';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import SidebarNav from "../sidebar";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import {APIURL} from '../../../matcher';
class AuditTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      startDate: ''
    };
  }

  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('revoke_privilege'))
    {
      this.props.history.push('/admin/dashboard')
    }

    axios.get(APIURL+'get-tracking-history')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            data: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            data: '',
            loading: false,
          });
        }
    })
  }
  
  handleChange = (date) =>
  {
    this.setState({
      date: moment(date).format("DD-MM-YYYY")
    });
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    var  startDate = moment(this.state.startDate).format('YYYY-MM-DD')
    axios.get(APIURL+'search-tracking?date='+startDate)
    .then((res) => 
    {
      if(res.data.list.length > 0)
      {
        this.setState({
          data: res.data.list,
          loading: false,
        });
      }
      else
      {
        alert('No Record Found for selected Parameters')
        this.setState({
          data: '',
          loading: false,
        });
      }
    })
  }
  render() {
    const { data } = this.state;

    const columns = [
      {
        title: "Date & Time",
        dataIndex: "date",
      },
      {
        title: "Module Name",
        dataIndex: "module",
      },
      {
        title: "Done By User",
        dataIndex: "full_name",
      },
      {
        title: "Action Taken",
        dataIndex: "logs",
      },
    ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Audit Tracking</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/admin/dashboard">Users</a>
                    </li>
                    <li className="breadcrumb-item active">Audit Tracking</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <form onSubmit={this.onSubmit} method="get" autoComplete="off"> 
                  <div className="row">
                    <div className="col-md-4">
                      <input type="date" className="form-control" onChange={e => this.setState({ startDate: e.target.value })} required/>
                    </div>
                    <div className="col-md-4">
                      <button className="btn btn-primary" type="submit"><i className="fa fa-search" />&nbsp;Search</button>
                    </div>
                  </div>
                  <br/>
                </form>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AuditTracking;
