
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class EditDistrict extends Component {
  constructor(props) 
  {
    super(props);
    this.state = {
      data: '',
      region_id : '',
      state_id : '',
      state_name : '',
      district_name: '',
      district_code: '',
      description:'',
      lists:'',
      states:[],
      submit:true,
      wait:false
    };
  }

  componentDidMount() 
  {  

    let permission = localStorage.getItem('permissions');
    if(!permission.includes('edit_district'))
    {
      this.props.history.push('/admin/dashboard')
    }

    axios.get(APIURL+'get-region-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            lists: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            lists: '',
            loading: false,
          });
        }
    })

    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    axios.get(APIURL+'edit-district/'+segment)
    .then((res) => 
    {
        if(res.data.statusCode == 200)
        {
          this.setState({
            data: res.data.details[0],
            region_id: res.data.details[0].region_id,
            state_id: res.data.details[0].state_id,
            state_name: res.data.details[0].state_name,
            district_name: res.data.details[0].district_name,
            district_code: res.data.details[0].district_code,
            description: res.data.details[0].description,
            loading: false,
          });
        }
        else
        {
          this.setState({
            data: '',
            loading: false,
          });
        }
    })
  }

  handleRegionId = (event) =>  
  {
    const region_id = event.target.value;
    if(!region_id) 
    {
      this.setState({ region_id });
    } 
    else 
    {
      this.setState({ region_id });
    }

    axios.get(APIURL+'get-state-list/'+region_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            states: res.data.list,
            state_name: '',
            loading: false,
          });
        }
        else
        {
          this.setState({
            states: [],
            loading: false,
          });
        }
    })
  }

  handleStateID = (event) =>  
  {
    console.log(event.target.value)
    const state_id = event.target.value;
    if(!state_id) 
    {
      this.setState({ state_id:event.target.value });
    } 
    else 
    {
      this.setState({ state_id:event.target.value });
    }
  }

  handleDistrictName = (event) =>  
  {
    const district_name = event.target.value;
    if(!district_name) 
    {
      this.setState({ district_name });
    } 
    else 
    {
      this.setState({ district_name });
    }
  }

  handleDistrictCode = (event) =>  
  {
    const district_code = event.target.value;
    if(!district_code) 
    {
      this.setState({ district_code });
    } 
    else 
    {
      this.setState({ district_code });
    }
  }

  handleDesc = (event) =>  
  {
    const description = event.target.value;
    if(!description) 
    {
      this.setState({ description });
    } 
    else 
    {
      this.setState({ description });
    }
  }

update = (event) => 
{
  const url = this.props.location.pathname;
  const segment = url.substring(url.lastIndexOf('/') + 1);
  event.preventDefault();
  this.setState({
          submit: false,
          wait: true,
        });
  axios({
      method: 'post',
      url: APIURL+'check-edit-district-name/'+segment,
      data: qs.stringify({
        district_name: this.state.district_name
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }).then((res) => 
    {
      if(res.data.total[0].total > 0)
      {
        alert('District Name Should be Unique.');
        this.setState({
          submit: true,
          wait: false,
        });
      }
      else if(res.data.total[0].total == 0)
      {
        axios({
          method: 'post',
          url: APIURL+'check-edit-district-code/'+segment,
          data: qs.stringify({
            district_code: this.state.district_code
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
          if(res.data.total[0].total > 0)
          {
            alert('District Code Should be Unique');
            this.setState({
              submit: false,
              wait: true,
            });
          }
          else if(res.data.total[0].total == 0)
          {
            axios({
                method: 'post',
                url: APIURL+'update-district/'+segment,
                data: qs.stringify({
                  region_id: this.state.region_id,
                  state_id: this.state.state_id,
                  district_name: this.state.district_name,
                  district_code: this.state.district_code,
                  description:   this.state.description,
                  admin_id: localStorage.getItem('id')
                }),
                headers: {
                  'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
              })
              .then((res) => 
              {
                  if(res.data.statusCode == 200)
                  {
                    alert(res.data.message)
                    this.props.history.push('/admin/district-list')
                  }
                  else if(res.data.statusCode == 500)
                  {
                    this.setState({
                        errorMessage: res.data.message
                    });
                  }
              })
              .catch((error) => {
                  if (axios.isCancel(error) || error) {
                      this.setState({
                          loading: false,
                          message: 'Failed to fetch results.Please check network',
                      });
                  }
              });
          }
        })       
      }
    })  
}

  render() {
    const { lists,data,states }  = this.state;
    if(lists.length == 0)
    {
      return('')
    }
    else
    {
      if(data != undefined)
      {
        return (        
          <>
          <SidebarNav />
          <div className="main-wrapper">
            {/* Page Wrapper */}
            <div className="page-wrapper">
              <div className="content container-fluid">
                {/* Page Header */}
                <div className="page-header">
                  <div className="row">
                    <div className="col-sm-7">
                      <h3 className="page-title">Edit District</h3>
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="">District List</a></li>
                        <li className="breadcrumb-item active">Edit District</li>
                      </ul>
                    </div>
                    <div className="col-sm-5">
                      <Link to="/admin/district-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
                    </div>
                  </div>
                </div>
                {/* /Page Header */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body custom-edit-service">
                        
                        {/* Edit User */}
                        <form method="post" encType="multipart/form-data" autoComplete="off" onSubmit={this.update}>
                          <div className="service-fields mb-2">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Select Region<b>*</b></label>
                                  <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleRegionId} required>
                                    <option value=""></option>
                                    {
                                      lists.map((list) =>
                                      {
                                        return(
                                            <option value={list.id}>{list.region}</option>
                                          )
                                      })
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Select State<b>*</b></label>
                                  <select className="form-control" placeholder="State Name" name="state_id" id="state_id" value={this.state.state_id}  onChange={this.handleStateID} required>
                                    <option value={data.state_id}>{data.state_name}</option>
                                    {
                                      states.map((state) =>
                                      {
                                        return(
                                            <option value={state.id}>{state.state_name}</option>
                                          )
                                      })
                                    }
                                  </select>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                          <div className="service-fields mb-2">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>District Name<b>*</b></label>
                                  <input className="form-control" placeholder="District Name" name="district_name" id="district_name" value={this.state.district_name}  onChange={this.handleDistrictName} required />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>District Code<b>*</b></label>
                                  <input className="form-control" placeholder="District Code" name="district_code" id="district_code" value={this.state.district_code}  onChange={this.handleDistrictCode} required />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="service-fields mb-2">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Description <b>*</b></label>
                                  <textarea className="form-control" value={this.state.description}  onChange={this.handleDesc} rows="5" name="description" required></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="submit-section">
                            {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Update</button> : null}
                            {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                          </div>
                        </form>
                        {/* /Edit User */}
                      </div>
                    </div>
                  </div>      
                </div>
              </div>      
            </div>
            {/* /Page Wrapper */}
          </div>
          </>
        );
      }
      else if(data == undefined)
      {
        return('')
      }
    }  
   }
}

export default EditDistrict;
