import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo-white.png';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class ForgotPassword extends Component{
	constructor(props) 
	{
	    super(props)
	    this.state = 
	    {
	      mobile : '',
	      otp:'',
	      password:'',
	      cpassword:'',
	      input: {},
      	  errors: {},
      	  wait:false,
      	  submit:true,
      	  otpsubmit:false,
      	  passwordScreen:false,
      	  forgetScreen:true,
      	  otpScreen:false
      	 
	    };
  	}

  	handleInputChange = (event) => 
  	{
	    const { value, name } = event.target;
	    this.setState({
	      [name]: value,
	      otpsubmit:true
	    });
  	}


	onSubmit = (event) => 
	{
		event.preventDefault();
		let input = this.state.input;
	    let errors = {};
	    let isValid = true;
	    
	    if(typeof this.state.mobile !== "undefined") 
	    {
	      var pattern = new RegExp(/^[0-9\b]+$/);
	      if (!pattern.test(this.state.mobile)) 
	      {
	        isValid = false;
	        errors["mobile"] = "Please enter only number.";
	      }
	      else if(this.state.mobile.length == '00000000000')
	      {
	        isValid = false;
	        errors["mobile"] = "Please Enter valid phone number.";
	      }
	      else if(this.state.mobile.length != 10)
	      {
	        isValid = false;
	        errors["mobile"] = "Please enter valid phone number.";
	      }
	    }

	    if(isValid == true)
	    {
	    	this.setState({
		        submit: false,
		        wait: true,
		      });
	    	localStorage.setItem('mobile', this.state.mobile)
			axios({
	              method: 'post',
	              url: APIURL+'send-otp',
	              data: qs.stringify({
	                mobile: this.state.mobile,
	                login_for:'admin'
	              }),
	              headers: {
	                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
	              }
	            })
	            .then((res) => 
	            {
	              if(res.data.statusCode == 200)
	              {
	              	localStorage.setItem('otp', res.data.otp)
	              	
	                this.setState({
	                	successMessage: res.data.message,
			            forgetScreen: false,
			            otpScreen: true,
			          });
	              }
	              else if(res.data.statusCode == 500)
	              {
	                this.setState({
	                    errorMessage: res.data.message,
	                    forgetScreen: true,
			            otpScreen: false
	                });
	              }
	            })
	            .catch((error) => 
	            {
	                if (axios.isCancel(error) || error) 
	                {
	                    this.setState({
	                        loading: false,
	                        message: 'Failed to fetch results.Please check network',
	                    });
	                }
	            });
	    }
			
	}

	onUpdate = (event) => 
	{
		event.preventDefault();

	    if(this.state.password == this.state.cpassword)
	    {
	    	this.setState({
		        submit: false,
		        wait: true,
		      });
	    	
			axios({
	              method: 'post',
	              url: APIURL+'change-password',
	              data: qs.stringify({
	                password: this.state.password,
	                mobile: localStorage.getItem('mobile')
	              }),
	              headers: {
	                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
	              }
	            })
	            .then((res) => 
	            {
	              if(res.data.statusCode == 200)
	              {
	              	alert(res.data.message)
	              	this.props.history.push('/admin/login')
	              }
	              else if(res.data.statusCode == 500)
	              {
	                this.setState({
	                    errorMessage: res.data.message,
	                    forgetScreen: true,
			            otpScreen: false
	                });
	              }
	            })
	            .catch((error) => 
	            {
	                if (axios.isCancel(error) || error) 
	                {
	                    this.setState({
	                        loading: false,
	                        message: 'Failed to fetch results.Please check network',
	                    });
	                }
	            });
	    }
	    else
	    {
	    	alert('Current Password does not match with confirm password.')
	    }
			
	}

	onUpdatee = (event) => 
	{
		if(this.state.otp == localStorage.getItem('otp'))
		{
			this.setState({
	            passwordScreen: true,
	            forgetScreen: false,
	            successMessage: false,
	            otpScreen: false,
	            wait: false,
	            submit: true
	          });
		}
		else
		{
            alert('OTP does not match.')
		}
	}	

	
    render(){
        return(
            <div>
                <div className="main-wrapper login-body">
		            <div className="login-wrapper">
		            	<div className="container">
		                	<div className="loginbox">
		                    	<div className="login-left">
									<img className="img-fluid" src={Logo} alt="Logo"/>
		                        </div>
		                        {this.state.forgetScreen ?
		                        <div className="login-right">
									<div className="login-right-wrap">
										{ this.state.errorMessage &&
											<div className="alert alert-danger alert-dismissible fade show" role="alert">
								              <strong>Error!</strong> { this.state.errorMessage }
								            </div>
							        	}
										<h1>Forgot Password</h1>
										<br/>
										<form onSubmit={this.onSubmit} method="post">
											<div className="form-group">
												<input className="form-control" type="text" maxlength="10" minlength="10" placeholder="Mobile No." value={this.state.mobile} name="mobile" onChange={this.handleInputChange} required/>
												<div className="text-danger">{this.state.errors.mobile}</div>
											</div>
											<div className="form-group">
												{this.state.submit ? <button className="btn btn-primary btn-block" type="submit">Send OTP</button> : null}
                      							{this.state.wait ? <button  className="btn btn-primary btn-block" type="button">Please Wait...</button> : null}
											</div>
										</form>
									</div>
		                        </div>
		                    	: null }

		                    	{this.state.otpScreen ?
		                        <div className="login-right">
									<div className="login-right-wrap">
										{ this.state.error &&
											<div className="alert alert-danger alert-dismissible fade show" role="alert">
								              <strong>Error!</strong> { this.state.error }
								            </div>
							        	}
							        	{ this.state.successMessage &&
											<div className="alert alert-success alert-dismissible fade show" role="alert">
								              <strong>Success!</strong> { this.state.successMessage }
								            </div>
							        	}
										<h1>OTP</h1>
										<br/>
										<form onSubmit={this.onUpdatee} action="#" method="post">
											<div className="form-group">
												<input className="form-control" type="text" maxlength="4" minlength="4" placeholder="Enter 4 digit OTP." value={this.state.otp} name="otp" onChange={this.handleInputChange} required/>
												<div className="text-danger">{this.state.errors.otp}</div>
											</div>
											<div className="form-group">
												{this.state.otpsubmit ? <button className="btn btn-primary btn-block" type="submit">Submit</button> : null}
											</div>
										</form>
									</div>
		                        </div>
		                    	: null }
		                    	{this.state.passwordScreen ?
									<div className="login-right">
										<div className="login-right-wrap">
											{ this.state.errorMessage &&
												<div className="alert alert-danger alert-dismissible fade show" role="alert">
									              <strong>Error!</strong> { this.state.errorMessage }
									            </div>
									    	}
									    	{ this.state.successMessage &&
												<div className="alert alert-success alert-dismissible fade show" role="alert">
									              <strong>Success!</strong> { this.state.successMessage }
									            </div>
									    	}
											<h1>Change Password</h1>
											<br/>
											<form onSubmit={this.onUpdate} method="post">
												<div className="form-group">
													<input className="form-control" type="password" maxlength="6" minlength="4" placeholder="Current Password" value={this.state.password} name="password" onChange={this.handleInputChange} required/>
												</div>
												<div className="form-group">
													<input className="form-control" type="password" maxlength="6" minlength="4" placeholder="Confirm Password" value={this.state.cpassword} name="cpassword" onChange={this.handleInputChange} required/>
												</div>
												<div className="form-group">
												{this.state.submit ? <button className="btn btn-primary btn-block" type="submit">Change</button> : null}
                      							{this.state.wait ? <button  className="btn btn-primary btn-block" type="button">Please Wait...</button> : null}
												</div>
											</form>
										</div>
									</div>
									: null }
		                    	
		                    </div>
		                </div>
		            </div>
        		</div>
            </div>
        );
    }
}

export default ForgotPassword;