import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import { DatePicker } from "antd";
import moment from 'moment';
import axios from 'axios';
import {APIURL} from '../../../matcher';
import {MAINURL} from '../../../matcher';
class EditRetailer extends Component {
  constructor(props) 
  {
    super(props)
    this.state = {
      data:'',
      region_id: '',
      state_id: '',
      district_id: '',
      retailer_mobile:'',
      retailer_customer_code:'',
      retailership_name:'',
      retailer_contact_person_name:'',
      birthday:'',
      anniversary:'',
      address_1:'',
      address_2:'',
      pincode:'',
      retailer_type:'',
      point_of_identity:'',
      store_image:'',
      point_of_address:'',
      visiting_card:'',
      latitude:'',
      longitude:'',
      old_point_of_identity:'',
      old_store_image:'',
      old_point_of_address:'',
      old_visiting_card:'',
      lists:[],
      states:[],
      districts:[],
      input: {},
      errors: {},
      wait:false,
      submit:true,
      existing_retailer:''
    };

    this.handleAnChange = this.handleAnChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onFileChangeOne = this.onFileChangeOne.bind(this);
    this.onFileChangeTwo = this.onFileChangeTwo.bind(this);
    this.onFileChangeThree = this.onFileChangeThree.bind(this);
  }

  onFileChange(event)
  {
    const fileType = event.target.files[0].type;
    if(fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/jpeg')
    {
      this.setState({ 
        visiting_card: event.target.files[0] 
      })
    }
    else
    {
      alert('Only .png, .jpg, .jpeg files are accept');
      event.target.value = null;
    }
  }

  onFileChangeOne(event)
  {
    const fileType = event.target.files[0].type;
    if(fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/jpeg')
    {
      this.setState({ 
        point_of_identity: event.target.files[0] 
      })
    }
    else
    {
      alert('Only .png, .jpg, .jpeg files are accept');
      event.target.value = null;
    }
  }

  onFileChangeTwo(event)
  {
    const fileType = event.target.files[0].type;
    if(fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/jpeg')
    {
      this.setState({ 
        store_image: event.target.files[0] 
      })
    }
    else
    {
      alert('Only .png, .jpg, .jpeg files are accept');
      event.target.value = null;
    }
  }

  onFileChangeThree(event)
  {
    const fileType = event.target.files[0].type;
    if(fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/jpeg')
    {
      this.setState({ 
        point_of_address: event.target.files[0] 
      })
    }
    else
    {
      alert('Only .png, .jpg, .jpeg files are accept');
      event.target.value = null;
    }
  }

  componentDidMount() 
  {  

    let permission = localStorage.getItem('permissions');
    if(!permission.includes('edit_retailer'))
    {
      this.props.history.push('/admin/dashboard')
    }

    axios.get(APIURL+'get-region-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            lists: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            lists: '',
            loading: false,
          });
        }
    })

    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    axios.get(APIURL+'edit-retailer/'+segment)
    .then((res) => 
    {
      if(res.data.statusCode == 200)
      {
        this.setState({
          data: res.data.details[0],
          retailer_customer_code: res.data.details[0].retailer_customer_code,
          retailership_name: res.data.details[0].retailership_name,
          retailer_mobile: res.data.details[0].retailer_mobile,
          retailer_contact_person_name: res.data.details[0].retailer_contact_person_name,
          birthday: res.data.details[0].birthday,
          anniversary: res.data.details[0].anniversary,
          address_1: res.data.details[0].address_1,
          address_2: res.data.details[0].address_2,
          pincode: res.data.details[0].pincode,
          region_id: res.data.details[0].region_id,
          district_id: res.data.details[0].district_id,
          state_id: res.data.details[0].state_id,
          old_visiting_card: res.data.details[0].visiting_card,
          old_store_image: res.data.details[0].store_image,
          old_point_of_address: res.data.details[0].point_of_address,
          old_point_of_identity: res.data.details[0].point_of_identity,
          longitude: res.data.details[0].longitude,
          latitude: res.data.details[0].latitude,
          existing_retailer: res.data.details[0].existing_retailer,
          // state_name: res.data.details[0].state_name,
          loading: false,
        });
        console.log(this.state.birthday)
      }
      else
      {
        this.setState({
          data: '',
          loading: false,
        });
      }
    })
  }
  
  handleChange = (event) =>
  {

    this.setState({
      birthday: event.target.value
    });
    const date18YrsAgo = new Date();
    date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
    const birth = new Date(event.target.value);
    if(birth.getFullYear() > date18YrsAgo.getFullYear())
    {
      alert("Invalid Birthday Date.")
      this.setState({
        birthday:''
      });
    }
  }

  handleAnChange = (event) =>
  {
    this.setState({
      anniversary: event.target.value
    });

    const anvDate = new Date(event.target.value);
    const birthDate = new Date(this.state.birthday);
    if(anvDate.getFullYear() <= birthDate.getFullYear())
    {
      alert("Anniversary Date Should be greater than birthday Date.")
      this.setState({
        anniversary:''
      });
    }
  }

  disabledDate(current) 
  {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  disabledDatee(current) 
  {
    return current && current > moment().endOf('day');
  }
  
  handleRegionId = (event) =>  
  {
    const region_id = event.target.value;
    if(!region_id) 
    {
      this.setState({ region_id });
    } 
    else 
    {
      this.setState({ region_id });
    }

    axios.get(APIURL+'get-state-list/'+region_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            states: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            states: [],
            loading: false,
          });
        }
    })
  }

  handleStateId = (event) =>  
  {
    const state_id = event.target.value;
    if(!state_id) 
    {
      this.setState({ state_id });
    } 
    else 
    {
      this.setState({ state_id });
    }

    axios.get(APIURL+'get-district-list/'+state_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            districts: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            districts: [],
            loading: false,
          });
        }
    })

    
  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    if(this.validate())
    {
      const url = this.props.location.pathname;
      const segment = url.substring(url.lastIndexOf('/') + 1);
      this.setState({
          submit: false,
          wait: true,
        });

        console.log(this.state.state_id)
        console.log(this.state.district_id)
      const formData = new FormData();
      formData.append("retailer_customer_code", this.state.retailer_customer_code);
      formData.append("retailership_name", this.state.retailership_name);
      formData.append("retailer_mobile", this.state.retailer_mobile);
      formData.append("retailer_contact_person_name", this.state.retailer_contact_person_name);
      formData.append("birthday", this.state.birthday);
      formData.append("anniversary", this.state.anniversary);
      formData.append("address_1", this.state.address_1);
      formData.append("address_2", this.state.address_2);
      formData.append("pincode", this.state.pincode);
      formData.append("state_id", this.state.state_id);
      formData.append("region_id", this.state.region_id);
      formData.append("district_id", this.state.district_id);
      // formData.append("rsm_id", 0);
      // formData.append("kre_id", 0);
      formData.append("visiting_card",this.state.visiting_card);
      formData.append("store_image",this.state.store_image);
      formData.append("point_of_address",this.state.point_of_address);
      formData.append("point_of_identity",this.state.point_of_identity);
      formData.append("old_visiting_card",this.state.old_visiting_card);
      formData.append("old_store_image",this.state.old_store_image);
      formData.append("old_point_of_address",this.state.old_point_of_address);
      formData.append("longitude",this.state.longitude);
      formData.append("latitude",this.state.latitude);
      formData.append("old_point_of_identity",this.state.old_point_of_identity);
      formData.append("existing_retailer",this.state.existing_retailer);
      formData.append("admin_id", localStorage.getItem('id'));
      console.log(formData)
      axios
      .post(APIURL+'update-retailer/'+segment, formData)
      .then((res) => 
      {
        if(res.data.statusCode == 200)
        {
          alert(res.data.message)
          this.props.history.push('/admin/retailer-management')
        }
        else if(res.data.statusCode == 500)
        {
          this.setState({
              errorMessage: res.data.message
          });
        }
      })
      .catch((error) => {
          if (axios.isCancel(error) || error) {
              this.setState({
                  loading: false,
                  message: 'Failed to fetch results.Please check network',
              });
          }
      });
    }         
  }

  validate()
  {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    
    if(this.state.birthday)
    {
      const date18YrsAgo = new Date();
      date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
      const birth = new Date(this.state.birthday);
      birth.setFullYear(birth.getFullYear() - 18);
      
      if((birth >= date18YrsAgo) == true)
      {
        isValid = false;
        errors["date"] = "Birthday Date should not be valid";
      }
    }

    if(this.state.address_1.trim().length == 0)
    {
      isValid = false;
      errors["address_1"] = "Please enter your address.";
    }

    if(typeof this.state.retailer_mobile !== "undefined") 
    {
      var pattern = new RegExp(/^[0-9\b]+$/);
      var pat = new RegExp(/^0/);
      if (!pattern.test(this.state.retailer_mobile)) 
      {
        isValid = false;
        errors["mobile"] = "Please enter only number.";
      }
      else if(pat.test(this.state.retailer_mobile)) 
      {
        isValid = false;
        
        errors["mobile"] = "Invalid Mobile Number";
      }
      else if(this.state.retailer_mobile.length != 10)
      {
        isValid = false;
        errors["mobile"] = "Please enter valid phone number.";
      }
    }

    if(typeof this.state.pincode !== "undefined") 
    {
      var pattern = new RegExp(/^[0-9\b]+$/);
      var pat = new RegExp(/^0/);
      if (!pattern.test(this.state.pincode)) 
      {
        isValid = false;
        errors["pincode"] = "Please enter only number.";
      }
      else if(pat.test(this.state.pincode)) 
      {
        isValid = false;
        errors["pincode"] = "Invalid PinCode";
      }
      
    }

    this.setState({
      errors: errors
    });
    return isValid;
  }

  render() 
  {
    const { lists,data,states, districts }  = this.state;
    if(data.length == 0)
    {
      return('')
    }
    else
    {
      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
        {/* Page Wrapper */}
        <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7">
                <h3 className="page-title">Edit Retailer</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Retailer Management</a></li>
                  <li className="breadcrumb-item active">Edit Retailer</li>
                </ul>
              </div>
              <div className="col-sm-5">
                <a href="/admin/retailer-management" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></a>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body custom-edit-service">
                  
                  {/* Add User */}
                  <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Retailer Mobile No. <b>*</b></label>
                            <input className="form-control"  type="text" name="retailer_mobile" id="retailer_mobile" value={this.state.retailer_mobile}  onChange={this.handleInputChange} minlength="10" maxlength="10" placeholder="Retailer Mobile No." required />
                            <div className="text-danger">{this.state.errors.mobile}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Region <b>*</b></label>
                            <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleRegionId} required>
                              {
                                lists.map((list) =>
                                {
                                  return(
                                      <option value={list.id}>{list.region}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>State <b>*</b></label>
                            <select className="form-control" placeholder="State Name" name="state_id" id="state_id" value={this.state.state_id}  onChange={this.handleStateId} required>
                              <option value={data.state_id}>{data.state_name}</option>
                              {
                                states.map((state) =>
                                {
                                  return(
                                      <option value={state.id}>{state.state_name}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>District <b>*</b></label>
                            <select className="form-control" name="district_id" value={this.state.district_id}  onChange={this.handleInputChange} required>   
                              <option value={data.district_id}>{data.district_name}</option>
                              {
                                districts.map((lis) =>
                                {
                                  return(
                                      <option value={lis.id}>{lis.district_name}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>RetailerShip Name <b>*</b></label>
                            <input className="form-control"  type="text" value={this.state.retailership_name}  onChange={this.handleInputChange} name="retailership_name" id="retailership_name" placeholder="RetailerShip Name" required />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Retailer Contact Person Name <b>*</b></label>
                            <input className="form-control"  type="text" name="retailer_contact_person_name" id="retailer_contact_person_name" value={this.state.retailer_contact_person_name}  onChange={this.handleInputChange}  placeholder="Retailer Contact Person Name" required />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Birthday *</label>
                            <input type="date" name="birthday" value={this.state.birthday} className="form-control"  onChange={this.handleChange} min="1971-01-01" required/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Anniversary </label>
                            <input type="date" name="anniversary" value={this.state.anniversary} className="form-control"  onChange={this.handleAnChange} min={this.state.birthday}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Address 1 <b>*</b> </label>
                            <input className="form-control"  type="text" name="address_1" id="address_1" value={this.state.address_1}  onChange={this.handleInputChange} placeholder="Address 1" required/>
                            <div className="text-danger">{this.state.errors.address_1}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Address 2 </label>
                            <input className="form-control" type="text" name="address_2" id="address_2" value={this.state.address_2}  onChange={this.handleInputChange}  placeholder="Address 2"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Pin Code <b>*</b> </label>
                            <input className="form-control"  minlength="6" maxlength="6" type="text" value={this.state.pincode}  onChange={this.handleInputChange} name="pincode" id="pincode" placeholder="Pin Code" required/>
                            <div className="text-danger">{this.state.errors.pincode}</div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Retailer Code </label>
                            <input className="form-control" placeholder="Retailer Code" type="text" name="retailer_customer_code" id="retailer_customer_code" value={this.state.retailer_customer_code}  onChange={this.handleInputChange}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Longitude </label>
                            <input className="form-control"  type="text" value={this.state.longitude}  onChange={this.handleInputChange} name="longitude" id="longitude" placeholder="Longitude"/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Latitude </label>
                            <input className="form-control"  placeholder="Latitude" type="text" name="latitude" id="latitude" value={this.state.latitude}  onChange={this.handleInputChange}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Existing Retailer ?</label>
                            <select className="form-control"  value={this.state.existing_retailer}  onChange={this.handleInputChange} name="existing_retailer" id="existing_retailer" placeholder="existing_retailer">
                              <option value=""></option>
                              <option value="1">YES</option>
                              <option value="2">NO</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Visiting Card </label>
                            <input className="form-control"  type="file"  onChange={this.onFileChange}/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Point Of Identity </label>
                            <input className="form-control"  type="file"  onChange={this.onFileChangeOne}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Store Image </label>
                            <input className="form-control"  type="file" onChange={this.onFileChangeTwo}/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Point Of Address </label>
                            <input className="form-control"  type="file" onChange={this.onFileChangeThree}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Existing Visiting Card </label>
                            <br/>
                            {this.state.old_visiting_card ?
                            <img src={MAINURL+this.state.old_visiting_card} width="100" height="100"/>
                            : 'No Image Upload' }
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Existing Point Of Identity </label>
                            <br/>
                            {this.state.old_point_of_identity ?
                              <img src={MAINURL+this.state.old_point_of_identity} width="100" height="100"/>
                            : 'No Image Upload' }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div class="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Existing Store Image </label>
                            <br/>
                            {this.state.old_store_image ?
                            <img src={MAINURL+this.state.old_store_image} width="100" height="100"/>
                            : 'No Image Upload' }
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Existing Point Of Address </label>
                            <br/>
                            {this.state.old_point_of_address ?
                            <img src={MAINURL+this.state.old_point_of_address} width="100" height="100"/>
                            : 'No Image Upload' }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Update</button> : null}
                      {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                    </div>
                  </form>
                  {/* /Add User */}
                </div>
              </div>
            </div>			
          </div>
        </div>			
      </div>
      {/* /Page Wrapper */}
        </div>
        </>
      );
    }
  }
}

export default EditRetailer;
