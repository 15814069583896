
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class AddSubCategory extends Component {
	constructor(props) 
	{
	  super(props)
	  this.state = {
	    category_id : '',
	    sub_category: '',
	    lists: ''
	  };
	}
	componentDidMount() 
  	{  
  		let permission = localStorage.getItem('permissions');
	    if(!permission.includes('add_sub'))
	    {
	      this.props.history.push('/admin/dashboard')
	    }
        axios.get(APIURL+'category-list')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                lists: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                lists: '',
                loading: false,
              });
            }
        })
  	}

  	handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

    onSubmit = (event) => 
    {
      event.preventDefault();
      this.btn.setAttribute("disabled", "disabled");
      axios({
          method: 'post',
          url: APIURL+'check-subcategory-name',
          data: qs.stringify({
            sub_category: this.state.sub_category
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
          if(res.data.total[0].total > 0)
          {
            alert('SubCategory Name Should be Unique');
            this.btn.removeAttribute("disabled");
          }
          else if(res.data.total[0].total == 0)
          {
            axios({
	          method: 'post',
	          url: APIURL+'add-subcategory',
	          data: qs.stringify({
	            category_id: this.state.category_id,
	            sub_category: this.state.sub_category,
	            is_active:1,
	            admin_id: localStorage.getItem('id')
	          }),
	          headers: {
	            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
	          }
	        })
	        .then((res) => 
	        {
	            if(res.data.statusCode == 200)
	            {
	              alert(res.data.message)
	              this.props.history.push('/admin/subcategory-list')
	            }
	            else if(res.data.statusCode == 500)
	            {
	              this.setState({
	                  errorMessage: res.data.message
	              });
	            }
	        })
	        .catch((error) => {
	            if (axios.isCancel(error) || error) {
	                this.setState({
	                    loading: false,
	                    message: 'Failed to fetch results.Please check network',
	                });
	            }
	        });       
          }
        })  
    }

  	

   render() {
   	const { lists }  = this.state;
   	if(lists.length == 0)
	{
	  console.log(lists)	
	  return('')
	}
	else
	{
		console.log(lists)
      return (        
	        <>
		        <SidebarNav />
		        <div className="main-wrapper">
		    		{/* Page Wrapper */}
			        <div className="page-wrapper">
				        <div className="content container-fluid">
				          {/* Page Header */}
				          <div className="page-header">
				            <div className="row">
				              <div className="col-sm-7">
				                <h3 className="page-title">Add SubCategory</h3>
				                <ul className="breadcrumb">
				                  <li className="breadcrumb-item"><a href="">SubCategory List</a></li>
				                  <li className="breadcrumb-item active">Add SubCategory</li>
				                </ul>
				              </div>
				              <div className="col-sm-5">
				                <Link to="/admin/subcategory-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
				              </div>
				            </div>
				          </div>
				          {/* /Page Header */}
				          <div className="row">
				            <div className="col-sm-12">
				              <div className="card">
				                <div className="card-body custom-edit-service">
				                  
				                  {/* Add User */}
				                  <form method="post" encType="multipart/form-data" autoComplete="off" onSubmit={this.onSubmit}>
				                    <div className="service-fields mb-2">
				                      <div className="row">
				                        <div className="col-lg-6">
				                          <div className="form-group">
				                            <label>Select Category<b>*</b></label>
				                            <select className="form-control" name="category_id" id="category_id" value={this.state.category_id}  onChange={this.handleInputChange} required>
				                            <option value="">Select</option>
				                            {
							                    lists.map((list) =>
							                    {
							                      return(
							                      		<option value={list.id}>{list.category_name}</option>
							                      	)
							                  	})
							                }
				                            </select>
				                          </div>
				                        </div>
				                        <div className="col-lg-6">
				                          <div className="form-group">
				                            <label>SubCategory Name<b>*</b></label>
				                            <input className="form-control" placeholder="SubCategory Name" name="sub_category" id="sub_category" value={this.state.sub_category}  onChange={this.handleInputChange} required />
				                          </div>
				                        </div>
				                      </div>
				                    </div>
				                    <div className="submit-section">
				                      <button ref={btn => { this.btn = btn; }} className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Submit</button>
				                    </div>
				                  </form>
				                  {/* /Add User */}
				                </div>
				              </div>
				            </div>			
				          </div>
				        </div>			
			      	</div>
		      		{/* /Page Wrapper */}
		        </div>
	        </>
     	);
    }  
   }
}

export default AddSubCategory;
