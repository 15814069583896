import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Switch } from "antd";
import 'antd/dist/antd.css';
import SidebarNav from "../sidebar";
import qs from 'qs';
import axios from 'axios';
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import {APIURL} from '../../../matcher';
class KreList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      details:''
    };
  }

  componentDidMount() 
  {  
    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);

    axios.get(APIURL+'edit-user/'+segment)
    .then((res) => 
    {
      if(res.data.statusCode == 200)
      {
        this.setState({
          details: res.data.details,
          loading: false,
        });

        axios.get(APIURL+'get-kre-list/'+segment+'/'+this.state.details[0].state_id)
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                data: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                data: '',
                loading: false,
              });
            }
        })
      }
      else
      {
        this.setState({
          details:'',
          loading: false,
        });
      }
    })
    console.log(this.state.details)
    
  }

  onChangeHandle = (event,index) =>
  {
    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    const status = event;
    const is_active = '';
    const user_id = index.target.id;
    if(status == true)
    {
      const is_active = 1;
      axios({
          method: 'post',
          url: APIURL+'rsm-tagged-kre/'+user_id,
          data: qs.stringify({
            is_active: is_active,
            'tagged_with':segment,
            'tagged_by':'admin',
            'admin_id': localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    }
    else if(status == false)
    {
      const is_active = 0;
      axios({
          method: 'post',
          url: APIURL+'rsm-tagged-kre/'+user_id,
          data: qs.stringify({
            is_active: is_active,
            'tagged_with': 0,
            'tagged_by':'',
            'admin_id': localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    } 
  }

  render() {
    const { data,details} = this.state;
    
    const columns = [
      {
        title: "#",
        dataIndex: "checked_status",
        render: (e,data) => (
          <Switch id={data.id} onChange={this.onChangeHandle} defaultChecked={e}/>
        ),
      },
      {
        title: "Region",
        dataIndex: "region",
      },
      {
        title: "State",
        dataIndex: "state_name",
      },
      {
        title: "District",
        dataIndex: "district_name",
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
      },
      {
        title: "KRE Name",
        dataIndex: "full_name",
      },
      {
        title: "KRE Code",
        dataIndex: "employee_code",
      },
    ];
    if(details.length == 0)
    {
      return('');
    }
    else
    {
      console.log(details[0].full_name)
      return (
        <>
          <SidebarNav />
          <div className="page-wrapper">
            <div className="content container-fluid">
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-7 col-auto">
                    <h3 className="page-title">KRE List</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/admin/dashboard">KRE Tagged</a>
                      </li>
                      <li className="breadcrumb-item active">KRE List</li>
                    </ul>
                  </div>
                  <div className="col-sm-5">
                    <Link to="/admin/kre-tagged" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
                  </div>
                </div>
              </div>
  
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <input className="form-control" type="text" name="name" id="name" placeholder="KRE Name"/>
                    </div>
                    <div className="col-md-4">
                      <input className="form-control" type="text" name="kre_code" id="kre_code" placeholder="KRE Code"/>
                    </div>
                    <div className="col-md-4">
                      <button className="btn btn-primary" type="submit"><i className="fa fa-search" />&nbsp;Search</button>
                    </div>
                  </div>
                  <br/>
                  <div class="row">
                    <div class="col-md-4">
                      <label><b>RM Name:</b>&nbsp;{details[0].full_name}</label>
                    </div>
                    <div class="col-md-4">
                      <label><b>RM Code:</b>&nbsp;{details[0].employee_code}</label>
                    </div>
                    <div class="col-md-4">
                      <label><b>RM Mobile:</b>&nbsp;{details[0].mobile}</label>
                    </div>
                    <div class="col-md-10">
                      <label><b>RM District:</b>&nbsp;{details[0].district_name}</label>
                    </div>
                  </div>
                  <br/>
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                       
                        <Table
                          className="table-striped"
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          // bordered
                          dataSource={data}
                          rowKey={(record) => record.id}
                          showSizeChanger={true}
                          pagination={{
                            total: data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default KreList;
