import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/logo-white.png';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../../matcher';
class ReportLogin extends Component{
	constructor(props) 
	{
	    super(props)
	    this.state = {
	      username : '',
	      password: ''
	    };
  	}

  	

  	handleInputChange = (event) => 
  	{
	    const { value, name } = event.target;
	    this.setState({
	      [name]: value
	    });
  	}

  	onSubmit = (event) => 
  	{
    	event.preventDefault();
        axios({
		  method: 'post',
		  url: APIURL+'login',
		  data: qs.stringify({
		    username: this.state.username,
		    password: this.state.password,
		    login_for:'report'
		  }),
		  headers: {
		    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
		  }
		})
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
            	localStorage.setItem('id', res.data.id)
            	localStorage.setItem('user_type', res.data.user_type)
            	localStorage.setItem('full_name', res.data.full_name)
            	localStorage.setItem('username', res.data.username)
            	localStorage.setItem('email', res.data.email)
            	localStorage.setItem('mobile', res.data.mobile)
            	localStorage.setItem('token', res.data.token)
            	localStorage.setItem('login', 'report')
            	localStorage.setItem('permissions', res.data.permissions)
            	localStorage.setItem('login_id', res.data.login_id)
            	window.location.href = '/admin/report/my-profile';
            }
            else if(res.data.statusCode == 500)
            {
            	this.setState({
                	errorMessage: res.data.message
            	});
            }
        })
        .catch((error) => 
        {
            if (axios.isCancel(error) || error) 
            {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
  	}

    render()
    {
        return(
            <div>
                <div className="main-wrapper login-body">
		            <div className="login-wrapper">
		            	<div className="container">
		                	<div className="loginbox">
		                    	<div className="login-left">
									<img className="img-fluid" src={Logo} alt="Logo"/>
		                        </div>
		                        <div className="login-right">
									<div className="login-right-wrap">
										{ this.state.errorMessage &&
											<div className="alert alert-danger alert-dismissible fade show" role="alert">
								              <strong>Error!</strong> { this.state.errorMessage }
								            </div>
							        	}
										<h1>Report Login</h1>
										<p className="account-subtitle">Access to our dashboard</p>
										
										<form  onSubmit={this.onSubmit} method="post">
											<div className="form-group">
												<input className="form-control" maxlength="20" type="text" placeholder="UserName" value={this.state.username} name="username" onChange={this.handleInputChange} required/>
											</div>
											<div className="form-group">
												<input className="form-control" maxlength="20" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.handleInputChange} required/>
											</div>
											<div className="form-group">
												<button className="btn btn-primary btn-block" type="submit">Login</button>
											</div>
										</form>
										<div className="text-center forgotpass"><Link to="/admin/report/reset-password">Forgot Password?</Link></div>
										{/*<div className="login-or">
											<span className="or-line"></span>
											<span className="span-or">or</span>
										</div>
										  */}
										{/*<div className="text-center dont-have">Don’t have an account? <Link to="/admin/register">Register</Link></div>*/}
									</div>
		                        </div>
		                    </div>
		                </div>
		            </div>
        		</div>
            </div>
        );
    }
}

export default ReportLogin;