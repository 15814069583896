
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class AddProduct extends Component {
  constructor(props) 
  {
    super(props)
    this.state = {
      category_id : '',
      sub_category_id : '',
      brand_id : '',
      product_name : '',
      product_sku : '',
      color_code : '',
      description : '',
      size : '',
      unit : '',
      roll_size : '',
      is_active : '',
      brands:'',
      lists:[],
      subCate:[],
      submit:true,
      wait:false
    };
  }

  componentDidMount() 
  {  

    let permission = localStorage.getItem('permissions');
    if(!permission.includes('add_product'))
    {
      this.props.history.push('/admin/dashboard')
    }

    axios.get(APIURL+'brand-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            brands: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            brands: '',
            loading: false,
          });
        }
    })

    axios.get(APIURL+'category-list')
        .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            lists: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            lists: [],
            loading: false,
          });
        }
    })
  }
    handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

  handleCategory = (event) =>  
  {
    const category_id = event.target.value;
    if(!category_id) 
    {
      this.setState({ category_id });
    } 
    else 
    {
      this.setState({ category_id });
    }

    axios.get(APIURL+'get-subcategory-list/'+category_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            subCate: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            subCate: [],
            loading: false,
          });
        }
    })
  }

    onSubmit = (event) => 
    {
      event.preventDefault();
      this.setState({
        submit: false,
        wait: true,
      });
      // axios({
      //     method: 'post',
      //     url: APIURL+'check-product-name',
      //     data: qs.stringify({
      //       product_name: this.state.product_name
      //     }),
      //     headers: {
      //       'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      //     }
      //   }).then((res) => 
      //   {
      //     if(res.data.total[0].total == 1)
      //     {
      //       alert('Product Name should be unique');
      //       this.setState({
      //         submit: true,
      //         wait: false,
      //       });
      //     }
      //     else if(res.data.total[0].total == 0)
      //     {
                     
      //     }
      //   })
        axios({
                method: 'post',
                url: APIURL+'check-product-sku',
                data: qs.stringify({
                  product_sku: this.state.product_sku
                }),
                headers: {
                  'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
              }).then((res) => 
              {
                if(res.data.total[0].total == 1)
                {
                  alert('Product SKU Code should be unique');
                  this.setState({
                    submit: true,
                    wait: false,
                  });
                }
                else if(res.data.total[0].total == 0)
                {
                  axios({
                  method: 'post',
                  url: APIURL+'add-product',
                  data: qs.stringify({
                    category_id: this.state.category_id,
                    sub_category_id: this.state.sub_category_id,
                    brand_id: this.state.brand_id,
                    product_name: this.state.product_name,
                    product_sku: this.state.product_sku,
                    color_code: this.state.color_code,
                    description: this.state.description,
                    size: this.state.size,
                    unit: this.state.unit,
                    roll_size: this.state.roll_size,
                    is_active: 1,
                    admin_id: localStorage.getItem('id')
                  }),
                  headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                  }
                })
                .then((res) => 
                {
                    if(res.data.statusCode == 200)
                    {
                      alert(res.data.message)
                      this.props.history.push('/admin/product-list')
                    }
                    else if(res.data.statusCode == 500)
                    {
                      this.setState({
                          errorMessage: res.data.message
                      });
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error) || error) {
                        this.setState({
                            loading: false,
                            message: 'Failed to fetch results.Please check network',
                        });
                    }
                });
                }
              })

    }
   render() {
    const { brands,lists,subCate }  = this.state;
    if(brands.length == 0)
    {
      return('')
    }
    else if(lists.length == 0)
    {
      return('')
    }
    else
    {
      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
        {/* Page Wrapper */}
        <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7">
                <h3 className="page-title">Add Product</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Product (SKU) Master</a></li>
                  <li className="breadcrumb-item active">Add Product</li>
                </ul>
              </div>
              <div className="col-sm-5">
                <Link to="/admin/product-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body custom-edit-service">
                  
                  {/* Add User */}
                  <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Brand Name<b>*</b></label>
                            <select className="form-control" placeholder="Brand Name" name="brand_id" id="brand_id" value={this.state.brand_id}  onChange={this.handleInputChange} required>
                            <option value="">Select</option>
                              {
                                brands.map((list) =>
                                {
                                  return(
                                    <option value={list.id}>{list.brand_name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Category<b>*</b></label>
                            <select className="form-control" placeholder="Brand Name" name="category_id" id="category_id" value={this.state.category_id}  onChange={this.handleCategory} required>
                            <option value="">Select</option>
                              {
                                lists.map((lis) =>
                                {
                                  return(
                                    <option value={lis.id}>{lis.category_name}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Sub Category<b>*</b></label>
                            <select className="form-control" placeholder="Brand Name" name="sub_category_id" id="sub_category_id" value={this.state.brand_name}  onChange={this.handleInputChange} required>
                            <option value="">Select</option>
                              {
                                subCate.map((sub) =>
                                {
                                  return(
                                    <option value={sub.id}>{sub.sub_category}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Product Name<b>*</b></label>
                            <input className="form-control" placeholder="Product Name" name="product_name" id="product_name" value={this.state.product_name}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Color Code<b>*</b></label>
                            <input className="form-control" placeholder="Color Code" name="color_code" id="color_code" value={this.state.color_code}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>SKU Code<b>*</b></label>
                            <input className="form-control" placeholder="SKU Code" name="product_sku" id="product_sku" value={this.state.product_sku}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Size<b>*</b></label>
                            <input className="form-control" placeholder="Size" name="size" id="size" value={this.state.size}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Unit<b>*</b></label>
                            <input className="form-control" placeholder="Unit" name="unit" id="unit" value={this.state.unit}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="-groformup">
                            <label>Roll Size<b>*</b></label>
                            <input className="form-control" placeholder="Roll Size" name="roll_size" id="roll_size" value={this.state.roll_size}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Product Description<b>*</b></label>
                            <input className="form-control" placeholder="Product Description" name="description" id="description" value={this.state.description}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Submit</button> : null}
                      {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                    </div>
                  </form>
                  {/* /Add User */}
                </div>
              </div>
            </div>			
          </div>
        </div>			
      </div>
      {/* /Page Wrapper */}
        </div>
        </>
      );
    }
   }
}

export default AddProduct;
