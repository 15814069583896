import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Switch } from "antd";
import 'antd/dist/antd.css';
import axios from 'axios';
import { CSVLink } from 'react-csv'
import qs from 'qs';
import SidebarNav from "../sidebar";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import {APIURL} from '../../../matcher';
class UserList extends Component {
  constructor(props) 
  {
    super(props);
    this.state = {
      data: '',
      regions:[],
      region_id : '',
      state_id : '',
      district_id: '',
      states:[],
      districts:[],
      user_type:'',
      user_name:'',
      mobile:'',
      privilege:null,
      add_user:false,
      edit_user:false,
      check_user:false,
      revoke_privilege:false,
      set_privilege:false,
      showResults:false,
      player:[],
    };
  }

  componentDidMount() 
  {  

    let permission = localStorage.getItem('permissions');
    if(!permission.includes('view_user'))
    {
      this.props.history.push('/admin/dashboard')
    }

    if(permission.includes('add_user'))
    {
      this.setState(
      {
        add_user: true
      });
    }

    if(permission.includes('edit_user'))
    {
      this.setState(
      {
        edit_user: true
      });
    }

    if(permission.includes('check_user'))
    {
      this.setState(
      {
        check_user: true
      });
    }

    if(permission.includes('revoke_privilege'))
    {
      this.setState(
      {
        revoke_privilege:true
      })
    }

    axios.get(APIURL+'region-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            regions: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            regions: '',
            loading: false,
          });
        }
    })

    const search = window.location.search;
    const user_type = new URLSearchParams(search).get('type');
    console.log(user_type)
    if(user_type == null)
    {
      axios.get(APIURL+'user-list')
      .then((res) => 
      {
          if(res.data.list.length > 0)
          {
            this.setState({
              data: res.data.list,
              loading: false,
              
            });
          }
          else
          {
            this.setState({
              data: '',
              loading: false,
            });
          }
      })
    }
    else
    {
      // axios.get(APIURL+'search-user?user_type='+user_type)
      // .then((res) => 
      // {
      //     if(res.data.list.length > 0)
      //     {
      //       this.setState({
      //         data: res.data.list,
      //         loading: false,
      //       });
      //     }
      //     else
      //     {
      //       alert('No Record Found for selected Parameters')
      //       this.setState({
      //         data: '',
      //         loading: false,
      //       });
      //     }
      // })
      axios.get(APIURL+'search-user?region='+this.state.region_id+'&state='+this.state.state_id+'&district='+this.state.district_id+'&user_type='+user_type+'&user_name='+this.state.user_name+'&mobile='+this.state.mobile)
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            player: res.data.list,
            data: res.data.list,
            loading: false,
            showResults: true
          });
        }
        else
        {
          alert('No Record Found for selected Parameters')
          this.setState({
            data: '',
            message:'No Record Found for selected Parameters',
            loading: false,
          });
        }
      })
    }
     
  }

  onChangeHandle = (event,index) =>
  {
    const status = event;
    const is_active = '';
    const user_id = index.target.id;
    if(status == true)
    {
      const is_active = 1;
      axios({
          method: 'post',
          url: APIURL+'update-user-status/'+user_id,
          data: qs.stringify({
            is_active: is_active,
            admin_id: localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    }
    else if(status == false)
    {
      const is_active = 0;
      axios({
          method: 'post',
          url: APIURL+'update-user-status/'+user_id,
          data: qs.stringify({
            is_active: is_active,
            admin_id: localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    } 
  }

  handleRegionId = (event) =>  
  {
    const region_id = event.target.value;
    if(!region_id) 
    {
      this.setState({ region_id });
    } 
    else 
    {
      this.setState({ region_id });
    }

    axios.get(APIURL+'get-state-list/'+region_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            states: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            states: [],
            loading: false,
          });
        }
    })
  }

  handleStateId = (event) =>  
  {
    const state_id = event.target.value;
    if(!state_id) 
    {
      this.setState({ state_id });
    } 
    else 
    {
      this.setState({ state_id });
    }

    axios.get(APIURL+'get-district-list/'+state_id)
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            districts: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            districts: [],
            loading: false,
          });
        }
    })
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    if(this.state.region_id == '' && this.state.state_id == '' && this.state.district_id == '' && this.state.user_type == '' && this.state.user_name == '' && this.state.mobile == '')
    {
      alert('Please Select the Parameters')
    }
    else
    {
      axios.get(APIURL+'search-user?region='+this.state.region_id+'&state='+this.state.state_id+'&district='+this.state.district_id+'&user_type='+this.state.user_type+'&user_name='+this.state.user_name+'&mobile='+this.state.mobile)
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            player: res.data.list,
            data: res.data.list,
            loading: false,
            showResults: true
          });
        }
        else
        {
          alert('No Record Found for selected Parameters')
          this.setState({
            data: '',
            'message':'No Record Found for selected Parameters',
            loading: false,
          });
        }
      })
    }
    
  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  render() 
  {
    const { data,regions,states,districts,player} = this.state;

      const players = player
      const headers = 
      [
        {
          label:"Region",key:"region"
        },
        {
          label:"State",key:"state_name"
        },
        {
          label:"District",key:"district_name"
        },
        {
          label:"Employee Code",key:"employee_code"
        },
        {
          label:"Name",key:"full_name"
        },
        {
          label:"Username",key:"username"
        },
        {
          label:"Email",key:"email"
        },
        {
          label:"Mobile No.",key:"mobile"
        },
        {
          label:"MemberType Detail",key:"member_type_detail"
        },
        {
          label: "Enabled/Disabled",
          key: "status"
        },
      ]
       
      const csvLink = 
      {
        headers: headers,
        data: players,
        filename:"users-list.csv"
      }

      const columns = 
      [
        {
          title: "#",
          dataIndex: "id",
          sorter: (a,b) =>
          {
            if(a.length === b.length)
            {
              return a > b ? 1: -1
            }
            return a.length > b.length ? 1 : -1;
          }
        },
        {
          title: "Region",
          dataIndex: "region",
        },
        {
          title: "State",
          dataIndex: "state_name",
        },
        {
          title:"District",dataIndex:"district_name",
        },
        {
          title: "Employee Code",
          dataIndex: "employee_code",
        },
        {
          title: "Name",
          dataIndex: "full_name",
        },
        {
          title: "Username",
          dataIndex: "username",
        },
        {
          title: "Email",
          dataIndex: "email",
        },
        {
          title: "Mobile No.",
          dataIndex: "mobile",
        },
        {
          title: "MemberType Detail",
          dataIndex: "member_type_detail",
        },
        {
          title: "Enabled/Disabled",
          dataIndex: "status",
          key: "status",
          render: (e,data) => (
            this.state.check_user ? 
            <Switch
            id={data.id}
            onChange={this.onChangeHandle}
            defaultChecked={e}        
            />
            : null
          ),
        },
        {
          title: 'Actions',
          dataIndex:"id",
          render: (dataIndex,data) => (
              
              <div className="actions">
                { this.state.revoke_privilege ?
                  data.privilege ? <Link to={'/admin/revoke-privilege/'+dataIndex}  className="btn btn-sm bg-warning-light" title="Revoke Privilege"><i className="fe fe-lock"></i></Link> : null
                : null 
                } 
                { 
                  this.state.revoke_privilege ?
                  data.set_privilege ? <Link to={'/admin/assign-permissions/'+data.login_for+'/'+dataIndex}  className="btn btn-sm bg-warning-light" title="Edit Privilege"><i className="fe fe-key"></i></Link> : null
                  :null
                } 
                {this.state.edit_user ?
                  <Link to={'/admin/edit-user/'+dataIndex} className="btn btn-sm bg-success-light" title="Edit"><i className="fe fe-pencil"></i></Link>
                  : null }
                </div>
              
                ),
        },
      ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">View Users</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/admin/users-list">Users</a>
                    </li>
                    <li className="breadcrumb-item active">View Users</li>
                  </ul>
                </div>
                { this.state.add_user ?
                <div className="col-sm-5 col">
                  <a className="btn btn-primary float-right mt-2" href="/admin/add-user"><i className="fas fa-user-plus mr-1" /> Add User</a>
                </div>
                : null }
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <form onSubmit={this.onSubmit} method="get" encType="multipart/form-data" autoComplete="off">
                  <div className="row">
                    <div className="col-md-4">
                      <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleRegionId}>
                      <option value="">Select Region</option>
                        {
                          regions.map((reg) =>
                          {
                            return(
                                <option value={reg.id}>{reg.region}</option>
                              )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-md-4">
                      <select className="form-control"  name="state_id" id="state_id" value={this.state.state_id}  onChange={this.handleStateId}>
                        <option value="">Select State</option>
                        {
                          states.map((state) =>
                          {
                            return(
                                <option value={state.id}>{state.state_name}</option>
                              )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-md-4">
                      <select className="form-control" name="district_id" value={this.state.district_id}  onChange={this.handleInputChange}>   
                        <option value="">Select District</option>
                        {
                          districts.map((lis) =>
                          {
                            return(
                                <option value={lis.id}>{lis.district_name}</option>
                              )
                          })
                        }
                      </select>  
                    </div>
                  </div>
                  <br/>
                  <div className="row">
                    <div className="col-md-4">
                      <select className="form-control" name="user_type" onChange={this.handleInputChange} value={this.state.user_type}>
                        <option value="">Member Type</option> 
                        <option value="admin">Admin</option>
                        <option value="rm">RM</option>
                        <option value="kre">KRE</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <input className="form-control" type="text" onChange={this.handleInputChange} name="user_name" id="user_name" placeholder="Username" value={this.state.user_name}/>
                    </div>
                    <div className="col-md-3">
                      <input className="form-control" type="text" onChange={this.handleInputChange} name="mobile" id="mobile" maxlength="10" minlength="10" placeholder="Mobile Number" value={this.state.mobile}/>
                    </div>
                    <div className="col-md-1">
                      <button className="btn btn-primary" type="submit"><i className="fa fa-search" /></button>
                    </div>
                  </div>
                  <br/>
                </form>
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                    { this.state.showResults ?
                      <CSVLink {...csvLink} className="btn btn-primary search-btn float-right" onClick={this.handleChange}><i className="fa fa-download"></i>&nbsp;Export</CSVLink> 
                    : null }
                      </div>
                  </div>
                  { this.state.showResults ? <br/> :null }

                  { this.state.showResults ? 
                    <div className="card">
                      <div className="card-body"> 
                        <div className="table-responsive">
                          <Table
                            className="table-striped"
                            columns={columns}
                            // bordered
                            dataSource={data}
                            rowKey={(record) => record.id}
                            showSizeChanger={true}
                            pagination={{
                              total: data.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            scroll={{
                              x: 2000,
                              }}
                          />
                        </div>
                      </div>
                    </div>
                  :
                  null 
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UserList;
