import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Switch } from "antd";
import 'antd/dist/antd.css';
import SidebarNav from "../sidebar";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class TagKRE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
    };
  }

  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('kre_tagged'))
    {
      this.props.history.push('/admin/dashboard')
    }
    axios.get(APIURL+'get-rsm-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            data: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            data: '',
            loading: false,
          });
        }
    })
  }

  render() {
    const { data } = this.state;

    const columns = [
      
      {
        title: "District",
        dataIndex: "district_name",
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
      },
      {
        title: "RM Name",
        dataIndex: "full_name",
      },
      {
        title: "RM Code",
        dataIndex: "employee_code",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: 'Actions',
         dataIndex:"id",
        render: (dataIndex) => (
            <div className="actions">
                <Link to={'/admin/kre-list/'+dataIndex} className="btn btn-sm bg-warning-light"><i className="fa fa-tag"></i> KRE Tag</Link>
              </div>
              ),
      },
    ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">RM List</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0)">KRE Tagged</a>
                    </li>
                    <li className="breadcrumb-item active">RM List</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        scroll={{
                          x: 2000,
                          }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TagKRE;
