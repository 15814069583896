import React, { Component } from "react";
import { Table, Switch } from "antd";
import 'antd/dist/antd.css';
import SidebarNav from "../sidebar";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";

class RevokePrivileges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privileges_for:''
    };
  }
  handleInputChange = (event) => 
  {
    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    const {value} = event.target;
    this.props.history.push('/admin/assign-permissions/'+value+'/'+segment)
  }
  render() {
    
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Revoke Privileges</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/admin/users-list">Users</a>
                    </li>
                    <li className="breadcrumb-item active">Revoke Privileges</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-2">
                        <span className="privileges">Privileges For ?</span>
                      </div>
                      <div className="col-sm-6">
                        <select className="form-control" name="privileges_for" value={this.state.privileges_for}  onChange={this.handleInputChange} required> 
                          <option value="">Select Member Type</option>
                          <option value="admin">Admin</option>
                          <option value="report">Report</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RevokePrivileges;
