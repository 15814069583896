import React, { Component } from 'react';
import SidebarNav from '../sidebar/index';
import IMG01 from '../../../assets/images/avatar-01.jpg'
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
class ReportProfile extends Component{
    constructor(props){
        super(props);
        this.state={
            key: 1,
            show: false,
            startDate: new Date()
        }
       
    }

    handleSelect=(key)=> {

		this.setState({ key })
    }
    handleClose=()=>{
        this.setState({
            show:false
        });
    }

    handleShow=()=>{
        this.setState({
            show:true
        });
    }
    handleChange = date => {
        this.setState({
          startDate: date
        });
      };
    render(){
        return(
            <div>
                <SidebarNav />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row">
                                <div className="col">
                                    <h3 className="page-title">My Profile</h3>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard">Dashboard</a></li>
                                        <li className="breadcrumb-item active">My Profile</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                       {/* page header */}
                      	<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title d-flex justify-content-between">
											<span>Personal Details</span> 
											<Link to="/admin/report/edit-profile" className="edit-link" onClick={this.handleShow}>
                                               <i className="fa fa-edit mr-1"></i>Edit
                                            </Link>    
										</h5>
										<div className="row">
											<p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Full Name</p>
											<p className="col-sm-10">{localStorage.getItem('full_name')}</p>
										</div>
										<div className="row">
											<p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Email ID</p>
											<p className="col-sm-10">{localStorage.getItem('email')}</p>
										</div>
										<div className="row">
											<p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Phone Number</p>
											<p className="col-sm-10">{localStorage.getItem('mobile')}</p>
										</div>
										<div className="row">
											<p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">UserName</p>
											<p className="col-sm-10">{localStorage.getItem('username')}</p>
										</div>
										<div className="row">
											<p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">User Type</p>
											<p className="col-sm-10">{localStorage.getItem('user_type')}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
                      </div>
                 </div>
            </div>
        );
    }
}

export default ReportProfile;