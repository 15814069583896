import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class AddState extends Component {
	constructor(props) 
	{
	  super(props)
	  this.state = {
	    region_id : '',
	    state_name: '',
	    state_code: '',
	    description: '',
	    lists: '',
	    submit:true,
      	wait:false
	  };
	}
	componentDidMount() 
  	{  
  		let permission = localStorage.getItem('permissions');
	    if(!permission.includes('add_state'))
	    {
	      this.props.history.push('/admin/dashboard')
	    }

        axios.get(APIURL+'get-region-list')
        .then((res) => 
        {
            if(res.data.list.length > 0)
            {
              this.setState({
                lists: res.data.list,
                loading: false,
              });
            }
            else
            {
              this.setState({
                lists: '',
                loading: false,
              });
            }
        })
  	}

  	handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

    onSubmit = (event) => 
    {
      	event.preventDefault();
      	this.setState({
          submit: false,
          wait: true,
        });
      	axios({
          method: 'post',
          url: APIURL+'check-state-name',
          data: qs.stringify({
            state_name: this.state.state_name
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
          if(res.data.total[0].total > 0)
          {
            alert('State Name Already Added.Please Add Unique State Name');
            this.setState({
	          submit: true,
	          wait: false,
	        });
          }
          else if(res.data.total[0].total == 0)
          {
              axios({
                method: 'post',
                url: APIURL+'check-state-code',
                data: qs.stringify({
                  state_code: this.state.state_code
                }),
                headers: {
                  'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
              }).then((res) => 
              {
                if(res.data.total[0].total == 1)
                {
                    alert('State Code Already Added.Please Add Unique State Code');
                    this.setState({
			          submit: true,
			          wait: false,
			        });
                }
                else if(res.data.total[0].total == 0)
                {
                  axios({
			          method: 'post',
			          url: APIURL+'add-state',
			          data: qs.stringify({
			            region_id: this.state.region_id,
			            state_name: this.state.state_name,
			            state_code: this.state.state_code,
			            description: this.state.description,
			            is_active:1,
			            admin_id: localStorage.getItem('id')
			          }),
			          headers: {
			            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
			          }
			        })
			        .then((res) => 
			        {
			            if(res.data.statusCode == 200)
			            {
			              alert(res.data.message)
			              this.props.history.push('/admin/state-list')
			            }
			            else if(res.data.statusCode == 500)
			            {
			              this.setState({
			                  errorMessage: res.data.message
			              });
			            }
			        })
			        .catch((error) => {
			            if (axios.isCancel(error) || error) {
			                this.setState({
			                    loading: false,
			                    message: 'Failed to fetch results.Please check network',
			                });
			            }
			        });
                }
              })       
          }
        })  
    }

   render() {
   	const { lists }  = this.state;
   	if(lists.length == 0)
	{
	  console.log(lists)	
	  return('')
	}
	else
	{
		console.log(lists)
      return (        
	        <>
		        <SidebarNav />
		        <div className="main-wrapper">
		    		{/* Page Wrapper */}
			        <div className="page-wrapper">
				        <div className="content container-fluid">
				          {/* Page Header */}
				          <div className="page-header">
				            <div className="row">
				              <div className="col-sm-7">
				                <h3 className="page-title">Add State</h3>
				                <ul className="breadcrumb">
				                  <li className="breadcrumb-item"><a href="">State List</a></li>
				                  <li className="breadcrumb-item active">Add State</li>
				                </ul>
				              </div>
				              	<div className="col-sm-5">
			                      <Link to="/admin/state-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
			                    </div>
				            </div>
				          </div>
				          {/* /Page Header */}
				          <div className="row">
				            <div className="col-sm-12">
				              <div className="card">
				                <div className="card-body custom-edit-service">
				                  
				                  {/* Add User */}
				                  <form method="post" encType="multipart/form-data" autoComplete="off" onSubmit={this.onSubmit}>
				                    <div className="service-fields mb-2">
				                      <div className="row">
				                        <div className="col-lg-4">
				                          <div className="form-group">
				                            <label>Select Region<b>*</b></label>
				                            <select className="form-control" name="region_id" id="region_id" value={this.state.region_id}  onChange={this.handleInputChange} required>
				                            <option value=""></option>
				                            {
							                    lists.map((list) =>
							                    {
							                      return(
							                      		<option value={list.id}>{list.region}</option>
							                      	)
							                  	})
							                }
				                            </select>
				                          </div>
				                        </div>
				                        <div className="col-lg-4">
				                          <div className="form-group">
				                            <label>State Name<b>*</b></label>
				                            <input className="form-control" placeholder="State Name" name="state_name" id="state_name" value={this.state.state_name}  onChange={this.handleInputChange} required />
				                          </div>
				                        </div>
				                        <div className="col-lg-4">
				                          <div className="form-group">
				                            <label>State Code<b>*</b></label>
				                            <input className="form-control" placeholder="State Code" name="state_code" id="state_code" value={this.state.state_code}  onChange={this.handleInputChange} required />
				                          </div>
				                        </div>
				                      </div>
				                    </div>
				                    <div className="service-fields mb-2">
				                      <div className="row">
				                        <div className="col-lg-12">
				                          <div className="form-group">
				                            <label>Description <b>*</b></label>
				                            <textarea className="form-control" value={this.state.description}  onChange={this.handleInputChange} rows="5" name="description" required></textarea>
				                          </div>
				                        </div>
				                      </div>
				                    </div>
				                    <div className="submit-section">
				                      {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Submit</button> : null}
				                      {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
				                    </div>
				                  </form>
				                  {/* /Add User */}
				                </div>
				              </div>
				            </div>			
				          </div>
				        </div>			
			      	</div>
		      		{/* /Page Wrapper */}
		        </div>
	        </>
     	);
    }  
   }
}

export default AddState;
