import React, { Component } from "react";
import SidebarNav from "../sidebar";
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class ReportPermission extends Component {
  constructor(props) 
  {
    super(props)
    this.state = 
    {
      retailer_enrolment_report:false,
      rsm_enrolment_report:false,
      kre_enrolment_report:false,
      beat_plan_report:false,
      order_detailed_report:false,
      communication_report:false,
      login_report:false,
      brand_visibility_report:false,
      visit_report:false,
      wait:false,
      submit:true
    };
  }

  componentDidMount() 
  {
    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    axios.get(APIURL+'edit-user/'+segment)
    .then((res) => 
    {
      if(res.data.statusCode == 200)
      {
        this.setState({
          data: res.data.details[0],
          permissions: res.data.details[0].permissions,
        });
        let permission = this.state.permissions;
        if(permission != null)
        {
          if(permission.includes('visit_report'))
          {
            this.setState({
              visit_report:true
            });
          }

          if(permission.includes('login_report'))
          {
            this.setState({
              login_report:true
            });
          }

          if(permission.includes('brand_visibility_report'))
          {
            this.setState({
              brand_visibility_report:true
            });
          }

          if(permission.includes('communication_report'))
          {
            this.setState({
              communication_report:true
            });
          }

          if(permission.includes('order_detailed_report'))
          {
            this.setState({
              order_detailed_report:true
            });
          }

          if(permission.includes('beat_plan_report'))
          {
            this.setState({
              beat_plan_report:true
            });
          }

          if(permission.includes('kre_enrolment_report'))
          {
            this.setState({
              kre_enrolment_report:true
            });
          }

          if(permission.includes('rsm_enrolment_report'))
          {
            this.setState({
              rsm_enrolment_report:true
            });
          }

          if(permission.includes('retailer_enrolment_report'))
          {
            this.setState({
              retailer_enrolment_report:true
            });
          }
        }
      }
    });
  }      

  handleCheck = (event) =>
  {
    this.setState({retailer_enrolment_report: event.target.checked});
  }

  handleCheckRSM = (event) =>
  {
    this.setState({rsm_enrolment_report: event.target.checked});
  }

  handleCheckKRE = (event) =>
  {
    this.setState({kre_enrolment_report: event.target.checked});
  }

  handleCheckBeat = (event) =>
  {
    this.setState({beat_plan_report: event.target.checked});
  }


  handleCheckOrder = (event) =>
  {
    this.setState({order_detailed_report:event.target.checked});
  }

  handleCheckCom = (event) =>
  {
    this.setState({communication_report:event.target.checked});
  }

  handleCheckBrand = (event) =>
  {
    this.setState({brand_visibility_report:event.target.checked});
  }

  handleCheckVisit = (event) =>
  {
    this.setState({
      visit_report:event.target.checked
    });
  }

  handleCheckLogin = (event) =>
  {
    this.setState({login_report:event.target.checked});
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    let arr = [];
    for (var key in this.state) 
    {
      if(this.state[key] === true) 
      {
        if(key != 'submit')
        {
          arr.push(key);
        }
      }
    }
    this.setState({
      submit: false,
      wait: true,
    });
    const url = this.props.location.pathname;
    const admin_id = url.substring(url.lastIndexOf('/') + 1);
    const permission = url.split('/').slice(-2)[0];
    axios({
        method: 'post',
        url: APIURL+'revoke-privilege/'+admin_id,
        data: qs.stringify({
          login_for:permission,
          permissions: arr
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
      .then((res) => 
      {
        if(res.data.statusCode == 200)
        {
          alert(res.data.message)
          this.props.history.push('/admin/users-list')
        }
        else if(res.data.statusCode == 500)
        {
          this.setState({
              errorMessage: res.data.message,
              wait:false,
              submit:true
          });
        }
      })
      .catch((error) => {
          if (axios.isCancel(error) || error) {
              this.setState({
                  loading: false,
                  message: 'Failed to fetch results.Please check network',
              });
          }
      });
  };

  render() 
  {
    // if(this.state != null)
    // {
      return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Report Panel Privileges</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/admin/users-list">Users</a>
                    </li>
                    <li className="breadcrumb-item active">Report Panel Privileges</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card-body">
                  <form onSubmit={this.onSubmit} method="post" autoComplete="off">    
                    <div className="table-responsive">
                        <table id="example" className="display table-bordered dataTable no-footer">
                          <thead>
                            <tr>
                              <th colspan="5" className="text-center">Role Permission</th>
                            </tr>
                            <tr>
                                <th rowspan="2" className="text-center sorting_disabled">Module Name</th>
                                <th colspan="4" className="text-center">
                                    <div className="checkboxx">
                                        <label for="select_all">Permissions</label>
                                    </div>
                                </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr role="row">
                              <td className="text-center"><span>MIS REPORTING MODULE</span></td>    
                              <td  colspan="5">
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox" value="visit-report" checked={this.state.visit_report} onChange={this.handleCheckVisit}/>
                                    &nbsp;&nbsp;<label for="visit-report">Visit Report</label>
                                  </div>
                                </div>
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox" value="brand-visibility-report" checked={this.state.brand_visibility_report} onChange={this.handleCheckBrand}/>
                                    &nbsp;&nbsp;<label for="brand-visibility-report">Brand Visibility Report</label>
                                  </div>
                                </div>
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox" value="login-report" checked={this.state.login_report} onChange={this.handleCheckLogin}/>
                                    &nbsp;&nbsp;<label for="login-report">Login Report</label>
                                  </div>
                                </div>
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox"  value="communication-report" checked={this.state.communication_report} onChange={this.handleCheckCom}/>
                                    &nbsp;&nbsp;<label for="communication-report">Communication Report</label>
                                  </div>
                                </div>
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox" value="order-detailed-report" checked={this.state.order_detailed_report} onChange={this.handleCheckOrder}/>
                                    &nbsp;&nbsp;<label for="order-detailed-report">Order Detailed Report</label>
                                  </div>
                                </div>
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox" value="beat-plan-report" checked={this.state.beat_plan_report} onChange={this.handleCheckBeat}/>
                                    &nbsp;&nbsp;<label for="beat-plan-report">Beat Plan Report</label>
                                  </div>
                                </div>
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox" value="kre-enrolment-report" checked={this.state.kre_enrolment_report
                                    } onChange={this.handleCheckKRE}/>
                                    &nbsp;&nbsp;<label>KRE Enrolment Report</label>
                                  </div>
                                </div>
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox" value="rsm-enrolment-report" checked={this.state.rsm_enrolment_report} onChange={this.handleCheckRSM}/>
                                    &nbsp;&nbsp;<label for="rsm-enrolment-report">RM Enrolment Report</label>
                                  </div>
                                </div>
                                <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                  <div className="checkbox">
                                    <input type="checkbox"  value="retailer-enrolment-report" checked={this.state.retailer_enrolment_report} onChange={this.handleCheck}/>
                                    
                                    &nbsp;&nbsp;<label for="retailer-enrolment-report">Retailer Enrolment Report</label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                    <br/>
                    <div className="submit-section">
                      {this.state.submit ? <button className="btn btn-primary privilege-btn" type="submit">Update</button> : ''}
                      {this.state.wait ? <button  className="btn btn-primary privilege-btn" type="button">Please Wait...</button> : ''}
                    </div>
                  </form>    
                </div> 
              </div>
            </div>
          </div>
        </div>
      </>
    );
    // }
    // else if(this.state == null)
    // {
    //   return('')
    // }
  }
}

export default ReportPermission;
