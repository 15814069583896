import React, { Component } from 'react';
import SidebarNav from '../sidebar';
import IMG01 from '../../assets/images/avatar-01.jpg'
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class EditProfile extends Component{
    constructor(props){
        super(props);
        this.state = {
            data: '',
            full_name : '',
            email: '',
            mobile: '',
            username: ''
        }; 
    }

    componentDidMount() 
    {  
        const user_id = localStorage.getItem('id');
        axios({
            method: 'post',
            url: APIURL+'user-details',
            data: qs.stringify({
              user_id: user_id
            }),
            headers: {
              'x-access-token': localStorage.getItem('token'),  
              'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
          })
          .then((res) => 
          {
              if(res.data.statusCode == 200)
              {
                this.setState({
                    data: res.data.details,
                    full_name: res.data.details[0].full_name,
                    email: res.data.details[0].email,
                    mobile: res.data.details[0].mobile,
                    username: res.data.details[0].username,
                    loading: false,
                });
              }
              else if(res.data.statusCode == 500)
              {
                this.setState({
                    errorMessage: res.data.message
                });
              }
          })
          .catch((error) => 
          {
              if (axios.isCancel(error) || error) {
                  this.setState({
                      loading: false,
                      message: 'Failed to fetch results.Please check network',
                  });
              }
          });
    }

    update = (event) => 
    {
      const user_id = localStorage.getItem('id');
      event.preventDefault();
      this.btn.setAttribute("disabled", "disabled");
      axios({
        method: 'post',
        url: APIURL+'update-user-profile/'+user_id,
        data: qs.stringify({
          full_name: this.state.full_name,
          email: this.state.email,
          mobile: this.state.mobile,
          username: this.state.username
        }),
        headers: {
           'x-access-token': localStorage.getItem('token'),
           'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
        })
        .then((res) => 
        {
          if(res.data.statusCode == 200)
          {
            alert(res.data.message)
            this.btn.removeAttribute("disabled");
          }
          else if(res.data.statusCode == 500)
          {
            this.setState({
                errorMessage: res.data.message
            });
          }
        })
        .catch((error) => {
          if (axios.isCancel(error) || error) {
              this.setState({
                  loading: false,
                  message: 'Failed to fetch results.Please check network',
              });
          }
        });  
    }

    handleFullName = (event) =>  
    {
      const full_name = event.target.value;
      if(!full_name) 
      {
        this.setState({ full_name });
      } 
      else 
      {
        this.setState({ full_name });
      }
    }

    handleEmail = (event) =>  
    {
      const email = event.target.value;
      if(!email) 
      {
        this.setState({ email });
      } 
      else 
      {
        this.setState({ email });
      }
    }


    handleMobile = (event) =>  
    {
      const mobile = event.target.value;
      if(!mobile) 
      {
        this.setState({ mobile });
      } 
      else 
      {
        this.setState({ mobile });
      }
    }

    handleUserName = (event) =>  
    {
      const username = event.target.value;
      if(!username) 
      {
        this.setState({ username });
      } 
      else 
      {
        this.setState({ username });
      }
    }
    render(){
        return(
            <div>
                <SidebarNav />
                <div className="page-wrapper">
                  <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col">
                                <h3 className="page-title">Edit Profile</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/admin/dashboard">Dashboard</a></li>
                                    <li className="breadcrumb-item">My Profile</li>
                                    <li className="breadcrumb-item active">Edit Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                   {/* page header */}
                  	<div className="row">
      								<div className="col-lg-12">
      									<div className="card">
      										<div className="card-body">
                            <form onSubmit={this.update} method="post" encType="multipart/form-data" autoComplete="off">
                                <div className="service-fields mb-2">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label>Full Name <b>*</b></label>
                                        <input className="form-control" type="text" onChange={this.handleFullName} name="full_name" value={this.state.full_name} id="full_name" placeholder="Full Name" required />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <label>Email ID <b>*</b></label>
                                        <input className="form-control" type="email" name="email" id="email" onChange={this.handleEmail} value={this.state.email} placeholder="Email ID" required />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="service-fields mb-2">
                                    <div className="row">
                                        <div className="col-lg-6">
                                          <div className="form-group">
                                            <label>Mobile Number <b>*</b></label>
                                            <input className="form-control" type="text" name="mobile" onChange={this.handleMobile} value={this.state.mobile} id="mobile" minlength="10" maxlength="10" placeholder="Mobile Number" required />
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="form-group">
                                            <label>UserName <b>*</b></label>
                                            <input className="form-control" type="text" name="username" onChange={this.handleUserName}  value={this.state.username} id="username" placeholder="UserName" required />
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                  <button className="btn btn-primary submit-btn" type="submit" ref={btn => { this.btn = btn; }} name="form_submit" value="submit">Update</button>
                                </div>
                            </form>
      										</div>
      									</div>
      								</div>
			              </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default EditProfile;