import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

class SidebarNav extends Component {
    constructor(props)
    {
      super(props);
      this.state=
      {
        show: null,
        visit_report:null,
        brand_visibility:null,
        communication_report:null,
        order_detailed_report:null,
        login_report:null,
        beat_plan_report:null,
        kre_enrolment_report:null,
        rsm_enrolment_report:null,
        retailer_enrolment_report:null,
        retailer_rejection_report:null
      }
    }

  handleShow(id)
  {
    this.setState(
    {
      show: id
    })
  }

  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(permission.includes('login_report'))
    {
      this.setState(
      {
        login_report: true
      });
    }

    if(permission.includes('visit_report'))
    {
      this.setState(
      {
        visit_report: true
      });
    }

    if(permission.includes('brand_visibility_report'))
    {
      this.setState(
      {
        brand_visibility: true
      });
    }

    if(permission.includes('communication_report'))
    {
      this.setState(
      {
        communication_report: true
      });
    }

    if(permission.includes('order_detailed_report'))
    {
      this.setState(
      {
        order_detailed_report: true
      });
    }

    if(permission.includes('beat_plan_report'))
    {
      this.setState(
      {
        beat_plan_report: true
      });
    }

    if(permission.includes('kre_enrolment_report'))
    {
      this.setState(
      {
        kre_enrolment_report: true
      });
    }

    if(permission.includes('rsm_enrolment_report'))
    {
      this.setState(
      {
        rsm_enrolment_report: true
      });
    }

    if(permission.includes('retailer_enrolment_report'))
    {
      this.setState(
      {
        retailer_enrolment_report: true
      });
    }

    if(permission.includes('retailer_rejection_report'))
    {
      this.setState(
      {
        retailer_rejection_report: true
      });
    }
  }
  
  render() {
   
    const {  location } = this.props
    let pathname = location.pathname;
  return (
    <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="menu-title"> 
                <span>MIS REPORTING MODULE</span>
              </li>
              {this.state.visit_report ?
              <li className={pathname.includes('visit-report') ?"active" :""}> 
                <Link to="/admin/report/visit-report"><i className="fa fa-list" /><span>Visit Report</span></Link>
              </li>
              : null }
              {this.state.brand_visibility ?
              <li className={pathname.includes('brand-visibility-report') ?"active" :""}> 
                <Link to="/admin/report/brand-visibility-report"><i className="fa fa-rouble" /><span>Brand Visibility Report</span></Link>
              </li>
              : null }
              {this.state.login_report ? 
                <li className={pathname.includes('login-report') ?"active" :""}> 
                  <Link to="/admin/report/login-report"><i className="fa fa-sign-in" /><span>Login Report</span></Link>
                </li>
              : null }
              {this.state.communication_report ?
              <li className={pathname.includes('communication-report') ?"active" :""}> 
                <Link  to="/admin/report/communication-report"><i className="fa fa-phone" /><span>Communication Report</span></Link>
              </li>
              : null }
              {this.state.order_detailed_report ?
              <li className={pathname.includes('order-detailed-report') ?"active" :""}> 
                <Link to="/admin/report/order-detailed-report"><i className="fa fa-first-order" /><span>Order Detailed Report</span></Link>
              </li>
              : null }
              {this.state.beat_plan_report ?
              <li className={pathname.includes('beat-plan-report') ?"active" :""}> 
                <Link to="/admin/report/beat-plan-report"><i className="fa fa-tasks" /> <span>Beat Plan Report</span></Link>
              </li>
              : null }
              {this.state.kre_enrolment_report ?
              <li className={pathname.includes('kre-enrolment-report') ?"active" :""}> 
                <Link to="/admin/report/kre-enrolment-report"><i className="fas fa-users" /> <span>KRE Enrolment Report</span></Link>
              </li>
              : null }
              {this.state.rsm_enrolment_report ?
              <li className={pathname.includes('rsm-enrolment-report') ?"active" :""}> 
                <Link to="/admin/report/rsm-enrolment-report"><i className="fas fa-user-check" /> <span>RM Enrolment Report</span></Link>
              </li>
              : null }
              {this.state.retailer_enrolment_report ?
              <li className={pathname.includes('retailer-enrolment-report') ?"active" :""}> 
                <Link to="/admin/report/retailer-enrolment-report"><i className="fas fa-store-alt" /> <span>Retailer Enrolment Report</span></Link>
              </li>
              : null }

              {this.state.retailer_rejection_report ?
              <li className={pathname.includes('retailer-rejection-report') ?"active" :""}> 
                <Link to="/admin/report/retailer-rejection-report"><i className="fas fa-list-alt" /> <span>Retailer Rejection Report</span></Link>
              </li>
              : null }
            </ul>
          </div>
        </div>
      </div>
  );
}
}

export default withRouter(SidebarNav);
