import React from 'react';
import {  Route, BrowserRouter as Router, Switch, Redirect} from 'react-router-dom';

import Admin from "./components/login/index";
import ReportLogin from "./components/report/login/index";
import ForgotPassword from "./components/forget/forgotpassword";
import ForgetPassword from "./components/forget/forgetpassword";
import PrivateRoute from './PrivateRoute'
import ReportHeader from "./components/report/header/index.jsx";
import ReportProfile from "./components/report/profile/index";
import ReportEditProfile from "./components/report/profile/edit-profile";
import VisitReport from "./components/report/visit-report";
import BrandVisitReport from "./components/report/brand-visibility-report";
import LoginReport from "./components/report/login-report";
import CommunicationReport from "./components/report/communication-report";
import OrderDetailedReport from "./components/report/order-detailed-report";
import BeatPlanReport from "./components/report/beat-plan-report";
import KREReport from "./components/report/kre-enrolment-report";
import RSMReport from "./components/report/rsm-enrolment-report";
import RetailerReport from "./components/report/retailer-report";
import RetailerRejectionReport from "./components/report/retailer-rejection-report";
import Header from "./components/header/index.jsx";
import Profile from "./components/profile/index";
import EditProfile from './components/profile/edit-profile';
import ChangePassword from './components/profile/change-password';
import changePassword from './components/profile/changepassword';
import UserList from './components/users/userlist';
import AddUser from './components/users/adduser';
import EditUser from './components/users/edituser';
import AuditTracking from './components/audit-tracking/list';
import RevokePrivileges from './components/privilege/list';
import Dashboard from './components/dashboard/index';
import KREtagged from './components/kre-tags/list';
import KreList from './components/kre-tags/kre-list.jsx';
import Retailer from './components/retailer/retailerlist';
import AddRetailer from './components/retailer/addretailer';
import EditRetailer from './components/retailer/editretailer';
import BulkUploadRetailer from './components/retailer/retailer-bulk-upload.jsx';
import RetailerMapping from './components/mapping/list.jsx';
import AssignRetailer from './components/mapping/assign.jsx';
import MapRetailer from './components/retailer/mapping.jsx';
import BulkMapping from './components/retailer/bulk.jsx';
import RegionList from './components/region/list.jsx';
import AddRegion from './components/region/add.jsx';
import EditRegion from './components/region/edit.jsx';
import StateList from './components/state/list.jsx';
import AddState from './components/state/add.jsx';
import EditState from './components/state/edit.jsx';
import DistrictList from './components/district/list.jsx';
import AddDistrict from './components/district/add.jsx';
import EditDistrict from './components/district/edit.jsx';
import BrandList from './components/brand/list.jsx';
import AddBrand from './components/brand/add.jsx';
import EditBrand from './components/brand/edit.jsx';
import CategoryList from './components/category/list.jsx';
import AddCategory from './components/category/add.jsx';
import EditCategory from './components/category/edit.jsx';
import SubCategoryList from './components/sub-category/list.jsx';
import AddSubCategory from './components/sub-category/add.jsx';
import EditSubCategory from './components/sub-category/edit.jsx';
import ProductList from './components/product/list.jsx';
import AddProduct from './components/product/add.jsx';
import EditProduct from './components/product/edit.jsx';
import VisibilityList from './components/visibility/list.jsx';
import AddVisibility from './components/visibility/add.jsx';
import EditVisibility from './components/visibility/edit.jsx';
import RevokePrivilege from './components/privilege/list.jsx';
import AdminPermission from './components/privilege/admin-permission.jsx';
import ReportPermission from './components/privilege/report-permission.jsx';

const ClientAppUniversal = function (props) {
	
    return (
			<Router>
				<div>
					
					<Route path="/admin/login" exact component={Admin}/>

					<Route path="/admin/report/login" exact component={ReportLogin}/>
					<Route path="/admin/forgot-password" exact component={ForgotPassword}/>
					<Route path="/admin/report/reset-password" exact component={ForgetPassword}/>
					
					<Route render={(props)=> <Header {...props}/>} />
					<PrivateRoute path="/admin/my-profile" exact component={Profile} />
					<PrivateRoute path="/admin/edit-profile" exact component={EditProfile} />
					<PrivateRoute path="/admin/change-password" exact component={ChangePassword} />
					<PrivateRoute path="/admin/region-list" exact component={RegionList} />
					<PrivateRoute path="/admin/add-region" exact component={AddRegion} />
					<PrivateRoute path="/admin/edit-region/:id" exact component={EditRegion} />
					<PrivateRoute path="/admin/state-list" exact component={StateList} />
					<PrivateRoute path="/admin/add-state" exact component={AddState} />
					<PrivateRoute path="/admin/edit-state/:id" exact component={EditState} />
					<PrivateRoute path="/admin/district-list" exact component={DistrictList} />
					<PrivateRoute path="/admin/add-district" exact component={AddDistrict} />
					<PrivateRoute path="/admin/edit-district/:id" exact component={EditDistrict} />
					<PrivateRoute path="/admin/dashboard" exact component={Dashboard} />
					<PrivateRoute path="/admin/add-user" exact component={AddUser} />
					<PrivateRoute path="/admin/edit-user/:id" exact component={EditUser} />
					<PrivateRoute path="/admin/users-list" exact component={UserList} />
					<PrivateRoute path="/admin/audit-tracking" exact component={AuditTracking} />
					<PrivateRoute path="/admin/assign-revoke-privileges" exact component={RevokePrivileges} />
					<PrivateRoute path="/admin/retailer-management" exact component={Retailer} />
					<PrivateRoute path="/admin/add-retailer" exact component={AddRetailer} />
					<PrivateRoute path="/admin/edit-retailer/:id" exact component={EditRetailer} />
					<PrivateRoute path="/admin/map-retailer/:id" exact component={MapRetailer} />
					<PrivateRoute path="/admin/retailer-bulk-upload" exact component={BulkUploadRetailer} />
					<PrivateRoute path="/admin/bulk-mapping" exact component={BulkMapping} />
					<PrivateRoute path="/admin/kre-tagged" exact component={KREtagged} />
					<PrivateRoute path="/admin/kre-list/:id" exact component={KreList} />
					<PrivateRoute path="/admin/retailer-mapping" exact component={RetailerMapping} />
					<PrivateRoute path="/admin/assign-retailer" exact component={AssignRetailer} />
					<PrivateRoute path="/admin/brand-list" exact component={BrandList} />
					<PrivateRoute path="/admin/add-brand" exact component={AddBrand} />
					<PrivateRoute path="/admin/edit-brand/:id" exact component={EditBrand} />
					<PrivateRoute path="/admin/category-list" exact component={CategoryList} />
					<PrivateRoute path="/admin/add-category" exact component={AddCategory} />
					<PrivateRoute path="/admin/edit-category/:id" exact component={EditCategory} />
					<PrivateRoute path="/admin/subcategory-list" exact component={SubCategoryList} />
					<PrivateRoute path="/admin/add-subcategory" exact component={AddSubCategory} />
					<PrivateRoute path="/admin/edit-subcategory/:id" exact component={EditSubCategory} />
					<PrivateRoute path="/admin/product-list" exact component={ProductList} />
					<PrivateRoute path="/admin/add-product" exact component={AddProduct} />
					<PrivateRoute path="/admin/edit-product/:id" exact component={EditProduct} />							
					<PrivateRoute path="/admin/visibility-management" exact component={VisibilityList} />
					<PrivateRoute path="/admin/add-visibility" exact component={AddVisibility} />
					<PrivateRoute path="/admin/edit-visibility/:id" exact component={EditVisibility} />
					<PrivateRoute path="/admin/revoke-privilege/:id" exact component={RevokePrivileges} />
					<PrivateRoute path="/admin/assign-permissions/report/:id" exact component={ReportPermission} />
					<PrivateRoute path="/admin/assign-permissions/admin/:id" exact component={AdminPermission} />
					{/*<Route render={(props)=> <ReportHeader {...props}/>} />*/}
					<PrivateRoute path="/admin/report/my-profile" exact component={ReportProfile} />
					<PrivateRoute path="/admin/report/edit-profile" exact component={ReportEditProfile} />
					<PrivateRoute path="/admin/report/visit-report" exact component={VisitReport} />
					<PrivateRoute path="/admin/report/brand-visibility-report" exact component={BrandVisitReport} />
					<PrivateRoute path="/admin/report/login-report" exact component={LoginReport} />
					<PrivateRoute path="/admin/report/communication-report" exact component={CommunicationReport} />
					<PrivateRoute path="/admin/report/order-detailed-report" exact component={OrderDetailedReport} />
					<PrivateRoute path="/admin/report/beat-plan-report" exact component={BeatPlanReport} />
					<PrivateRoute path="/admin/report/kre-enrolment-report" exact component={KREReport} />
					<PrivateRoute path="/admin/report/rsm-enrolment-report" exact component={RSMReport} />
					<PrivateRoute path="/admin/report/retailer-enrolment-report" exact component={RetailerReport} />
					<PrivateRoute path="/admin/report/retailer-rejection-report" exact component={RetailerRejectionReport} />
					<PrivateRoute path="/admin/report/change-password" exact component={changePassword} />
					
				</div>
			</Router>
    );
}
export default ClientAppUniversal;

