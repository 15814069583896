import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class AddRegion extends Component {
  constructor(props) 
  {
    super(props)
    this.state = {
      region_name : '',
      region_code: '',
      description: '',
      submit:true,
      wait:false
    };
  }

  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('add_region'))
    {
      this.props.history.push('/admin/dashboard')
    }
  } 

    handleInputChange = (event) => 
    {
      const { value, name } = event.target;
      this.setState({
        [name]: value
      });
    }

    onSubmit = (event) => 
    {
      event.preventDefault();
        this.setState({
          submit: false,
          wait: true,
        });
      axios({
          method: 'post',
          url: APIURL+'check-region-name',
          data: qs.stringify({
            region_name: this.state.region_name
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
          if(res.data.total[0].total > 0)
          {
            alert('Region Name Already Added.Please Add Unique Region Name');
            this.setState({
              submit: true,
              wait: false,
            });
          }
          else if(res.data.total[0].total == 0)
          {
            axios({
              method: 'post',
              url: APIURL+'check-region-code',
              data: qs.stringify({
                region_code: this.state.region_code
              }),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
              }
            }).then((res) => 
            {
              if(res.data.total[0].total == 1)
              {
                alert('Region Code Already Added.Please Add Unique Region Code');
                this.setState({
                  submit: true,
                  wait: false,
                });
              }
              else if(res.data.total[0].total == 0)
              {
                axios({
                  method: 'post',
                  url: APIURL+'add-region',
                  data: qs.stringify({
                    region: this.state.region_name,
                    region_code: this.state.region_code,
                    region_description: this.state.description,
                    is_active:1,
                    admin_id: localStorage.getItem('id')
                  }),
                  headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                  }
                })
                .then((res) => 
                {
                    if(res.data.statusCode == 200)
                    {
                      alert(res.data.message)
                      this.props.history.push('/admin/region-list')
                    }
                    else if(res.data.statusCode == 500)
                    {
                      this.setState({
                          errorMessage: res.data.message
                      });
                    }
                })
                .catch((error) => 
                {
                    if (axios.isCancel(error) || error) {
                        this.setState({
                            loading: false,
                            message: 'Failed to fetch results.Please check network',
                        });
                    }
                });
              }
            })       
          }
        })  
    }
   render() {
      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
        {/* Page Wrapper */}
        <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7">
                <h3 className="page-title">Add Region</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Region Master</a></li>
                  <li className="breadcrumb-item active">Add Region</li>
                </ul>
              </div>
              <div className="col-sm-5">
                <Link to="/admin/region-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body custom-edit-service">
                  
                  {/* Add User */}
                  <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Region Name<b>*</b></label>
                            <input className="form-control" placeholder="Region Name" name="region_name" id="region_id" value={this.state.region_name}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Region Code<b>*</b></label>
                            <input className="form-control" placeholder="Region Code" name="region_code" id="region_code" value={this.state.region_code}  onChange={this.handleInputChange} required />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Region Description <b>*</b></label>
                            <textarea className="form-control" value={this.state.description}  onChange={this.handleInputChange} rows="5" name="description" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Submit</button> : null}
                      {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                    </div>
                  </form>
                  {/* /Add User */}
                </div>
              </div>
            </div>			
          </div>
        </div>			
      </div>
      {/* /Page Wrapper */}
        </div>
        </>
      );
   }
}

export default AddRegion;
