import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { CSVLink } from 'react-csv'
import SidebarNav from './sidebar/index';
import { Modal } from 'react-bootstrap';
import { DatePicker } from "antd";
import moment from 'moment';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class OrderDetailedReport extends Component{
  constructor(props) 
  {
    super(props)
    this.state = {
      startDate: '',
      endDate: '',
      name:'',
      mobile:'',
      player:[],
      showResults:false
    };
  }

  handleChange = (event) => 
  {
    this.setState({
      showResults: false,
    });
  }

  componentDidMount() 
  {  
      let permission = localStorage.getItem('permissions');
      if(!permission.includes('order_detailed_report'))
      {
          this.props.history.push('/admin/report/my-profile')
      }
  }
    
  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
      showResults: false
    });
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    if(this.state.startDate == '')
    {
      var  startDate = ''
    }
    else
    {
      var  startDate = moment(this.state.startDate).format('YYYY-MM-DD')
    }
    
    if(this.state.endDate == '')
    {
      var  endDate = ''
    }
    else
    {
      var  endDate = moment(this.state.endDate).format('YYYY-MM-DD')
    }

    if(this.state.mobile == '' && this.state.name == '' && startDate == '' && endDate == '')
    {
      alert('Please Select the Parameters')
    }
    else
    {
      axios.get(APIURL+'order-detailed-report?&mobile='+this.state.mobile+'&name='+this.state.name+'&startDate='+startDate+'&endDate='+endDate)
      .then((res) => 
      {
        console.log(res.data.list.length)
        if(res.data.list.length > 0)
        {
          this.setState({
            player: res.data.list,
            showResults: true,
          });
        }
        else
        {
          alert('No Record Found for selected Parameters')
          this.setState({
            showResults: false,
          });
        }
      })
    }  
  }

    render()
    {
      const { player,startDate}  = this.state;
      const players = player
      const headers = [
        {
          label:"Region",key:"region"
        },
        {
          label:"State",key:"state_name"
        },
        {
          label:"District",key:"district_name"
        },
        {
          label:"Retailer Customer Code",key:"retailer_customer_code"
        },
        {
          label:"Retailership Name",key:"retailership_name"
        },
        {
          label:"Retailer Mobile No",key:"retailer_mobile"
        },
        {
          label:"Retailer Contact Person Name",key:"retailer_contact_person_name"
        },
        {
          label:"Address",key:"address_1"
        },
        {
          label:"Pin Code",key:"pincode"
        },
        {
          label:"Order Date",key:"order_date"
        },
        {
          label:"Order No",key:"order_no"
        },
        {
          label:"Sub Order No",key:"sub_order_no"
        },
        {
          label:"Product Name",key:"product_name"
        },
        {
          label:"Price",key:"price"
        },
        {
          label:"Product Code",key:"product_code"
        },
        {
          label:"Total Quantity Req.",key:"total_qty"
        },
        {
          label:"Fulfilled Quantity",key:"full_filled_qty"
        },
        {
          label:"Remaining Quantity",key:"remaining_qty"
        },
        {
          label:"Order Status",key:"order_status"
        },
        {
          label:"As On Date",key:"as_on_date"
        },
        {
          label:"Remarks",key:"remarks"
        },
        {
          label:"Tagged KRE Code",key:"tagged_kre_code"
        },
        {
          label:"Tagged KRE Name",key:"tagged_kre_name"
        },
        {
          label:"RMEmployeeCode",key:"tagged_rsm_code"
        },
        {
          label:"RM NAME",key:"tagged_rsm_name"
        }
      ]
       
      const csvLink = {
        headers: headers,
        data: players,
        filename:"order-detailed-report.csv"
      }
        return(
          <div>
            <SidebarNav />
            <div className="page-wrapper">
              <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col">
                            <h3 className="page-title">Order Detailed Report</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Report</a></li>
                                <li className="breadcrumb-item active">Order Detailed Report</li>
                            </ul>
                        </div>
                    </div>
                </div>
                 {/* page header */}
                <div className="row">
                  <div className="col-lg-12">
                      <div className="card">
                          <div className="card-body">
                              <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                                  <div className="service-fields mb-2">
                                    <div className="row">
                                      <div className="col-lg-2">
                                        <div className="form-group">
                                          <label>From Date </label>
                                          <input type="date" className="form-control" onChange={e => this.setState({ startDate: e.target.value })}/>
                                        </div>
                                      </div>
                                      {startDate != '' && (
                                        <React.Fragment>
                                          <div className="col-lg-2">
                                            <div className="form-group">
                                              <label>To Date </label>
                                              <input type="date" className="form-control" min={startDate} onChange={e => this.setState({ endDate: e.target.value })}/>
                                            </div>
                                          </div>
                                       </React.Fragment>
                                      )}
                                      <div className="col-lg-3">
                                        <div className="form-group">
                                          <label>Full Name </label>
                                          <input type="text"  className="form-control" placeholder="Full Name" name="name" value={this.state.name} onChange={this.handleInputChange}/>
                                        </div>
                                      </div>
                                      <div className="col-lg-3">
                                        <div className="form-group">
                                          <label>Mobile No. </label>
                                          <input type="text" minlength="10" maxlength="10" className="form-control" placeholder="Mobile No." name="mobile" value={this.state.mobile} onChange={this.handleInputChange}/>
                                        </div>
                                      </div>
                                      <div className="col-lg-2">
                                        <div className="form-group">
                                          { this.state.showResults ? null : <button className="btn btn-primary search-btnn" type="submit" ref={btn => { this.btn = btn; }}><i className="fa fa-search"></i></button> }
                                          { this.state.showResults ? <CSVLink {...csvLink} className="btn btn-primary search-btn"  onClick={this.handleChange}><i className="fa fa-download"></i>&nbsp;Export</CSVLink> : null }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default OrderDetailedReport;