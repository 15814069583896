import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from 'jquery-slimscroll';
import logo from "../../assets/images/logo.png";
import logoicon from "../../assets/images/logo-small.png";
import avatar from "../../assets/images/avatar-01.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import $ from "jquery";
import IMG01 from "../../assets/images/doctors/doctor-thumb-01.jpg";
import IMG02 from "../../assets/images/doctors/doctor-thumb-02.jpg";
import IMG03 from "../../assets/images/doctors/doctor-thumb-03.jpg";
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';

class Header extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  removeToken = (event) => 
  {
    axios({
		  method: 'post',
		  url: APIURL+'update-logout-time/'+localStorage.getItem('login_id'),
		  headers: {
		    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
		  }
		})
    .then((res) => 
    {
        if(res.data.statusCode == 200)
        {
          localStorage.removeItem("token");
          localStorage.removeItem("login");
          localStorage.removeItem("mobile");
          localStorage.removeItem("id");
          localStorage.removeItem("full_name");
          localStorage.removeItem("username");
          localStorage.removeItem("email");
          localStorage.removeItem("user_type");
          localStorage.removeItem("permissions");
          localStorage.removeItem("login_id");
          window.location.href = "/admin/login";
        }
        else if(res.data.statusCode == 500)
        {
          this.setState({
              errorMessage: res.data.message
          });
        }
    })
    .catch((error) => {
        if (axios.isCancel(error) || error) {
            this.setState({
                loading: false,
                message: 'Failed to fetch results.Please check network',
            });
        }
    });
  };

  removeTokenOne = (event) => 
  {
    axios({
		  method: 'post',
		  url: APIURL+'update-logout-time/'+localStorage.getItem('login_id'),
		  headers: {
		    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
		  }
		})
    .then((res) => 
    {
        if(res.data.statusCode == 200)
        {
          localStorage.removeItem("token");
          localStorage.removeItem("login");
          localStorage.removeItem("mobile");
          localStorage.removeItem("id");
          localStorage.removeItem("full_name");
          localStorage.removeItem("username");
          localStorage.removeItem("email");
          localStorage.removeItem("user_type");
          localStorage.removeItem("permissions");
          localStorage.removeItem("login_id");
          window.location.href = "/admin/report/login";
        }
        else if(res.data.statusCode == 500)
        {
          this.setState({
              errorMessage: res.data.message
          });
        }
    })
    .catch((error) => {
        if (axios.isCancel(error) || error) {
            this.setState({
                loading: false,
                message: 'Failed to fetch results.Please check network',
            });
        }
    });
  };
  componentDidMount(){

    var Sidemenu = function() {
      this.$menuItem = $('#sidebar-menu a');
    };

    $('#toggle_btn').on('click', function (e) {
      if($('body').hasClass('mini-sidebar')) {
        $('body').removeClass('mini-sidebar');
        $('.subdrop + ul').slideDown();
      } else {
        $('body').addClass('mini-sidebar');
        $('.subdrop + ul').slideUp();
      }
      return false;
    });

    if(this.props.location.pathname.split("/")[1] === 'admin') {
      // require('../../assets/plugins/bootstrap-rtl/css/bootstrap.min.css')
      require('../..//assets/css/feathericon.min.css')
      require('../../assets/js/app.js');
      require('../../assets/plugins/fontawesome/css/fontawesome.min.css')
      require('../../assets/plugins/fontawesome/css/all.min.css')
      require('../../assets/css/font-awesome.min.css')
      require('../../assets/css/style.css')
    }
    
  }

  render() {
    const exclusionArray = [
      "/admin/login",
      "/admin/report/login",
      "/admin/forgot-password",
      "/admin/report/reset-password",
      "/admin/lockscreen",
      "/admin/404",
      "/admin/500",
    ];
    if (exclusionArray.indexOf(this.props.location.pathname) >= 0) 
    {
      return "";
    }
    else if(localStorage.getItem('login') == 'admin')
    {
      return (
        <div className="header">
          {/* Logo */}
          <div className="header-left">
            <Link to="/admin/dashboard" className="logo">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          {/* /Logo */}
          <a  id="toggle_btn" onClick={() => this.handlesidebar()}>
            <i className="fe fe-text-align-left" />
          </a>
          <div className="top-nav-search"></div>
          {/* Mobile Menu Toggle */}
          <a href="#0" className="mobile_btn" id="mobile_btn">
            <i className="fa fa-bars" />
          </a>
          {/* /Mobile Menu Toggle */}
          <ul className="nav user-menu">
            <li className="nav-item dropdown has-arrow">
              <Dropdown className="user-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span className="user-img">
                    <img
                      className="rounded-circle"
                      src={avatar}
                      width="31"
                      alt="Ryan Taylor"
                    />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1" className="no-padding">
                    <div className="user-header">
                      <div className="avatar avatar-sm">
                        <img
                          src={avatar}
                          alt="User"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="user-text">
                        <h6>{localStorage.getItem('full_name')}</h6>
                        <p className="text-muted mb-0">{localStorage.getItem('user_type')}</p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/admin/my-profile"><i className="fa fa-user" />&nbsp; My Profile</Dropdown.Item>
                  <Dropdown.Item href="/admin/change-password"><i className="fa fa-key" />&nbsp; Change Password</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={this.removeToken}><i className="fa fa-power-off" />&nbsp;&nbsp;Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> 
          </ul>
        </div>
      );
    }
    else if(localStorage.getItem('login') == 'report')
    {
        return (
        <div className="header">
          {/* Logo */}
          <div className="header-left">
            <Link to="/admin/report/my-profile" className="logo">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          {/* /Logo */}
          <a  id="toggle_btn" onClick={() => this.handlesidebar()}>
            <i className="fe fe-text-align-left" />
          </a>
          <div className="top-nav-search"></div>
          {/* Mobile Menu Toggle */}
          <a href="#0" className="mobile_btn" id="mobile_btn">
            <i className="fa fa-bars" />
          </a>
          {/* /Mobile Menu Toggle */}
          <ul className="nav user-menu">
            <li className="nav-item dropdown has-arrow">
              <Dropdown className="user-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span className="user-img">
                    <img
                      className="rounded-circle"
                      src={avatar}
                      width="31"
                      alt="Ryan Taylor"
                    />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1" className="no-padding">
                    <div className="user-header">
                      <div className="avatar avatar-sm">
                        <img
                          src={avatar}
                          alt="User"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="user-text">
                        <h6>{localStorage.getItem('full_name')}</h6>
                        <p className="text-muted mb-0">{localStorage.getItem('user_type')}</p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/admin/report/my-profile"><i className="fa fa-user" />&nbsp; My Profile</Dropdown.Item>
                  <Dropdown.Item href="/admin/report/change-password"><i className="fa fa-key" />&nbsp; Change Password</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={this.removeTokenOne}><i className="fa fa-power-off" />&nbsp;&nbsp;Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> 
          </ul>
        </div>
      );
    }
  }
}

export default Header;
