import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Switch } from "antd";
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import SidebarNav from "../sidebar";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import {APIURL} from '../../../matcher';
class VisibilityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      add:false,
      edit:false,
      check:false
    };
  }

  componentDidMount() 
  {
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('view'))
    {
      this.props.history.push('/admin/dashboard')
    }

    if(permission.includes('add'))
    {
      this.setState(
      {
        add: true
      });
    }

    if(permission.includes('edit'))
    {
      this.setState(
      {
        edit: true
      });
    }

    if(permission.includes('check'))
    {
      this.setState(
      {
        check: true
      });
    }

    axios.get(APIURL+'visibility-list')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            data: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            data: '',
            loading: false,
          });
        }
    })   
  }

  onChangeHandle = (event,index) =>
  {
    const status = event;
    const is_active = '';
    const region_id = index.target.id;
    if(status == true)
    {
      const is_active = 1;
      axios({
          method: 'post',
          url: APIURL+'update-visibility-status/'+region_id,
          data: qs.stringify({
            is_active: is_active,
            admin_id: localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    }
    else if(status == false)
    {
      const is_active = 0;
      axios({
          method: 'post',
          url: APIURL+'update-visibility-status/'+region_id,
          data: qs.stringify({
            is_active: is_active,
            admin_id: localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    } 
  }

  render() {
    const { data }  = this.state;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        sorter: (a,b) =>
        {
          if(a.length === b.length)
          {
            return a > b ? 1: -1
          }
          return a.length > b.length ? 1 : -1;
        }
      },
      {
        title: "Visibility Type",
        dataIndex: "visibility_type",
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Enabled/Disabled",
        dataIndex: "status",
        key: "status",
        render: (e,data) => (
          this.state.check ?
          <Switch
          id={data.id}
          onChange={this.onChangeHandle}
          defaultChecked={e}        
          />
          : null
        ),
      },
      {
        title: 'Actions',
        dataIndex: "id",
        render: (dataIndex) => (
            this.state.edit ?
              <div className="actions">
                <Link to={'/admin/edit-visibility/'+dataIndex} className="btn btn-sm bg-success-light"><i className="fe fe-pencil"></i> Edit</Link>
              </div>
            : null  
            ),
      },
    ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Visibility Management</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0)">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Visibility Management</li>
                  </ul>
                </div>
                { this.state.add ? 
                <div className="col-sm-5 col">
                  <a className="btn btn-primary float-right mt-2" href="/admin/add-visibility"><i className="fas fa-plus mr-1" /> Add Visibility </a>
                </div>
                : null }
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default VisibilityList;
