import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class EditRegion extends Component {

    constructor(props) 
    {
      super(props);
      this.state = {
        data: '',
        region : '',
        region_code: '',
        region_description: '',
        submit:true,
        wait:false
      };
    }
    componentDidMount() 
    {  
      let permission = localStorage.getItem('permissions');
      if(!permission.includes('edit_region'))
      {
        this.props.history.push('/admin/dashboard')
      }

      const url = this.props.location.pathname;
      const segment = url.substring(url.lastIndexOf('/') + 1);
      axios.get(APIURL+'edit-region/'+segment)
      .then((res) => 
      {
          if(res.data.statusCode == 200)
          {
            this.setState({
              data: res.data.details,
              region: res.data.details[0].region,
              region_code: res.data.details[0].region_code,
              region_description: res.data.details[0].region_description,
              loading: false,
            });
          }
          else
          {
            this.setState({
              data: '',
              loading: false,
            });
          }
      })
    }

    handleRegion = (event) =>  
    {
      const region = event.target.value;
      if(!region) 
      {
        this.setState({ region });
      } 
      else 
      {
        this.setState({ region });
      }
    }

    handleRegionCode = (event) =>  
    {
      const region_code = event.target.value;
      if(!region_code) 
      {
        this.setState({ region_code });
      } 
      else 
      {
        this.setState({ region_code });
      }
    }

    handleDesc = (event) =>  
    {
      const region_description = event.target.value;
      if(!region_description) 
      {
        this.setState({ region_description });
      } 
      else 
      {
        this.setState({ region_description });
      }
    }

    update = (event) => 
    {
      const url = this.props.location.pathname;
      const segment = url.substring(url.lastIndexOf('/') + 1);
      event.preventDefault();
        this.setState({
          submit: false,
          wait: true,
        });
      axios({
          method: 'post',
          url: APIURL+'check-edit-region-name/'+segment,
          data: qs.stringify({
            region: this.state.region
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        }).then((res) => 
        {
          if(res.data.total[0].total > 0)
          {
            alert('Region Name Should be Unique.');
            this.setState({
              submit: true,
              wait: false,
            });
          }
          else if(res.data.total[0].total == 0)
          {
            axios({
              method: 'post',
              url: APIURL+'check-edit-region-code/'+segment,
              data: qs.stringify({
                region_code: this.state.region_code
              }),
              headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
              }
            }).then((res) => 
            {
              if(res.data.total[0].total > 0)
              {
                alert('Region Code Should be Unique');
                this.setState({
                  submit: true,
                  wait: false,
                });
              }
              else if(res.data.total[0].total == 0)
              {
                  axios({
                    method: 'post',
                    url: APIURL+'update-region/'+segment,
                    data: qs.stringify({
                      region: this.state.region,
                      region_code: this.state.region_code,
                      region_description: this.state.region_description,
                      admin_id: localStorage.getItem('id')
                    }),
                    headers: {
                      'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                    }
                  })
                  .then((res) => 
                  {
                      if(res.data.statusCode == 200)
                      {
                        alert(res.data.message)
                        this.props.history.push('/admin/region-list')
                      }
                      else if(res.data.statusCode == 500)
                      {
                        this.setState({
                            errorMessage: res.data.message
                        });
                      }
                  })
                  .catch((error) => {
                      if (axios.isCancel(error) || error) {
                          this.setState({
                              loading: false,
                              message: 'Failed to fetch results.Please check network',
                          });
                      }
                  });
              }
            })       
          }
        })  
    }

   render() 
   {
    const { data } = this.state;
    if(data.length > 0)
    {
      return (        
        <>
        <SidebarNav />
          <div className="main-wrapper">
            {/* Page Wrapper */}
            <div className="page-wrapper">
              <div className="content container-fluid">
                {/* Page Header */}
                <div className="page-header">
                  <div className="row">
                    <div className="col-sm-7">
                      <h3 className="page-title">Edit Region</h3>
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="">Region List</a></li>
                        <li className="breadcrumb-item active">Edit Region</li>
                      </ul>
                    </div>
                    <div className="col-sm-5">
                      <Link to="/admin/region-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
                    </div>
                  </div>
                </div>
                {/* /Page Header */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body custom-edit-service">
                        
                        {/* Add User */}
                        <form onSubmit={this.update} method="post" encType="multipart/form-data" autoComplete="off">
                          <div className="service-fields mb-2">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Region Name<b>*</b></label>
                                  <input className="form-control" placeholder="Region Name" onChange={this.handleRegion} value={this.state.region} name="region" id="region" required />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label>Region Code<b>*</b></label>
                                  <input className="form-control" placeholder="Region Code" onChange={this.handleRegionCode} value={this.state.region_code} name="region_code" id="region_code" required />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="service-fields mb-2">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Region Description <b>*</b></label>
                                  <textarea className="form-control" rows="5" name="region_description" onChange={this.handleDesc} required>{this.state.region_description}</textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="submit-section">
                            {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Update</button> : null}
                            {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                          </div>
                        </form>
                        {/* /Add User */}
                      </div>
                    </div>
                  </div>      
                </div>
              </div>      
            </div>
            {/* /Page Wrapper */}
          </div>
        </>
      );
    }
    else if(data.length == 0)
    {
      return(
          <div></div>
        )
    }  
   }
}

export default EditRegion;
