import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Switch } from "antd";
import 'antd/dist/antd.css';
import axios from 'axios';
import { CSVLink } from 'react-csv'
import qs from 'qs';
import SidebarNav from "../sidebar";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import {APIURL} from '../../../matcher';
import {MAINURL} from '../../../matcher';
class RetailerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      name:'',
      mobile:'',
      is_approved:'',
      kre_id:'',
      add_retailer:false,
      edit_retailer:false,
      check_retailer:false,
      retailer_mapping:false,
      showResults:false,
      player:[],
      kreLists:[],
    };
  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() 
  {  

    let permission = localStorage.getItem('permissions');
    if(!permission.includes('view_retailer'))
    {
      this.props.history.push('/admin/dashboard')
    }

    if(permission.includes('add_retailer'))
    {
      this.setState(
      {
        add_retailer: true
      });
    }

    if(permission.includes('edit_retailer'))
    {
      this.setState(
      {
        edit_retailer: true
      });
    }

    if(permission.includes('check_retailer'))
    {
      this.setState(
      {
        check_retailer: true
      });
    }

    if(permission.includes('retailer_mapping'))
    {
      this.setState(
      {
        retailer_mapping: true
      });
    }

    // axios.get(APIURL+'retailer-list')
    // .then((res) => 
    // {
    //     if(res.data.list.length > 0)
    //     {
    //       console.log(res.data.list)
    //       this.setState({
    //         data: res.data.list,
    //         loading: false,
    //         showResults: false
    //       });
    //     }
    //     else
    //     {
    //       this.setState({
    //         data: '',
    //         loading: false,
    //       });
    //     }
    // }) 

    axios.get(APIURL+'search-user?region=&state=&district=&user_type=kre&user_name=&mobile=')
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            kreLists: res.data.list,
            loading: false
            
          });
        }
        else
        {
          this.setState({
            kreLists: '',
            'message':'No Record Found for selected Parameters',
            loading: false
          });
        }
      })
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    //console.log(this.state.is_approved)
    if(this.state.name == '' && this.state.mobile == '' && this.state.is_approved == '' && this.state.kre_id == '')
    {
      alert('Please Select the Parameters')
    }
    else
    {
      axios.get(APIURL+'search-retailer?name='+this.state.name+'&mobile='+this.state.mobile+'&is_approved='+this.state.is_approved+'&kre_id='+this.state.kre_id)
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            data: res.data.list,
            loading: false,
            showResults: true,
            player: res.data.list,
          });
        }
        else
        {
          alert('No Record Found for selected Parameters')
          this.setState({
            data: '',
            loading: false,
          });
        }
      })
    }
    
  }

  onChangeHandle = (event,index) =>
  {
    const status = event;
    const is_active = '';
    const retailer = index.target.id;
    if(status == true)
    {
      const is_active = 1;
      axios({
          method: 'post',
          url: APIURL+'update-retailer-status/'+retailer,
          data: qs.stringify({
            is_active: is_active,
            admin_id: localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    }
    else if(status == false)
    {
      const is_active = 0;
      axios({
          method: 'post',
          url: APIURL+'update-retailer-status/'+retailer,
          data: qs.stringify({
            is_active: is_active,
            admin_id: localStorage.getItem('id')
          }),
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
        .catch((error) => {
            if (axios.isCancel(error) || error) {
                this.setState({
                    loading: false,
                    message: 'Failed to fetch results.Please check network',
                });
            }
        });
    } 
  }

  render() {
    const { data,player,kreLists } = this.state;

    const players = player
      const headers = [
        {
          label:"Existing Retailer?",key:"existing_retailer"
        }, 
        {
          label:"Mapped By",key:"mapped_by"
        }, 
        {
          label:"Is Approved ?",key:"is_approved"
        },
        {
          label:"Region",key:"region"
        },
        {
          label:"State",key:"state_name"
        },
        {
          label: "District",key: "district_name"
        },
        {
          label: "Retailer Mobile No.",
          key: "retailer_mobile"
        },
        {
          label: "Retailer ship Name",
          key: "retailership_name"
        },
        {
          label: "Retailer Code",
          key: "retailer_customer_code"
        },
        {
          label: "Retailer Contact Person Name",
          key: "retailer_contact_person_name"
        },
        {
          label: "Birthday",
          key: "birthday"
        },
        {
          label: "Anniversary",
          key: "anniversary"
        },
        {
          label: "Address 1",
          key: "address_1"
        },
        {
          label: "Address 2",
          key: "address_2"
        },
        {
          label: "Pin Code",
          key: "pincode"
        },
        {
          label: "Visiting card",
          key: "visitingcard"
        },
        {
          label: "POI",
          key: "pointofidentity"
        },
        {
          label: "POA",
          key: "pointofaddress"
        },
        {
          label: "Image of Retailer Store ",
          key: "storeimage"
        },
        {
          label: "Tagged RM",
          key: "rsm_name"
        },
        {
          label: "KRE User",
          key: "kre_name"
        },
        {
          label: "Enabled/Disabled",
          key: "status"
        }
      ]
       
      const csvLink = {
        headers: headers,
        data: players,
        filename:"retailer-list.csv"
      }

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        sorter: (a,b) =>
        {
          if(a.length === b.length)
          {
            return a > b ? 1: -1
          }
          return a.length > b.length ? 1 : -1;
        }
      },
      {
        title:"Existing Retailer?",dataIndex:"existing_retailer"
      }, 
      {
        title: "Mapped By",
        dataIndex: "mapped_by",
      },
      {
        title: "Is Approved ?",
        dataIndex: "is_approved",
      },
      {
        title: "Region",
        dataIndex: "region",
      },
      {
        title: "State",
        dataIndex: "state_name",
      },
      {
        title: "District",
        dataIndex: "district_name",
      },
      {
        title: "Retailer Mobile No.",
        dataIndex: "retailer_mobile",
      },
      {
        title: "Retailer ship Name",
        dataIndex: "retailership_name",
      },
      {
        title: "Retailer Code",
        dataIndex: "retailer_customer_code",
      },
      {
        title: "Retailer Contact Person Name",
        dataIndex: "retailer_contact_person_name",
      },
      {
        title: "Birthday",
        dataIndex: "birthday",
      },
      {
        title: "Anniversary",
        dataIndex: "anniversary",
      },
      {
        title: "Address 1",
        dataIndex: "address_1",
      },
      {
        title: "Address 2",
        dataIndex: "address_2",
      },
      {
        title: "Pin Code",
        dataIndex: "pincode",
      },
      {
        title: "Visiting card",
        dataIndex: "visiting_card",
        render: (dataIndex) => (
            <div className="actions">
             {dataIndex == null ?
              null :
              dataIndex == '' ? null :
              <a href={MAINURL+dataIndex} className="btn-sm bg-info-light" target="_blank">Click to View</a>
              }
            </div> 
          ),
      },
      {
        title: "POI",
        dataIndex: "point_of_identity",
        render: (dataIndex) => (
            <div className="actions">
              {dataIndex == null ?
              null :
                dataIndex == '' ? null :
                <a href={MAINURL+dataIndex} className="btn-sm bg-success-light" target="_blank">Click to View</a>
              }
            </div>
            ),
      },
      {
        title: "POA",
        dataIndex: "point_of_address",
        render: (dataIndex) => (
          <div className="actions">
            {dataIndex == null ?
              null :
              dataIndex == '' ? null :
              <a href={MAINURL+dataIndex} className="btn-sm bg-warning-light" target="_blank">Click to View</a>
            }
          </div>
        ),
      },
      {
        title: "Image of Retailer Store ",
        dataIndex: "store_image",
        render: (dataIndex) => (
            <div className="actions">
            {dataIndex == null ?
              null :
              dataIndex == '' ? null :
              <a href={MAINURL+dataIndex} className="btn-sm bg-primary-light" target="_blank">Click to View</a>
            }
            </div>
            ),
      },
      {
        title: "Tagged RM",
        dataIndex: "rsm_name",
        sorter: (a, b) => a.rsm_name.length - b.rsm_name.length,
      },
      {
        title: "KRE User",
        dataIndex: "kre_name",
        sorter: (a, b) => a.kre_name.length - b.kre_name.length,
      },
      {
        title: "Enabled/Disabled",
        dataIndex: "status",
        key: "status",
        render: (e,data) => (
          this.state.check_retailer ? 
          <Switch
          id={data.id}
          onChange={this.onChangeHandle}
          defaultChecked={e}        
          />
          : null
        ),
      },
      {
        title: 'Actions',
        dataIndex:"id",
        render: (dataIndex) => (
            <div className="actions">
              { this.state.edit_retailer ?
              <Link to={'/admin/edit-retailer/'+dataIndex} className="btn btn-sm bg-success-light"><i className="fe fe-pencil"></i> Edit</Link>
              : null }
              { this.state.retailer_mapping ?
              <Link to={'/admin/map-retailer/'+dataIndex} className="btn btn-sm bg-warning-light"><i className="fe fe-map"></i> Mapping</Link>
              : null }
            </div>
            ),
      },
    ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Retailer Management</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/admin/dashboard">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Retailer Management</li>
                  </ul>
                </div>
                { this.state.add_retailer ?
                <div className="col-sm-5 col">
                  <a className="btn btn-primary float-right mt-2" href="/admin/add-retailer"><i className="fas fa-user-plus mr-1" /> Add Retailer</a>
                </div>
                : null }
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <form onSubmit={this.onSubmit} method="get" encType="multipart/form-data" autoComplete="off"> 
                  <div className="row">
                    <div className="col-md-2">
                      <input className="form-control" type="text" name="name" id="name" onChange={this.handleInputChange} value={this.state.name} placeholder="Retailership Name"/>
                    </div>
                    <div className="col-md-2">
                      <input className="form-control" type="text" name="mobile" minlength="10" maxlength="10" id="mobile" placeholder="Mobile Number" onChange={this.handleInputChange} value={this.state.mobile}/>
                    </div>
                    <div className="col-md-3">
                      <select className="form-control" name="is_approved" onChange={this.handleInputChange} value={this.state.is_approved}>
                        <option value="">Is Approved ?</option> 
                        <option value="0">Pending</option>
                        <option value="1">Approved</option>
                        <option value="2">Rejected</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <select className="form-control" name="kre_id" onChange={this.handleInputChange} value={this.state.kre_id}>
                        <option value="">Select KRE Name</option> 
                        {
                          kreLists.map((lis) =>
                          {
                            return(
                                <option value={lis.id}>{lis.full_name}</option>
                              )
                          })
                        }
                      </select>
                    </div>
                    <div className="col-md-2">
                      <button className="btn btn-primary" type="submit"><i className="fa fa-search" />&nbsp;Search</button>
                    </div>
                  </div>
                </form>
                <br/>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                    { this.state.showResults ?
                      <CSVLink {...csvLink} className="btn btn-primary search-btn float-right" onClick={this.handleChange}><i className="fa fa-download"></i>&nbsp;Export</CSVLink> 
                    : null }
                      </div>
                  </div>
                  { this.state.showResults ? <br/> :null }
                { this.state.showResults ? 
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          className="table-striped"
                          columns={columns}
                          // bordered
                          dataSource={data}
                          rowKey={(record) => record.id}
                          showSizeChanger={true}
                          pagination={{
                            total: data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          scroll={{
                            x: 2000,
                            }}
                        />
                      </div>
                    </div>
                  </div>
                  : null 
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RetailerList;
