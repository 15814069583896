import React, { Component } from 'react';
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import {APIURL} from '../../../matcher';
import {MAINURL} from '../../../matcher';
import axios from 'axios';

class BulkUploadRetailer extends Component {
  constructor(props) {
        super(props);
        this.state = {
          file: '',
          heading: true,
          upload: false,
          filename:'',
          wait:false,
          submit:false
        }
        this.onFileChange = this.onFileChange.bind(this);
    }

  componentDidMount() 
  {  

    let permission = localStorage.getItem('permissions');
    if(!permission.includes('bulk_enrol'))
    {
      this.props.history.push('/admin/dashboard')
    }
  }
    onFileChange(event)
    {
      const fileType = event.target.files[0].type;
      if(fileType == 'text/csv')
      {
        this.setState({ 
          file: event.target.files[0],
          upload: true,
          heading: false,
          filename: event.target.files[0].name,
          submit: true
        })
      }
      else
      {
        alert('Only CSV file are accept');
        event.target.value = null;
        this.setState({ 
          heading: true,
          upload: false,
          filename: '',
          wait: false,
          submit: false
        })
      }
    }
    

    onSubmit = async(event) => 
    {
        event.preventDefault();
        this.setState({
          submit: false,
          wait: true,
        });
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('admin_id', localStorage.getItem('id'));
        axios.post(APIURL+'retailer-bulk-upload', formData)
        .then((res) => 
        {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
              this.props.history.push('/admin/retailer-management')
            }
            else if(res.data.statusCode == 500)
            {
              alert(res.data.message)
              this.setState({
                  errorMessage: res.data.message
              });
            }
        })
    }

   render() {
      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
          {/* Page Wrapper */}
            <div className="page-wrapper">
              <div className="content container-fluid">
                {/* Page Header */}
                <div className="page-header">
                  <div className="row">
                    <div className="col-sm-7">
                      <h3 className="page-title">Bulk Upload</h3>
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/admin/retailer-management">Retailer Management</a></li>
                        <li className="breadcrumb-item active">Bulk Upload</li>
                      </ul>
                    </div>
                    <div className="col-sm-5">
                      <a className="btn btn-primary float-right mt-2" href={MAINURL+'/bulk-upload-retailer.csv'} download><i className="fas fa-download mr-1" /> Sample </a>
                    </div>
                  </div>
                </div>
                {/* /Page Header */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body custom-edit-service">
                        {/* Add User */}
                        <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                          <div className="service-fields mb-3">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="service-upload">
                                  {this.state.heading ? <i className="fas fa-cloud-upload-alt" /> : null}
                                  {this.state.heading ? <span>Upload Bulk Data *</span> : null}
                                  {this.state.upload ? <i className="fas fa-file" /> : null}
                                  {this.state.upload ? <span>{this.state.filename}</span> : null}
                                  <input className="form-control"  type="file" required onChange={this.onFileChange}/>
                                </div>  
                              </div>
                            </div>
                          </div>
                          <div className="submit-section">
                            {this.state.submit ? <button  className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Upload</button> : null}
                            {this.state.wait ? <button  className="btn btn-primary submit-btn" type="button">Please Wait...</button> : null}
                          </div>
                        </form>
                        {/* /Add User */}
                      </div>
                    </div>
                  </div>      
                </div>
              </div>      
            </div>
          {/* /Page Wrapper */}
        </div>
        </>
      );
   }
}

export default BulkUploadRetailer;
