import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Switch } from "antd";
import 'antd/dist/antd.css';
import SidebarNav from "../sidebar";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import axios from 'axios';
import qs from 'qs';
import {APIURL} from '../../../matcher';
class RetailerMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ''
    };
  }

  componentDidMount() 
  {  
    axios.get(APIURL+'get-retailer-mapping')
    .then((res) => 
    {
        if(res.data.list.length > 0)
        {
          this.setState({
            data: res.data.list,
            loading: false,
          });
        }
        else
        {
          this.setState({
            data: '',
            loading: false,
          });
        }
    }) 
  }
  render() {
    const { data } = this.state;

    const columns = [
    
      {
        title: "RM Name",
        dataIndex: "rsm_name",
      },
      {
        title: "KRE Name",
        dataIndex: "kre_name",
      },
      {
        title: "Retailership Name",
        dataIndex: "retailership_name",
      },
      {
        title: "Retailer Contact Person Name",
        dataIndex: "retailer_contact_person_name",
      },
      {
        title: "Retailer Code",
        dataIndex: "retailer_customer_code",
      },
      {
        title: "Mapped By",
        dataIndex: "mapped_by",
      },
      // {
      //   title: 'Actions',
      //   render: (text, record) => (
      //         <div className="actions">
      //           <Link to="#" className="btn btn-sm bg-success-light"><i className="fe fe-pencil"></i> Edit</Link>
      //         </div>
      //       ),
      // },
    ];

    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Retailer Mapping</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0)">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Retailer Mapping</li>
                  </ul>
                </div>
                <div className="col-sm-5 col">
                  <a className="btn btn-primary float-right mt-2" href="/admin/assign-retailer"><i className="fa fa-map-o mr-1" />&nbsp;&nbsp;Mapping </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/*<div className="row">
                  <div className="col-md-3">
                    <input className="form-control" type="text" name="district" id="district" placeholder="District Name"/>
                  </div>
                  <div className="col-md-3">
                    <input className="form-control" type="text" name="name" id="name" placeholder="RSM Name"/>
                  </div>
                  <div className="col-md-3">
                    <input className="form-control" type="text" name="kre_name" id="kre_name" placeholder="KRE Name"/>
                  </div>
                  <div className="col-md-3">
                    <button className="btn btn-primary" type="submit"><i className="fa fa-search" />&nbsp;Search</button>
                  </div>
                </div>
                <br/>*/}
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RetailerMapping;
