import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Scrollbars } from 'jquery-slimscroll';
import logo from "../../../assets/images/logo.png";
import logoicon from "../../../assets/images/logo-small.png";
import avatar from "../../../assets/images/avatar-01.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import $ from "jquery";
import IMG01 from "../../../assets/images/doctors/doctor-thumb-01.jpg";
import IMG02 from "../../../assets/images/doctors/doctor-thumb-02.jpg";
import IMG03 from "../../../assets/images/doctors/doctor-thumb-03.jpg";


class ReportHeader extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  removeToken = (event) => 
  {
      localStorage.removeItem("token");
      window.location.href = "/admin/report/login";
  };
  componentDidMount(){

    var Sidemenu = function() {
      this.$menuItem = $('#sidebar-menu a');
    };

    $('#toggle_btn').on('click', function (e) {
      if($('body').hasClass('mini-sidebar')) {
        $('body').removeClass('mini-sidebar');
        $('.subdrop + ul').slideDown();
      } else {
        $('body').addClass('mini-sidebar');
        $('.subdrop + ul').slideUp();
      }
      return false;
    });

    if(this.props.location.pathname.split("/")[1] === 'admin') {
      // require('../../assets/plugins/bootstrap-rtl/css/bootstrap.min.css')
      require('../../../assets/css/feathericon.min.css')
      require('../../../assets/js/app.js');
      require('../../../assets/plugins/fontawesome/css/fontawesome.min.css')
      require('../../../assets/plugins/fontawesome/css/all.min.css')
      require('../../../assets/css/font-awesome.min.css')
      require('../../../assets/css/style.css')
    }
    
  }

  render() {
    const exclusionArray = [
      "/admin/report/login",
      "/admin/404",
      "/admin/500",
    ];
    if (exclusionArray.indexOf(this.props.location.pathname) >= 0) 
    {
      return "";
    }
    return (
        <div className="header">
          {/* Logo */}
          <div className="header-left">
            <a href="javascript:void(0)" className="logo">
              <img src={logo} alt="Logo" />
            </a>
            {/*<a href="/admin" className="logo logo-small">
              <img src={logoicon} alt="Logo" width="30" height="30" />
            </a>*/}
          </div>
          {/* /Logo */}
          <a  id="toggle_btn" onClick={() => this.handlesidebar()}>
            <i className="fe fe-text-align-left" />
          </a>
          <div className="top-nav-search">
            {/*<form>
              <input type="text" className="form-control" placeholder="Search here" />
              <button className="btn" type="submit"><i className="fa fa-search" /></button>
            </form>*/}
          </div>
          {/* Mobile Menu Toggle */}
          <a href="#0" className="mobile_btn" id="mobile_btn">
            <i className="fa fa-bars" />
          </a>
          {/* /Mobile Menu Toggle */}

          <ul className="nav user-menu">
            <li className="nav-item dropdown has-arrow">
              <Dropdown className="user-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span className="user-img">
                    <img
                      className="rounded-circle"
                      src={avatar}
                      width="31"
                      alt="Ryan Taylor"
                    />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1" className="no-padding">
                    <div className="user-header">
                      <div className="avatar avatar-sm">
                        <img
                          src={avatar}
                          alt="User"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                      <div className="user-text">
                        <h6>{localStorage.getItem('full_name')}</h6>
                        <p className="text-muted mb-0">{localStorage.getItem('user_type')}</p>
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item href="/admin/report/my-profile"><i className="fa fa-user" />&nbsp; My Profile</Dropdown.Item>
                  <Dropdown.Item href="/admin/report/change-password"><i className="fa fa-key" />&nbsp; Change Password</Dropdown.Item>
                  <Dropdown.Item href="#" onClick={this.removeToken}><i className="fa fa-power-off" />&nbsp;&nbsp;Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> 
          </ul>
        </div>
      );
  }
}

export default ReportHeader;
