import React, { Component } from 'react';
import SidebarNav from './sidebar/index';
import { CSVLink } from 'react-csv'
import { Link } from "react-router-dom";
import { Tabs, Tab } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class CommunicationReport extends Component{
    constructor(props) 
  {
    super(props)
    this.state = {
      startDate: '',
      endDate: '',
      lists:[],
      name:'',
      mobile:'',
      player:[],
      showResults:false
    };
  }

    componentDidMount() 
    {  
        let permission = localStorage.getItem('permissions');
        if(!permission.includes('communication_report'))
        {
            this.props.history.push('/admin/report/my-profile')
        }
    }

    handleInputChange = (event) => 
      {
        const { value, name } = event.target;
        this.setState({
          [name]: value,
          showResults: false
        });
      }

    onSubmit = (event) => 
    {
        event.preventDefault();
        if(this.state.startDate == '')
        {
          var  startDate = ''
        }
        else
        {
          var  startDate = moment(this.state.startDate).format('YYYY-MM-DD')
        }

        if(this.state.endDate == '')
        {
          var  endDate = ''
        }
        else
        {
          var  endDate = moment(this.state.endDate).format('YYYY-MM-DD')
        }

        if(this.state.mobile == '' && this.state.name == '' && startDate == '' && endDate == '')
        {
          alert('Please Select the Parameters')
        }
        else
        {
          axios.get(APIURL+'communication-report?mobile='+this.state.mobile+'&name='+this.state.name+'&startDate='+startDate+'&endDate='+endDate)
          .then((res) => 
          {
            if(res.data.list.length > 0)
            {
              this.setState({
                player: res.data.list,
                showResults: true,
              });
            }
            else
            {
              alert('No Record Found for selected Parameters')
              this.setState({
                showResults: false,
              });
            }
          })
        }

    }

  handleChange = (event) => 
  {
    this.setState({
      showResults: false,
    });
  }  
    render()
    {
        const { lists,states,districts,player,startDate}  = this.state;
      const players = player
      const headers = [
        {
          label:"Name",key:"name"
        },
        {
          label:"Mobile",key:"mobile"
        },
        {
          label:"Member Type",key:"member_type"
        },
        {
          label:"Sent Date & Time",key:"created_at"
        },
        {
          label:"MessageType",key:"message_type"
        },
        {
          label:"ACKID",key:"ackid"
        },
        {
          label:"SMS Content",key:"sms_content"
        }
        
      ]
       
      const csvLink = {
        headers: headers,
        data: players,
        filename:"communication-report.csv"
      }
        return(
            <div>
                <SidebarNav />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row">
                                <div className="col">
                                    <h3 className="page-title">Communication Report</h3>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Report</a></li>
                                        <li className="breadcrumb-item active">Communication Report</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                       {/* page header */}
                      	<div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                                            <div className="service-fields mb-2">
                                              <div className="row">
                                                <div className="col-lg-2">
                                                  <div className="form-group">
                                                    <label>From Date </label>
                                                    <input type="date" className="form-control" onChange={e => this.setState({ startDate: e.target.value })}/>
                                                  </div>
                                                </div>
                                                {startDate != '' && (
                                                  <React.Fragment>
                                                    <div className="col-lg-2">
                                                      <div className="form-group">
                                                        <label>To Date </label>
                                                        <input type="date" className="form-control" min={startDate} onChange={e => this.setState({ endDate: e.target.value })}/>
                                                      </div>
                                                    </div>
                                                 </React.Fragment>
                                                )}
                                                <div className="col-lg-3">
                                                  <div className="form-group">
                                                    <label>Full Name </label>
                                                    <input type="text" className="form-control" placeholder="Full Name" name="name" value={this.state.name} onChange={this.handleInputChange}/>
                                                  </div>
                                                </div>
                                                <div className="col-lg-3">
                                                  <div className="form-group">
                                                    <label>Mobile No. </label>
                                                    <input type="text" className="form-control" placeholder="Mobile No." minLength={10} maxLength={10} name="mobile" value={this.state.mobile} onChange={this.handleInputChange}/>
                                                  </div>
                                                </div>
                                                <div className="col-lg-2">
                                                  <div className="form-group">
                                                    { this.state.showResults ? null : <button className="btn btn-primary search-btnn" type="submit"><i className="fa fa-search"></i></button> }
                                                    { this.state.showResults ? <CSVLink {...csvLink} className="btn btn-primary search-btnn" onClick={this.handleChange}><i className="fa fa-download"></i>&nbsp;Export</CSVLink> : null }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="service-fields mb-2">
                                                <div className="row">
                                                    
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                 </div>
            </div>
        );
    }
}

export default CommunicationReport;