import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
//export const 
class AssignRetailer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
      states:[],
      districts:[],
      input: {},
      errors: {},
      region_id: '',
      state_id: '',
      district_id: '',
      rsm_list:[],
      rsm_id:'',
      kre_id:'',
      kre_name:'',
      retailer:[],
      lists:[],
      data:'',
      kre_list:[],
      colourOptions : []
    };

  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value
    });
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    this.btn.setAttribute("disabled", "disabled");
    axios({
            method: 'post',
            url: APIURL+'retailer-mapping/'+segment,
            data: qs.stringify({
              rsm_id: this.state.rsm_id,
              kre_id: this.state.kre_id,
              admin_id: localStorage.getItem('id')
              /*retailer: this.state.optionSelected*/
            }),
            headers: {
              'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
          })
          .then((res) => 
          {
            if(res.data.statusCode == 200)
            {
              alert(res.data.message)
              this.props.history.push('/admin/retailer-mapping')
            }
            else if(res.data.statusCode == 500)
            {
              this.setState({
                  errorMessage: res.data.message
              });
            }
          })
          .catch((error) => {
              if (axios.isCancel(error) || error) {
                  this.setState({
                      loading: false,
                      message: 'Failed to fetch results.Please check network',
                  });
              }
          });        
  }

  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('retailer_mapping'))
    {
      this.props.history.push('/admin/dashboard')
    }
    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    axios.get(APIURL+'edit-retailer/'+segment)
    .then((res) => 
    {
      if(res.data.statusCode == 200)
      {
        this.setState({
          data: res.data.details[0],
          rsm_id: res.data.details[0].rsm_id,
          kre_id: res.data.details[0].kre_id,
          kre_name: res.data.details[0].kre_name,
          district_id: res.data.details[0].district_id,
          state_id: res.data.details[0].state_id,
          loading: false,
        });
        console.log(this.state.rsm_id)
        axios.get(APIURL+'get-tagged-rsm-kre/'+this.state.rsm_id)
        .then((res) => 
        {
            if(res.data.details.length > 0)
            {
              this.setState({
                kre_list: res.data.details,
                loading: false,
              });
            }
            else
            {
              this.setState({
                kre_list: [],
                loading: false,
              });
            }
        })

        axios.get(APIURL+'get-district-wise-rsm/'+this.state.state_id)
        .then((res) => 
        {
            if(res.data.details.length > 0)
            {
              this.setState({
                rsm_list: res.data.details,
                loading: false,
              });
            }
            else
            {
              this.setState({
                rsm_list: [],
                loading: false,
              });
            }
        })
      }
      else
      {
        this.setState({
          data: '',
          loading: false,
        });
      }
    })

  }


  handleRSM = (event) =>  
  {
    const rsm_id = event.target.value;
    //console.log(rsm_id)
    if(!rsm_id) 
    {
      this.setState({ rsm_id });
    } 
    else 
    {
      this.setState({ rsm_id });
    }

    axios.get(APIURL+'get-tagged-rsm-kre/'+rsm_id)
    .then((res) => 
    {

        if(res.data.details.length > 0)
        {
          this.setState({
            kre_list: res.data.details,
            loading: false,
          });
        }
        else
        {
          this.setState({
            kre_list: [],
            loading: false,
          });
        }
        //console.log(this.state.kre_list)
    })
  }

  handleChange = (selected) => 
  {
    this.setState({
      optionSelected: selected
    });
  };
   render() 
   {
      const { lists,data,states, districts,rsm_list,kre_list }  = this.state;

      return (        
        <>
        <SidebarNav />
        <div className="main-wrapper">
        {/* Page Wrapper */}
        <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-7">
                <h3 className="page-title">Retailer Mapping</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/retailer-management">Retailer Management</a></li>
                  <li className="breadcrumb-item active">Retailer Mapping</li>
                </ul>
              </div>
              <div className="col-sm-5">
                  <Link to="/admin/retailer-management" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
                </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body custom-edit-service">
                  {/* Add User */}
                  <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                    <div className="service-fields mb-2">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Select RM <b>*</b></label>
                            <select value={this.state.rsm_id} className="form-control" name="rsm_id" value={this.state.rsm_id}  onChange={this.handleRSM} required>   
                              <option value="">Select RM</option>
                              {
                                rsm_list.map((lis) =>
                                {
                                  return(
                                      <option value={lis.id}>{lis.full_name}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Tagged KRE <b>*</b></label>
                            <select value={this.state.kre_id} className="form-control" name="kre_id"  onChange={this.handleInputChange} required>   
                            <option value="">Select KRE</option>
                              {
                                kre_list.map((lis) =>
                                {
                                  return(
                                      <option value={lis.id}>{lis.full_name}</option>
                                    )
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button ref={btn => { this.btn = btn; }} className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Submit</button>
                    </div>
                  </form>
                  {/* /Add User */}
                </div>
              </div>
            </div>      
          </div>
        </div>      
      </div>
      {/* /Page Wrapper */}
        </div>
        </>
      );
   }
}

export default AssignRetailer;
