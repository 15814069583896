
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from '../sidebar';
import { Modal } from 'react-bootstrap';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class EditBrand extends Component {

    constructor(props) 
    {
      super(props);
      this.state = {
        data: '',
        brand_name : ''
      };
    }

    componentDidMount() 
    {  
      let permission = localStorage.getItem('permissions');
      if(!permission.includes('edit_brand'))
      {
        this.props.history.push('/admin/dashboard')
      }

      const url = this.props.location.pathname;
      const segment = url.substring(url.lastIndexOf('/') + 1);
      axios.get(APIURL+'edit-brand/'+segment)
      .then((res) => 
      {
        if(res.data.statusCode == 200)
        {
          this.setState({
            data: res.data.details,
            brand_name: res.data.details[0].brand_name,
            loading: false,
          });
        }
        else
        {
          this.setState({
            data: '',
            loading: false,
          });
        }
      })
      
    }

    handleBrand = (event) =>  
    {
      const brand_name = event.target.value;
      if(!brand_name) 
      {
        this.setState({ brand_name });
      } 
      else 
      {
        this.setState({ brand_name });
      }
    }


  update = (event) => 
  {
    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    event.preventDefault();
    this.btn.setAttribute("disabled", "disabled");
    axios({
        method: 'post',
        url: APIURL+'check-edit-brand-name/'+segment,
        data: qs.stringify({
          brand_name: this.state.brand_name
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      }).then((res) => 
      {
        if(res.data.total[0].total > 0)
        {
          alert('Brand Name Should be Unique');
          this.btn.removeAttribute("disabled");
        }
        else if(res.data.total[0].total == 0)
        {
          axios({
            method: 'post',
            url: APIURL+'update-brand/'+segment,
            data: qs.stringify({
              brand_name: this.state.brand_name,
              admin_id: localStorage.getItem('id')
            }),
            headers: {
              'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
            }
          })
          .then((res) => 
          {
              if(res.data.statusCode == 200)
              {
                alert(res.data.message)
                this.props.history.push('/admin/brand-list')
              }
              else if(res.data.statusCode == 500)
              {
                this.setState({
                    errorMessage: res.data.message
                });
              }
          })
          .catch((error) => {
              if (axios.isCancel(error) || error) {
                  this.setState({
                      loading: false,
                      message: 'Failed to fetch results.Please check network',
                  });
              }
          });       
        }
      })  
  }

   render() 
   {
    const { data } = this.state;
    if(data.length > 0)
    {
      return (        
        <>
        <SidebarNav />
          <div className="main-wrapper">
            {/* Page Wrapper */}
            <div className="page-wrapper">
              <div className="content container-fluid">
                {/* Page Header */}
                <div className="page-header">
                  <div className="row">
                    <div className="col-sm-7">
                      <h3 className="page-title">Edit Brand</h3>
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="">Brand Master</a></li>
                        <li className="breadcrumb-item active">Edit Brand</li>
                      </ul>
                    </div>
                    <div className="col-sm-5">
                      <Link to="/admin/brand-list" className="btn btn-primary" style={{marginLeft: "92%"}}><i className="fa fa-arrow-left"></i></Link>
                    </div>
                  </div>
                </div>
                {/* /Page Header */}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body custom-edit-service">
                        
                        {/* Add User */}
                        <form onSubmit={this.update} method="post" encType="multipart/form-data" autoComplete="off">
                          <div className="service-fields mb-2">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>Brand Name<b>*</b></label>
                                  <input className="form-control" placeholder="Brand Name" onChange={this.handleBrand} value={this.state.brand_name} name="region" id="region" required />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="submit-section">
                            <button ref={btn => { this.btn = btn; }} className="btn btn-primary submit-btn" type="submit" name="form_submit" value="submit">Update</button>
                          </div>
                        </form>
                        {/* /Add User */}
                      </div>
                    </div>
                  </div>      
                </div>
              </div>      
            </div>
            {/* /Page Wrapper */}
          </div>
        </>
      );
    }
    else if(data.length == 0)
    {
      return(
          <div></div>
        )
    }  
   }
}

export default EditBrand;
