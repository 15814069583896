import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SidebarNav from './sidebar/index';
import { Modal } from 'react-bootstrap';
import { DatePicker } from "antd";
import { CSVLink } from 'react-csv'
import moment from 'moment';
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';
class RetailerReport extends Component{
  constructor(props) 
  {
    super(props)
    this.state = {
      startDate: '',
      endDate: '',
      name:'',
      mobile:'',
      is_approved:'',
      existing_retailer:'',
      player:[],
      showResults:false
    };
  }

  handleChange = (event) => 
  {
    this.setState({
      showResults: false,
    });
  }

  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(!permission.includes('retailer_enrolment_report'))
    {
      this.props.history.push('/admin/report/my-profile')
    }
  }

  handleInputChange = (event) => 
  {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
      showResults: false
    });
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    if(this.state.startDate == '')
    {
      var  startDate = ''
    }
    else
    {
      var  startDate = moment(this.state.startDate).format('YYYY-MM-DD')
    }
    
    if(this.state.endDate == '')
    {
      var  endDate = ''
    }
    else
    {
      var  endDate = moment(this.state.endDate).format('YYYY-MM-DD')
    }

    if(this.state.mobile == '' && this.state.name == '' && startDate == '' && endDate == '' && this.state.is_approved == '' && this.state.existing_retailer == '')
    {
      alert('Please Select the Parameters')
    }
    else
    {
      axios.get(APIURL+'retailer-report?&mobile='+this.state.mobile+'&name='+this.state.name+'&startDate='+startDate+'&endDate='+endDate+'&is_approved='+this.state.is_approved+'&existing_retailer='+this.state.existing_retailer)
      .then((res) => 
      {
        if(res.data.list.length > 0)
        {
          this.setState({
            player: res.data.list,
            showResults: true,
          });
        }
        else
        {
          alert('No Record Found for selected Parameters')
          this.setState({
            showResults: false,
          });
        }
      })
    }  
  }

    render()
    {
      const { player,startDate}  = this.state;
      const players = player
      const headers = [
        {
          label:"Region",key:"region"
        },
        {
          label:"State",key:"state_name"
        },
        {
          label:"District",key:"district_name"
        },
        {
          label:"Retailer Customer Code",key:"retailer_customer_code"
        },
        {
          label:"Retailer ID",key:"retailer_id"
        },
        {
          label:"Retailership Name",key:"retailership_name"
        },
        {
          label:"Retailer Mobile No",key:"retailer_mobile"
        },
        {
          label:"Retailer Contact Person Name",key:"retailer_contact_person_name"
        },
        {
          label:"Retailer Email ID",key:"district_name"
        },
        {
          label:"Birthday",key:"birthday"
        },
        {
          label:"Anniversary",key:"anniversary"
        },
        {
          label:"Address 1",key:"address_1"
        },
        {
          label:"Address 2",key:"address_2"
        },
        {
          label:"Pin Code",key:"pincode"
        },
        {
          label:"RM Employee Code",key:"tagged_rsm_code"
        },
        {
          label:"RM Full Name",key:"tagged_rsm_name"
        },
        {
          label:"Tagged KRE Code",key:"tagged_kre_code"
        },
        {
          label:"Tagged KRE Name",key:"tagged_kre_name"
        },
        {
          label:"Enrolment Date",key:"created_at"
        },
        {
          label:"Latitude",key:"latitude"
        },
        {
          label:"Longitude",key:"longitude"
        },
        {
          label:"Status",key:"is_active"
        },
        {
          label:"Is Approved ?",key:"is_approved"
        },
        {
          label:"Existing Retailer ?",key:"existing_retailer"
        }

      ]
       
      const csvLink = {
        headers: headers,
        data: players,
        filename:"retailer-enrolment-report.csv"
      }
        return(
          <div>
            <SidebarNav />
            <div className="page-wrapper">
              <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col">
                            <h3 className="page-title">Retailer Enrolment Report</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Report</a></li>
                                <li className="breadcrumb-item active">Retailer Enrolment Report</li>
                            </ul>
                        </div>
                    </div>
                </div>
                 {/* page header */}
                <div className="row">
                  <div className="col-lg-12">
                      <div className="card">
                          <div className="card-body">
                              <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" autoComplete="off">
                                  <div className="service-fields mb-2">
                                    <div className="row">
                                      <div className="col-lg-2">
                                        <div className="form-group">
                                          <label>From Date </label>
                                          <input type="date" className="form-control" onChange={e => this.setState({ startDate: e.target.value })}/>
                                        </div>
                                      </div>
                                      {startDate != '' && (
                                        <React.Fragment>
                                          <div className="col-lg-2">
                                            <div className="form-group">
                                              <label>To Date </label>
                                              <input type="date" className="form-control" min={startDate} onChange={e => this.setState({ endDate: e.target.value })}/>
                                            </div>
                                          </div>
                                       </React.Fragment>
                                      )}
                                      <div className="col-lg-2">
                                        <div className="form-group">
                                          <label>Full Name </label>
                                          <input type="text"  className="form-control" placeholder="Full Name" name="name" value={this.state.name} onChange={this.handleInputChange}/>
                                        </div>
                                      </div>
                                      <div className="col-lg-2">
                                        <div className="form-group">
                                          <label>Mobile No. </label>
                                          <input type="text" minlength="10" maxlength="10" className="form-control" placeholder="Mobile No." name="mobile" value={this.state.mobile} onChange={this.handleInputChange}/>
                                        </div>
                                      </div>
                                      <div className="col-lg-2">
                                        <div className="form-group">
                                          <label>Is Approved ?</label>
                                          <select className="form-control" name="is_approved" onChange={this.handleInputChange} value={this.state.is_approved}>
                                            <option value="">Is Approved</option> 
                                            <option value="0">Pending</option>
                                            <option value="1">Approved</option>
                                            <option value="2">Rejected</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-2">
                                        <div className="form-group">
                                          <label>Existing Retailer ?</label>
                                          <select className="form-control" name="existing_retailer" onChange={this.handleInputChange} value={this.state.existing_retailer}>
                                            <option value="">Existing Retailer</option> 
                                            <option value="1">YES</option>
                                            <option value="2">NO</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-2">
                                        <div className="form-group">
                                          { this.state.showResults ? null : <button className="btn btn-primary search-btnn" type="submit" ref={btn => { this.btn = btn; }}><i className="fa fa-search"></i></button> }
                                          { this.state.showResults ? <CSVLink {...csvLink} className="btn btn-primary search-btnn"  onClick={this.handleChange}><i className="fa fa-download"></i>&nbsp;Export</CSVLink> : null }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default RetailerReport;