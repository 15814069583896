import React, { Component } from "react";
import SidebarNav from "../sidebar";
import qs from 'qs';
import axios from 'axios';
import {APIURL} from '../../../matcher';

class AdminPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions:'',
      view_region:false,
      add_region:false,
      edit_region:false,
      check_region:false,
      view_state:false,
      add_state:false,
      edit_state:false,
      check_state:false,
      view_district:false,
      add_district:false,
      edit_district:false,
      check_district:false,
      view_category:false,
      add_category:false,
      edit_category:false,
      check_category:false,
      view_sub:false,
      add_sub:false,
      edit_sub:false,
      check_sub:false,
      view_brand:false,
      add_brand:false,
      edit_brand:false,
      check_brand:false,
      view_product:false,
      add_product:false,
      edit_product:false,
      check_product:false,
      view_user:false,
      add_user:false,
      edit_user:false,
      check_user:false,
      view_retailer:false,
      add_retailer:false,
      edit_retailer:false,
      check_retailer:false,
      retailer_mapping:false,
      bulk_enrol:false,
      bulk_mapping:false,
      kre_tagged:false,
      view_visible:false,
      add_visible:false,
      edit_visible:false,
      check_visible:false,
      submit:true,
      wait:false
    };
  }

  handleViewRegion = (event) =>
  {
    this.setState({
      view_region:event.target.checked
    });
  }

  handleAddRegion = (event) =>
  {
    this.setState({
      add_region:event.target.checked
    });
  }

  handleEditRegion = (event) =>
  {
    this.setState({
      edit_region:event.target.checked
    });
  }

  handleCheckRegion = (event) =>
  {
    this.setState({
      check_region:event.target.checked
    });
  }

  handleViewState = (event) =>
  {
    this.setState({
      view_state:event.target.checked
    });
  }

  handleAddState = (event) =>
  {
    this.setState({
      add_state:event.target.checked
    });
  }

  handleEditState = (event) =>
  {
    this.setState({
      edit_state:event.target.checked
    });
  }

  handleCheckState = (event) =>
  {
    this.setState({
      check_state:event.target.checked
    });
  }

  handleViewDistrict = (event) =>
  {
    this.setState({
      view_district:event.target.checked
    });
  }

  handleAddDistrict = (event) =>
  {
    this.setState({
      add_district:event.target.checked
    });
  }

  handleEditDistrict = (event) =>
  {
    this.setState({
      edit_district:event.target.checked
    });
  }

  handleCheckDistrict = (event) =>
  {
    this.setState({
      check_district:event.target.checked
    });
  }

  handleViewCategory = (event) =>
  {
    this.setState({
      view_category:event.target.checked
    });
  }

  handleAddCategory = (event) =>
  {
    this.setState({
      add_category:event.target.checked
    });
  }

  handleEditCategory = (event) =>
  {
    this.setState({
      edit_category:event.target.checked
    });
  }

  handleCheckCategory = (event) =>
  {
    this.setState({
      check_category:event.target.checked
    });
  }

  handleViewSub = (event) =>
  {
    this.setState({
      view_sub:event.target.checked
    });
  }

  handleAddSub = (event) =>
  {
    this.setState({
      add_sub:event.target.checked
    });
  }

  handleEditSub = (event) =>
  {
    this.setState({
      edit_sub:event.target.checked
    });
  }

  handleCheckSub = (event) =>
  {
    this.setState({
      check_sub:event.target.checked
    });
  }

  handleViewBrand = (event) =>
  {
    this.setState({
      view_brand:event.target.checked
    });
  }
  handleEditBrand = (event) =>
  {
    this.setState({
      edit_brand:event.target.checked
    });
  }

  handleAddBrand = (event) =>
  {
    this.setState({
      add_brand:event.target.checked
    });
  }

  handleCheckBrand = (event) =>
  {
    this.setState({
      check_brand:event.target.checked
    });
  }

  handleAddProduct = (event) =>
  {
    this.setState({
      add_product:event.target.checked
    });
  }

  handleEditProduct = (event) =>
  {
    this.setState({
      edit_product:event.target.checked
    });
  }

  handleViewProduct = (event) =>
  {
    this.setState({
      view_product:event.target.checked
    });
  }

  handleCheckProduct = (event) =>
  {
    this.setState({
      check_product:event.target.checked
    });
  }

  handleViewUser = (event) =>
  {
    this.setState({
      view_user:event.target.checked
    });
  }

  handleAddUser = (event) =>
  {
    this.setState({
      add_user:event.target.checked
    });
  }

  handleEditUser = (event) =>
  {
    this.setState({
      edit_user:event.target.checked
    });
  }

  handleCheckUser = (event) =>
  {
    this.setState({
      check_user:event.target.checked
    });
  }

  handleRevoke = (event) =>
  {
    this.setState({
      revoke_privilege:event.target.checked
    });
  }

  handleAudit = (event) =>
  {
    this.setState({
      audit_tracking:event.target.checked
    });
  }

  handleRetailerList = (event) =>
  {
    this.setState({
      view_retailer:event.target.checked
    });
  }

  handleAddRetailer = (event) =>
  {
    this.setState({
      add_retailer:event.target.checked
    });
  }

  handleEditRetailer = (event) =>
  {
    this.setState({
      edit_retailer:event.target.checked
    });
  }

  handleCheckRetailer = (event) =>
  {
    this.setState({
      check_retailer:event.target.checked
    });
  }

  handleRetailerMap = (event) =>
  {
    this.setState({
      retailer_mapping:event.target.checked
    });
  }

  handleBulkEnrolment = (event) =>
  {
    this.setState({
      bulk_enrol:event.target.checked
    });
  }

  handleBulkMap = (event) =>
  {
    this.setState({
      bulk_mapping:event.target.checked
    });
  }

  handleKRETag = (event) =>
  {
    this.setState({
      kre_tagged:event.target.checked
    });
  }

  handleView = (event) =>
  {
    this.setState({
      view_visible:event.target.checked
    });
  }

  handleAdd = (event) =>
  {
    this.setState({
      add_visible:event.target.checked
    });
  }

  handleEdit = (event) =>
  {
    this.setState({
      edit_visible:event.target.checked
    });
  }

  handleCheck = (event) =>
  {
    this.setState({
      check_visible:event.target.checked
    });
  }

  componentDidMount() 
  {
    const url = this.props.location.pathname;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    axios.get(APIURL+'edit-user/'+segment)
    .then((res) => 
    {
      if(res.data.statusCode == 200)
      {
        this.setState({
          data: res.data.details[0],
          permissions: res.data.details[0].permissions,
        });
        console.log(this.state.data)
        let permission = this.state.permissions;
        if(permission != null)
        {
          if(permission.includes('view_region'))
          {
            this.setState({
              view_region:true
            });
          }
          if(permission.includes('add_region'))
          {
            this.setState({
              add_region:true
            });
          }

        if(permission.includes('edit_region'))
        {
          this.setState({
            edit_region:true
          });
        }

        if(permission.includes('check_region'))
        {
          this.setState({
            check_region:true
          });
        }

        if(permission.includes('view_state'))
        {
          this.setState({
            view_state:true
          });
        }

        if(permission.includes('add_state'))
        {
          this.setState({
            add_state:true
          });
        }

        if(permission.includes('edit_state'))
        {
          this.setState({
            edit_state:true
          });
        }

        if(permission.includes('check_state'))
        {
          this.setState({
            check_state:true
          });
        }

        if(permission.includes('view_district'))
        {
          this.setState({
            view_district:true
          });
        }

        if(permission.includes('add_district'))
        {
          this.setState({
            add_district:true
          });
        }

        if(permission.includes('edit_district'))
        {
          this.setState({
            edit_district:true
          });
        }

        if(permission.includes('check_district'))
        {
          this.setState({
            check_district:true
          });
        }

        if(permission.includes('view_district'))
        {
          this.setState({
            view_district:true
          });
        }

        if(permission.includes('view_category'))
        {
          this.setState({
            view_category:true
          });
        }

        if(permission.includes('add_category'))
        {
          this.setState({
            add_category:true
          });
        }

        if(permission.includes('edit_category'))
        {
          this.setState({
            edit_category:true
          });
        }

        if(permission.includes('check_category'))
        {
          this.setState({
            check_category:true
          });
        }

        if(permission.includes('view_sub'))
        {
          this.setState({
            view_sub:true
          });
        }

        if(permission.includes('add_sub'))
        {
          this.setState({
            add_sub:true
          });
        }

        if(permission.includes('edit_sub'))
        {
          this.setState({
            edit_sub:true
          });
        }

        if(permission.includes('check_sub'))
        {
          this.setState({
            check_sub:true
          });
        }

        if(permission.includes('view_brand'))
        {
          this.setState({
            view_brand:true
          });
        }

        if(permission.includes('add_brand'))
        {
          this.setState({
            add_brand:true
          });
        }

        if(permission.includes('edit_brand'))
        {
          this.setState({
            edit_brand:true
          });
        }

        if(permission.includes('check_brand'))
        {
          this.setState({
            check_brand:true
          });
        }

        if(permission.includes('view_product'))
        {
          this.setState({
            view_product:true
          });
        }

        if(permission.includes('add_product'))
        {
          this.setState({
            add_product:true
          });
        }

        if(permission.includes('edit_product'))
        {
          this.setState({
            edit_product:true
          });
        }

        if(permission.includes('check_product'))
        {
          this.setState({
            check_product:true
          });
        }

        if(permission.includes('view_user'))
        {
          this.setState({
            view_user:true
          });
        }

        if(permission.includes('add_user'))
        {
          this.setState({
            add_user:true
          });
        }

        if(permission.includes('edit_user'))
        {
          this.setState({
            edit_user:true
          });
        }

        if(permission.includes('check_user'))
        {
          this.setState({
            check_user:true
          });
        }

        if(permission.includes('revoke_privilege'))
        {
          this.setState({
            revoke_privilege:true
          });
        }

        if(permission.includes('audit_tracking'))
        {
          this.setState({
            audit_tracking:true
          });
        }

        if(permission.includes('view_retailer'))
        {
          this.setState({
            view_retailer:true
          });
        }

        if(permission.includes('add_retailer'))
        {
          this.setState({
            add_retailer:true
          });
        }

        if(permission.includes('edit_retailer'))
        {
          this.setState({
            edit_retailer:true
          });
        }

        if(permission.includes('check_retailer'))
        {
          this.setState({
            check_retailer:true
          });
        }

        if(permission.includes('retailer_mapping'))
        {
          this.setState({
            retailer_mapping:true
          });
        }

        if(permission.includes('bulk_enrol'))
        {
          this.setState({
            bulk_enrol:true
          });
        }

        if(permission.includes('bulk_mapping'))
        {
          this.setState({
            bulk_mapping:true
          });
        }

        if(permission.includes('kre_tagged'))
        {
          this.setState({
            kre_tagged:true
          });
        }

        if(permission.includes('view_visible'))
        {
          this.setState({
            view_visible:true
          });
        }

        if(permission.includes('add_visible'))
        {
          this.setState({
            add_visible:true
          });
        }

        if(permission.includes('edit_visible'))
        {
          this.setState({
            edit_visible:true
          });
        }

        if(permission.includes('check_visible'))
        {
          this.setState({
            check_visible:true
          });
        }
        }
      }
      else
      {
        this.setState({
          data: '',
        });
      }
    })
  }

  onSubmit = (event) => 
  {
    event.preventDefault();
    let arr = [];
    for (var key in this.state) 
    {
      if(this.state[key] === true) 
      {
        if(key != 'submit')
        {
          arr.push(key);
        }
      }
    }
    this.setState({
      submit: false,
      wait: true,
    });
    const url = this.props.location.pathname;
    const admin_id = url.substring(url.lastIndexOf('/') + 1);
    const permission = url.split('/').slice(-2)[0];
    axios({
        method: 'post',
        url: APIURL+'revoke-privilege/'+admin_id,
        data: qs.stringify({
          login_for:permission,
          permissions: arr
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
      .then((res) => 
      {
        if(res.data.statusCode == 200)
        {
          alert(res.data.message)
          this.props.history.push('/admin/users-list')
        }
        else if(res.data.statusCode == 500)
        {
          this.setState({
              errorMessage: res.data.message,
              wait:false,
              submit:true
          });
        }
      })
      .catch((error) => {
          if (axios.isCancel(error) || error) {
              this.setState({
                  loading: false,
                  message: 'Failed to fetch results.Please check network',
              });
          }
      });
  };

  render() 
  {  
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Report Panel Privileges</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/admin/users-list">Users</a>
                    </li>
                    <li className="breadcrumb-item active">Report Panel Privileges</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                  <div className="card-body">
                      <form onSubmit={this.onSubmit} method="post" autoComplete="off">    
                          <div className="table-responsive">
                              <table id="example" className="display table-bordered dataTable no-footer">
                                <thead>
                                  <tr>
                                      <th colspan="5" className="text-center">Role Permission</th>
                                  </tr>
                                  <tr>
                                    <th rowspan="2" className="text-center sorting_disabled">Module Name</th>
                                    <th colspan="4" className="text-center">
                                      <div className="checkboxx">
                                        <label for="select_all">Permissions</label>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th className="text-center sorting_disabled">View</th>
                                    <th className="text-center sorting_disabled">Add</th>
                                    <th className="text-center sorting_disabled">Edit</th>
                                    <th className="text-center sorting_disabled">Enabled/Disabled</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr role="row">
                                    <td className="text-center"><span>Region Master</span></td>  
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-region" onChange={this.handleViewRegion}  checked={this.state.view_region}/>
                                        </div>
                                      </div>
                                    </td>  
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-region" onChange={this.handleAddRegion} checked={this.state.add_region}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                          <div className="checkbox">
                                            <input type="checkbox" value="edit-region" checked={this.state.edit_region} onChange={this.handleEditRegion}/>
                                          </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-region" checked={this.state.check_region} onChange={this.handleCheckRegion}/>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>State Master</span></td>    
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-state" checked={this.state.view_state} onChange={this.handleViewState}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-state" checked={this.state.add_state} onChange={this.handleAddState}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                          <div className="checkbox">
                                            <input type="checkbox" value="edit-state" checked={this.state.edit_state} onChange={this.handleEditState}/>
                                          </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-state" checked={this.state.check_state} onChange={this.handleCheckState}/>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>District Master</span></td>    
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox"  value="view-district" checked={this.state.view_district} onChange={this.handleViewDistrict}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-district" checked={this.state.add_district} onChange={this.handleAddDistrict}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                          <div className="checkbox">
                                            <input type="checkbox" value="edit-district" checked={this.state.edit_district} onChange={this.handleEditDistrict}/>
                                          </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-district" checked={this.state.check_district} onChange={this.handleCheckDistrict}/>                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                      <th colspan="5" className="text-center">** Product Management Start **</th>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>Category Master</span></td> 
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-category" checked={this.state.view_category} onChange={this.handleViewCategory}/>
                                        </div>
                                      </div>
                                    </td>   
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-category" checked={this.state.add_category} onChange={this.handleAddCategory}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                          <div className="checkbox">
                                            <input type="checkbox" value="edit-category" checked={this.state.edit_category} onChange={this.handleEditCategory}/>
                                          </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-category" checked={this.state.check_category} onChange={this.handleCheckCategory}/>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>SubCategory Master</span></td>    
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-subcategory" checked={this.state.view_sub} onChange={this.handleViewSub}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-subcategory" checked={this.state.add_sub} onChange={this.handleAddSub}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                          <div className="checkbox">
                                            <input type="checkbox" value="edit-subcategory" checked={this.state.edit_sub} onChange={this.handleEditSub}/>
                                          </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-subcategory" checked={this.state.check_sub} onChange={this.handleCheckSub}/>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>Brand Master</span></td>    
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-brand" checked={this.state.view_brand} onChange={this.handleViewBrand}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-brand" checked={this.state.add_brand} onChange={this.handleAddBrand}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                          <div className="checkbox">
                                            <input type="checkbox" value="edit-brand" checked={this.state.edit_brand} onChange={this.handleEditBrand}/>
                                          </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-brand" checked={this.state.check_brand} onChange={this.handleCheckBrand}/>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>Product Master</span></td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-product" checked={this.state.view_product} onChange={this.handleViewProduct}/>
                                        </div>
                                      </div>
                                    </td>    
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-product" checked={this.state.add_product} onChange={this.handleAddProduct}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue">
                                          <div className="checkbox">
                                            <input type="checkbox" value="edit-product" checked={this.state.edit_product} onChange={this.handleEditProduct}/>
                                          </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-product" checked={this.state.check_product} onChange={this.handleCheckProduct}/>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                      <th colspan="5" className="text-center">** Product Management End **</th>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>Users</span></td>    
                                    <td  colspan="5">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-users" checked={this.state.view_user} onChange={this.handleViewUser}/>
                                          &nbsp;&nbsp;<label for="view-users">View Users</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="edit-users" checked={this.state.edit_user} onChange={this.handleEditUser}/>
                                          &nbsp;&nbsp;<label for="edit-users">Edit Users</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-users" checked={this.state.check_user} onChange={this.handleCheckUser}/>
                                          &nbsp;&nbsp;<label for="check-users">Enabled/Disabled Users</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="privilege-users" checked={this.state.revoke_privilege} onChange={this.handleRevoke}/>
                                          &nbsp;&nbsp;<label for="privilege-users">Revoke Privileges</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="audit-tracking" checked={this.state.audit_tracking} onChange={this.handleAudit}/>
                                          &nbsp;&nbsp;<label for="audit-tracking">Audit Tracking</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-users" checked={this.state.add_user} onChange={this.handleAddUser}/>
                                          &nbsp;&nbsp;<label for="add-users">User Enrolment</label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>Retailer Management</span></td>    
                                    <td  colspan="5">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-retailers" checked={this.state.view_retailer} onChange={this.handleRetailerList}/>
                                          &nbsp;&nbsp;<label for="view-retailers">Retailers List</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="edit-retailers" checked={this.state.edit_retailer} onChange={this.handleEditRetailer}/>
                                          &nbsp;&nbsp;<label for="edit-retailers">Edit Retailers</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-retailers" checked={this.state.check_retailer} onChange={this.handleCheckRetailer}/>
                                          &nbsp;&nbsp;<label for="check-retailers">Enabled/Disabled Retailers</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="retailer-mapping" checked={this.state.retailer_mapping} onChange={this.handleRetailerMap}/>
                                          &nbsp;&nbsp;<label for="retailer-mapping">Retailers Mapping</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-retailer" checked={this.state.add_retailer} onChange={this.handleAddRetailer}/>
                                          &nbsp;&nbsp;<label for="add-users">Retailer Enrolment</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="bulk-enrolment" checked={this.state.bulk_enrol}  onChange={this.handleBulkEnrolment}/>
                                          &nbsp;&nbsp;<label for="bulk-enrolment">Bulk Enrolment</label>
                                        </div>
                                      </div>
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="bulk-mapping" checked={this.state.bulk_mapping} onChange={this.handleBulkMap}/>
                                          &nbsp;&nbsp;<label for="bulk-mapping">Bulk Mapping</label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>KRE Tagged</span></td>    
                                    <td colspan="5">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="kre-tagged" checked={this.state.kre_tagged} onChange={this.handleKRETag}/>
                                          <label for="kre-tagged"></label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr role="row">
                                    <td className="text-center"><span>Visibility Management</span></td>    
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="view-visibility" checked={this.state.view_visible} onChange={this.handleView}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="add-visibility" checked={this.state.add_visible} onChange={this.handleAdd}/>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                          <div className="checkbox">
                                            <input type="checkbox" value="edit-visibility" checked={this.state.edit_visible} onChange={this.handleEdit}/>
                                            <label for="edit-visibility"></label>
                                          </div>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <div className="icheckbox_square-blue" aria-checked="false" aria-disabled="false">
                                        <div className="checkbox">
                                          <input type="checkbox" value="check-visibility" checked={this.state.check_visible} onChange={this.handleCheck}/>
                                          <label for="check-visibility"></label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                          </div>
                          <br/>
                          {this.state.submit ?
                            <button className="btn btn-primary privilege-btn" type="submit">Update</button>
                          : null }
                          {this.state.wait ?
                            <button className="btn btn-primary privilege-btn" type="button">Please Wait..</button>
                          : null }
                      </form>    
                  </div> 
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdminPermission;
