import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";

class SidebarNav extends Component {
    constructor(props){
      super(props);
      this.state=
      {
        show: null,
        show_region:null,
        show_state:null,
        show_district:null,
        show_product:null,
        show_category:null,
        show_sub:null,
        show_brand:null,
        view_product:null,
        audit_tracking:null,
        add_user:null,
        view_user:null,
        show_visible:null,
        kre_tagged:null,
        add_retailer:null,
        bulk_mapping:null,
        bulk_enrol:null,
        show_retailer:null,
        retailer_mapping:null
      }
    }

  handleShow(id){
    this.setState({
        show: id
    })
  }
  
  componentDidMount() 
  {  
    let permission = localStorage.getItem('permissions');
    if(permission.includes('view_region') || permission.includes('add_region') || permission.includes('edit_region') || permission.includes('check_region'))
    {
      this.setState(
      {
        show_region: true
      });
    }

    if(permission.includes('view_state') || permission.includes('add_state') || permission.includes('edit_state') || permission.includes('check_state'))
    {
      this.setState(
      {
        show_state: true
      });
    }

    if(permission.includes('view_district') || permission.includes('add_district') || permission.includes('edit_district') || permission.includes('check_district'))
    {
      this.setState(
      {
        show_district: true
      });
    }

    if(permission.includes('view_brand') || permission.includes('add_brand') || permission.includes('edit_brand') || permission.includes('check_brand') || permission.includes('view_category') || permission.includes('add_category') || permission.includes('edit_category') || permission.includes('check_category') || permission.includes('view_sub') || permission.includes('add_sub') || permission.includes('edit_sub') || permission.includes('check_sub') || permission.includes('view_brand') || permission.includes('add_brand') || permission.includes('edit_brand') || permission.includes('check_brand') || permission.includes('view_product') || permission.includes('add_product') || permission.includes('edit_product') || permission.includes('check_product'))
    {
      this.setState(
      {
        show_product: true
      });
    }

    if(permission.includes('view_brand') || permission.includes('add_brand') || permission.includes('edit_brand') || permission.includes('check_brand'))
    {
      this.setState(
      {
        show_brand: true
      });
    }

    if(permission.includes('view_category') || permission.includes('add_category') || permission.includes('edit_category') || permission.includes('check_category'))
    {
      this.setState(
      {
        show_category: true
      });
    }

    if(permission.includes('view_sub') || permission.includes('add_sub') || permission.includes('edit_sub') || permission.includes('check_sub'))
    {
      this.setState(
      {
        show_sub: true
      });
    }

    if(permission.includes('view_product') || permission.includes('add_product') || permission.includes('edit_product') || permission.includes('check_product'))
    {
      this.setState(
      {
        view_product: true
      });
    }

    if(permission.includes('view_user') || permission.includes('add_user') || permission.includes('edit_user') || permission.includes('check_user') || permission.includes('audit_tracking') || permission.includes('revoke_privilege'))
    {
      this.setState(
      {
        show_user: true
      });
    }

    if(permission.includes('view_user') || permission.includes('add_user') || permission.includes('edit_user') || permission.includes('check_user') || permission.includes('revoke_privilege'))
    {
      this.setState(
      {
        view_user: true
      });
    }

    if(permission.includes('add_user'))
    {
      this.setState(
      {
        add_user: true
      });
    }

    if(permission.includes('audit_tracking'))
    {
      this.setState(
      {
        audit_tracking: true
      });
    }

    if(permission.includes('view_visible') || permission.includes('add_visible') || permission.includes('edit_visible') || permission.includes('check_visible'))
    {
      this.setState(
      {
        show_visible: true
      });
    }

    if(permission.includes('kre_tagged'))
    {
      this.setState(
      {
        kre_tagged: true
      });
    }

    if(permission.includes('add_retailer'))
    {
      this.setState(
      {
        add_retailer: true
      });
    }

    if(permission.includes('bulk_enrol'))
    {
      this.setState(
      {
        bulk_enrol: true
      });
    }

    if(permission.includes('bulk_mapping'))
    {
      this.setState(
      {
        bulk_mapping: true
      });
    }

    if(permission.includes('retailer_mapping'))
    {
      this.setState(
      {
        retailer_mapping: true
      });
    }

    if(permission.includes('retailer_mapping') || permission.includes('bulk_mapping') || permission.includes('bulk_enrol') || permission.includes('add_retailer') || permission.includes('edit_retailer') || permission.includes('view_retailer') || permission.includes('check_retailer'))
    {
      this.setState(
      {
        show_retailer: true
      });
    }
  }

  handleChange = (event) =>
  {
    alert()
  }

  render() {
   
    const {  location } = this.props
    let pathname = location.pathname

   return (
    <div className="sidebar" id="sidebar">
        <div className="sidebar-inner">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
      				{/*<li className="menu-title"> 
      					<span>Main</span>
      				</li>*/}
      				<li className={pathname.includes('dashboard') ?"active" :""}> 
      					<a href="/admin/dashboard"><i className="fa fa-home" /> <span>Dashboard</span></a>
      				</li>
              {this.state.show_region ? 
              <li className={pathname.includes('region-list') ?"active" : pathname.includes('add-region') ? "active" : pathname.includes('edit-region') ? "active" : ""}> 
                <a href="/admin/region-list"><i className="fa fa-globe" /> <span>Region Master</span></a>
              </li>
              : null }
              {this.state.show_state ? 
              <li className={pathname.includes('state-list') ?"active" : pathname.includes('add-state') ? "active" : pathname.includes('edit-state') ? "active" : ""}> 
                <a href="/admin/state-list"><i className="fas fa-city" /> <span>State Master</span></a>
              </li>
              :null }

              {this.state.show_district ? 
              <li className={pathname.includes('district-list') ?"active" : pathname.includes('add-district') ? "active" : pathname.includes('edit-district') ? "active" : ""}> 
                <a href="/admin/district-list"><i className="fa fa-map-marker" /> <span>District Master</span></a>
              </li>
              : null }

              {this.state.show_product ? 
              <li className="submenu">
                <a href="javascript:void(0);"><i className="fa fa-product-hunt" /> <span> Product Management</span> <span className="menu-arrow" /></a>
                <ul>
                {this.state.show_category ? 
                  <li><a href="/admin/category-list" className={pathname.includes('category-list') ?"active" : pathname.includes('add-category') ? "active" : pathname.includes('edit-category') ? "active" : ""}>Category Master</a></li>
                : null }  
                {this.state.show_sub ? 
                  <li><a href="/admin/subcategory-list" className={pathname.includes('subcategory-list') ?"active" : pathname.includes('add-subcategory') ? "active" : pathname.includes('edit-subcategory') ? "active" : ""}>SubCategory Master</a></li>
                : null }  
                {this.state.show_brand ? 
                  <li><a href="/admin/brand-list" className={pathname.includes('brand-list') ?"active" : pathname.includes('add-brand') ? "active" : pathname.includes('edit-brand') ? "active" : ""}>Brand Master</a></li>
                : null }
                {this.state.view_product ?   
                  <li><a href="/admin/product-list" className={pathname.includes('product-list') ?"active" : pathname.includes('add-product') ? "active" : pathname.includes('edit-product') ? "active" : ""}>Product Master</a></li>
                : null }
                </ul>
              </li>
              : null }

              { this.state.show_user ?
      				<li className="submenu">
      					<a href="javascript:void(0);"><i className="fa fa-users" /> <span> Users</span> <span className="menu-arrow"/></a>
      					<ul>
                  { this.state.view_user ?
      					  <li><a href="/admin/users-list" className={pathname.includes('users-list') ?"active" :""}>View Users</a></li>
                  : null }
                  { this.state.audit_tracking ?
                  <li><a href="/admin/audit-tracking" className={pathname.includes('audit-tracking') ?"active" :""}>Audit Tracking</a></li>
      					  : null }
                  { this.state.add_user ?
                  <li><a href="/admin/add-user" className={pathname.includes('add-user') ?"active" :pathname.includes('edit-user') ?"active" :""}>User Enrolment</a></li>
      					   : null }
                </ul>
      				</li>
              : null }
              { this.state.show_retailer ?
            	<li className="submenu">
                <a href="javascript:void(0);"><i className="fas fa-store-alt" /> <span> Retailer Management</span> <span className="menu-arrow" /></a>
                <ul>
                  <li><a href="/admin/retailer-management" className={pathname.includes('retailer-management') ?"active" :""}>Retailers List</a></li>
                  { this.state.add_retailer ?
                  <li><a href="/admin/add-retailer" className={pathname.includes('add-retailer') ?"active" :""}>Retailer Enrolment</a></li>
                  : null }
                  { this.state.retailer_mapping ?
                  <li><a href="/admin/retailer-mapping" className={pathname.includes('retailer-mapping') ?"active" : ""}>Retailer Mapping</a></li>
                  : null }
                  { this.state.bulk_enrol ?
                  <li><a href="/admin/retailer-bulk-upload" className={pathname.includes('retailer-bulk-upload') ?"active" :""}>Bulk Enrolment</a></li>
                  : null }
                  { this.state.bulk_mapping ?
                  <li><a href="/admin/bulk-mapping" className={pathname.includes('bulk-mapping') ?"active" : ""}>Bulk Mapping</a></li>
                  : null }
                </ul>
            	</li>
              : null }
              { this.state.kre_tagged ?
                <li className={pathname.includes('kre-tagged') ?"active" : pathname.includes('kre-list') ? "active" : ""}> 
                <a href="/admin/kre-tagged"><i className="fa fa-tag" /> <span>KRE Tagged</span></a>
              </li>
              : null }
              {this.state.show_visible ?
              <li className={pathname.includes('visibility-management') ? "active" : ""}> 
                <a href="/admin/visibility-management"><i className="fa fa-list" /> <span>Visibility Management</span></a>
              </li>
              : null }
            </ul>
          </div>
        </div>
      </div>
  );
}
}

export default withRouter(SidebarNav);
